import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

import '../../styles/UIElements/ProgressBar.css';

const ProgressBar = ({ currentStep, totalSteps, stepNames }) => {
	const formVariants = {
		hidden: { opacity: 0, scale: 0 },
		visible: { opacity: 1, scale: 1 },
	};

	return (
		<div className='progress-bar'>
			<AnimatePresence>
				{[...Array(totalSteps)].map((_, index) => (
					<React.Fragment key={index}>
						{index > 0 && (
							<motion.div
								initial={{ width: '0%' }}
								animate={{ width: currentStep > index ? '100%' : '0%' }}
								exit={{ width: '0%' }}
								transition={{ duration: 0.5 }}
								className={`line ${currentStep > index ? 'completed' : ''}`}
							></motion.div>
						)}
						<motion.div
							key={index}
							initial='hidden'
							animate='visible'
							exit='hidden'
							variants={formVariants}
							transition={{ duration: 0.5 }}
							className={`step ${currentStep >= index + 1 ? 'active' : ''}`}
						>
							{index + 1}
							<span className='stepDetails'>{stepNames[index]}</span>
						</motion.div>
					</React.Fragment>
				))}
			</AnimatePresence>
		</div>
	);
};

ProgressBar.propTypes = {
	currentStep: PropTypes.number.isRequired,
	totalSteps: PropTypes.number.isRequired,
};

export default ProgressBar;
