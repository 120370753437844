// Action types
export const SET_SHOW_DELETE_APP_MODAL = 'SET_SHOW_DELETE_APP_MODAL';
export const SET_SHOW_EDIT_APP_MODAL = 'SET_SHOW_EDIT_APP_MODAL';
export const SET_SHOW_DELETE_EMAIL_MODAL = 'SET_SHOW_DELETE_EMAIL_MODAL';
export const SET_SHOW_NOTIFICATIONS = 'SET_SHOW_NOTIFICATIONS';

// Action creators
export const setShowDeleteApplicationModal = (value) => ({
	type: SET_SHOW_DELETE_APP_MODAL,
	payload: value,
});
export const setShowEditApplicationModal = (value) => ({
	type: SET_SHOW_EDIT_APP_MODAL,
	payload: value,
});
export const setShowDeleteEmailModal = (value) => ({
	type: SET_SHOW_DELETE_EMAIL_MODAL,
	payload: value,
});
export const setShowNotifications = (value) => ({
	type: SET_SHOW_NOTIFICATIONS,
	payload: value,
});

// Initial state
const initialState = {
	showDeleteApplicationModal: false,
	showEditApplicationModal: false,
	showDeleteEmailModal: false,
	showNotifications: false,
};

// Reducer
const modalDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SHOW_DELETE_APP_MODAL:
			return {
				...state,
				showDeleteApplicationModal: action.payload,
			};
		case SET_SHOW_EDIT_APP_MODAL:
			return {
				...state,
				showEditApplicationModal: action.payload,
			};
		case SET_SHOW_DELETE_EMAIL_MODAL:
			return {
				...state,
				showDeleteEmailModal: action.payload,
			};
		case SET_SHOW_NOTIFICATIONS:
			return {
				...state,
				showNotifications: action.payload,
			};
		default:
			return state;
	}
};

export default modalDataReducer;
