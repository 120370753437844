import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import { setUserSortedApplicationData, setUserApplicationData } from '../../../redux/userReducer';
import { setReviewQueue } from '../../../redux/appDataReducer';

import Modal from '../Modal';
import '../../../styles/UIElements/ApplicationActions.css';
import { AnimatePresence, motion } from 'framer-motion';

const ApplicationActions = (props, { triggerForceUpdate }) => {
	const dispatch = useDispatch();

	// eslint-disable-next-line no-unused-vars
	const userApplicationData = useSelector((state) => state.user.userApplicationData);
	const userData = useSelector((state) => state.user.userData);

	let isCheckedList = props.isCheckedList;

	const importTime = props.lastUpdated;

	const today = new Date();

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showUpdated, setShowUpdated] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [filters, setFilters] = useState({
		employer: '',
		jobTitle: '',
		stage: '',
	});
	const [search, setSearch] = useState('');
	const [mergeApplicationDetails, setMergeApplicationDetails] = useState({
		employer: '',
		jobTitle: '',
		stage: '',
		lastUpdated: today,
	});
	const [addApplicationDetails, setAddApplicationDetails] = useState({
		employer: '',
		jobTitle: '',
		stage: 'submitted',
		lastUpdated: '',
	});
	const [detailsFetched, setDetailsFetched] = useState(false);

	useEffect(() => {
		applySearchAndFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, filters]);

	let formattedDate;
	if (importTime) {
		const date = importTime.split('T')[0];
		const time = importTime.split('T')[1].slice(0, -8);

		formattedDate = date + ' at ' + time;
	}

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const employers = [
		...new Set(
			props.applicationData
				.filter((application) => application.employer !== null)
				.map((application) => application.employer)
		),
	].sort((a, b) => a.localeCompare(b));

	const jobTitles = [
		...new Set(
			props.applicationData
				.filter((application) => application.jobTitle !== null)
				.map((application) => application.jobTitle)
		),
	].sort((a, b) => a.localeCompare(b));

	const [isFetching, setIsFetching] = useState(false);

	const fetchNewEmails = async () => {
		if (userData.settings.autoImport) {
			setIsFetching(true);
			try {
				const response = await fetch(backendUrl + 'fetch-emails/google', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				});
				if (response.ok) {
					if (response.status === 205) {
						try {
							const response = await fetch(backendUrl + `authtoken`, {
								method: 'GET',
								headers: {
									'Content-Type': 'application/json',
								},
								credentials: 'include',
							});
							if (response.status === 303) {
								const url = await response.json();
								window.location.href = url.url;
							}
							if (response.ok) {
								setTimeout(() => {
									fetchNewEmails();
								}, 1000);
							} else {
								console.log('unable to refresh token.');
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						const newEmailData = await response.json();
						if (newEmailData.emailData.length < 1) {
							setShowUpdated(true);
							props.triggerForceUpdate();
							setTimeout(() => {
								setShowUpdated(false);
							}, 5000);
						} else {
							props.triggerForceUpdate();
							setIsFetching(false);
						}
						if (newEmailData.reviewQueue.length > 0) {
							dispatch(setReviewQueue(newEmailData.reviewQueue));
						}
						setIsFetching(false);
					}
				} else {
					if (response.status === 401 || response.status === 500) {
						try {
							const response = await fetch(backendUrl + `authtoken`, {
								method: 'GET',
								headers: {
									'Content-Type': 'application/json',
								},
								credentials: 'include',
							});
							if (response.ok) {
								setTimeout(() => {
									fetchNewEmails();
								}, 1000);
							} else if (response.status === 303) {
								const url = await response.json();
								window.location.href = url.url;
							}
						} catch (error) {
							console.log(error);
						}
					} else {
						const errorData = await response.json();
						console.error(errorData.error); // Handle errors
						setIsFetching(false);
					}
				}
			} catch (error) {
				if (error.status === 500) {
					try {
						const response = await fetch(backendUrl + `authtoken`, {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
							},
							credentials: 'include',
						});
						if (response.ok) {
							setTimeout(() => {
								fetchNewEmails();
							}, 1000);
						} else if (response.status === 303) {
							const url = await response.json();
							window.location.href = url.url;
						} else {
							console.log('unable to refresh token.');
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					console.log('Error fetching new emails: ' + error);
					setIsFetching(false);
				}
			}
		}
	};

	const handleShowFilters = () => {
		setShowFilters((prevState) => !prevState);
		setFilters({ employer: '', jobTitle: '', stage: '' });
	};

	const applySearchAndFilter = () => {
		const filteredApplications = props.applicationData.filter((application) => {
			const employerMatches =
				!filters.employer ||
				(application.employer && application.employer.toLowerCase().includes(filters.employer.toLowerCase()));
			const jobTitleMatches =
				!filters.jobTitle ||
				(application.jobTitle && application.jobTitle.toLowerCase().includes(filters.jobTitle.toLowerCase()));
			const stageMatches =
				!filters.stage || (application.stage && application.stage.toLowerCase() === filters.stage.toLowerCase());

			const searchMatches =
				!search ||
				Object.values(application).some(
					(value) => value && value.toString().toLowerCase().includes(search.toLowerCase())
				) ||
				(application.emails &&
					application.emails[0] &&
					application.emails[0].sender &&
					application.emails[0].sender.toLowerCase().includes(search.toLowerCase()));

			return employerMatches && jobTitleMatches && stageMatches && searchMatches;
		});

		dispatch(setUserSortedApplicationData(filteredApplications));
	};

	const handleSearch = (search) => {
		setSearch(search);
	};

	const handleDeletion = async () => {
		const body = JSON.stringify({ ids: isCheckedList });
		try {
			const response = await fetch(backendUrl + 'application/multiple', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: body,
			});
			if (response.ok) {
				props.handleResetList();
				props.triggerForceUpdate();
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
	};

	const handleFlagAll = async () => {
		try {
			// Fetch details for each application
			const applicationDetails = await Promise.all(
				isCheckedList.map(async (applicationId) => {
					const response = await fetch(backendUrl + `application/${applicationId}`, {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
					});
					const data = await response.json();
					return { applicationId, data };
				})
			);

			// Flag emails for each application
			await Promise.all(
				applicationDetails.map(async ({ applicationId, data }) => {
					if (data && data.emails && data.emails.length > 0) {
						const emailId = data.emails[0]._id;

						try {
							const responseData = await fetch(backendUrl + `email/${emailId}`, {
								method: 'PATCH',
								headers: {
									'Content-Type': 'application/json',
								},
								credentials: 'include',
								body: JSON.stringify({ flaggedIncorrect: true }),
							});
							console.log(responseData);
						} catch (error) {
							console.log('Unable to update email');
						}
					} else {
						console.log(`Email details not found for application ID: ${applicationId}`);
					}
				})
			);

			console.log('Flagging completed successfully');
		} catch (error) {
			console.log('Error during flagging applications: ' + error);
		}
	};

	const handleMerge = async () => {
		const body = JSON.stringify({ ids: isCheckedList, data: { ...mergeApplicationDetails, lastUpdated: today } });
		try {
			const response = await fetch(backendUrl + 'application/multiple', {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: body,
			});
			if (response.ok) {
				const updatedApplications = userApplicationData.filter(
					(application) => !isCheckedList.includes(application._id)
				);
				dispatch(setUserApplicationData(updatedApplications));
				setMergeApplicationDetails({
					employer: '',
					jobTitle: '',
					stage: '',
					lastUpdated: today,
				});
				props.handleResetList();
				props.triggerForceUpdate();
				setShowEditModal(false);
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
	};

	const handleDateChange = (date) => {
		if (date instanceof Date) {
			const formattedDate = date.toISOString().split('T')[0] + 'T00:00:00.000Z';
			setAddApplicationDetails((prevState) => ({
				...prevState,
				lastUpdated: formattedDate,
			}));
		}
	};
	const handleAddChange = (e) => {
		const { name, value } = e.target;
		if (name === 'stage') {
			// Handle the select element separately
			setAddApplicationDetails((prevState) => ({
				...prevState,
				stage: value,
			}));
		} else {
			// For other input fields
			setAddApplicationDetails((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleEditChange = async (e) => {
		const { name, value } = e.target;
		if (name === 'stage') {
			// Handle the select element separately
			setMergeApplicationDetails((prevState) => ({
				...prevState,
				stage: value,
			}));
		} else {
			// For other input fields
			setMergeApplicationDetails((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleAddApplication = async () => {
		const body = JSON.stringify({ ...addApplicationDetails });
		try {
			const response = await fetch(backendUrl + 'application', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: body,
			});
			if (response.ok) {
				const data = await response.json();
				const updatedApplications = [...userApplicationData, data.application];
				dispatch(setUserApplicationData(updatedApplications));
				props.triggerForceUpdate();
				setAddApplicationDetails({
					employer: '',
					jobTitle: '',
					stage: 'submitted',
					lastUpdated: today,
				});
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
	};

	const handleShowDeleteModal = () => {
		setShowDeleteModal((prevState) => !prevState);
	};
	const handleShowEditModal = () => {
		setShowEditModal((prevState) => !prevState);
	};
	const handleShowAddModal = () => {
		setShowAddModal((prevState) => !prevState);
	};

	useEffect(() => {
		const fetchApplicationDetails = async () => {
			if (isCheckedList.length > 1) {
				const checkedApplication = userApplicationData.find((application) => application._id === isCheckedList[0]);
				if (checkedApplication) {
					setMergeApplicationDetails({
						employer: checkedApplication.employer,
						jobTitle: checkedApplication.jobTitle,
						stage: checkedApplication.stage,
					});
					setDetailsFetched(true);
				}
			}
		};
		fetchApplicationDetails();
	}, [isCheckedList, userApplicationData]);

	//This is commented out until I figure out the logic involved if a user does a manual add first. I don't want to import all if they did.
	/*useEffect(() => {
		const fetchOnLoad = async () => {
			if (userData && userData.settings && userData.settings.autoImport) {
				const lastFetchTime = localStorage.getItem('lastFetchTime');
				const currentTime = new Date().getTime();
				if (lastFetchTime && currentTime - lastFetchTime > 3600000) {
					if (authToken) {
						// 3600000 milliseconds = 1 hour
						setIsFetching(true);
						try {
							const response = await fetch(backendUrl + 'fetch-emails/google', {
								method: 'GET',
								headers: {
									'Content-Type': 'application/json',
									Authorization: `Bearer ${authToken}`,
								},
							});
							if (response.ok) {
								if (response.status === 205) {
									try {
										const response = await fetch(backendUrl + `authtoken`, {
											method: 'GET',
											headers: {
												'Content-Type': 'application/json',
												Authorization: `Bearer ${authToken}`,
											},
										});
										if (response.status === 303) {
											const url = await response.json();
											window.location.href = url.url;
										}
										if (response.ok) {
											setTimeout(() => {
												fetchOnLoad();
											}, 1000);
										} else {
											console.log('unable to refresh token.');
										}
									} catch (error) {
										console.log(error);
									}
								} else {
									const newEmailData = await response.json();
									if (newEmailData.emailData.length < 1) {
										setShowUpdated(true);
										props.triggerForceUpdate();
										setTimeout(() => {
											setShowUpdated(false);
										}, 5000);
									} else {
										props.triggerForceUpdate();
										setIsFetching(false);
									}
									if (newEmailData.reviewQueue.length > 0) {
										dispatch(setReviewQueue(newEmailData.reviewQueue));
									}
									setIsFetching(false);
								}
								localStorage.setItem('lastFetchTime', currentTime);
							} else {
								if (response.status === 401 || response.status === 500) {
									try {
										const response = await fetch(backendUrl + `authtoken`, {
											method: 'GET',
											headers: {
												'Content-Type': 'application/json',
												Authorization: `Bearer ${authToken}`,
											},
										});
										if (response.ok) {
											setTimeout(() => {
												fetchOnLoad();
											}, 1000);
										} else if (response.status === 303) {
											const url = await response.json();
											window.location.href = url.url;
										}
									} catch (error) {
										console.log(error);
									}
								} else {
									const errorData = await response.json();
									console.error(errorData.error); // Handle errors
									setIsFetching(false);
								}
							}
						} catch (error) {
							if (error.status === 500) {
								try {
									const response = await fetch(backendUrl + `authtoken`, {
										method: 'GET',
										headers: {
											'Content-Type': 'application/json',
											Authorization: `Bearer ${authToken}`,
										},
									});
									if (response.ok) {
										setTimeout(() => {
											fetchOnLoad();
										}, 1000);
									} else if (response.status === 303) {
										const url = await response.json();
										window.location.href = url.url;
									} else {
										console.log('unable to refresh token.');
									}
								} catch (error) {
									console.log(error);
								}
							} else {
								console.log('Error fetching new emails: ' + error);
								setIsFetching(false);
							}
						}
					}
				}
			}
		};

		fetchOnLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken, backendUrl, dispatch, props]);*/

	const [isDemo, setIsDemo] = useState(false);
	const currentPathname = window.location.pathname;

	const checkDemoPage = () => {
		if (currentPathname === '/demo') {
			setIsDemo(true);
		}
	};

	useEffect(() => {
		checkDemoPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPathname]);

	const actionVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<React.Fragment>
			<AnimatePresence>
				{showDeleteModal && (
					<Modal
						allowClose={true}
						setShow={() => setShowDeleteModal(false)}
					>
						<div className='p-8'>
							<h3>Are you sure you want to delete these applications?</h3>
							<p>
								If you are deleting them because they were incorrectly imported, please let us know by flagging and
								deleting them.{' '}
							</p>
							<div className='modal-button_container'>
								<button
									className='cancel'
									onClick={() => {
										setShowDeleteModal(false);
									}}
								>
									Cancel
								</button>
								<button
									className='secondary delete'
									onClick={() => {
										handleDeletion();
										setShowDeleteModal(false);
									}}
								>
									Delete
								</button>
								<button
									className='primary delete'
									onClick={async () => {
										await handleFlagAll();
										handleDeletion();
										setShowDeleteModal(false);
									}}
								>
									Flag and Delete
								</button>
							</div>
						</div>
					</Modal>
				)}
				{showEditModal && detailsFetched && (
					<Modal
						allowClose={true}
						setShow={() => setShowEditModal(false)}
					>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleMerge();
							}}
							className='form p-8'
						>
							<h3 className='self-start'>Application Details</h3>
							<p></p>
							<div className='form-inner_container'>
								<label>Employer:</label>
								<input
									type='text'
									name='employer'
									className='form-input'
									placeholder='Employer'
									onChange={(e) => handleEditChange(e)}
									value={mergeApplicationDetails.employer}
								></input>
							</div>
							<div className='form-inner_container !mt-0'>
								<label>Job Title:</label>
								<input
									type='text'
									name='jobTitle'
									className='form-input'
									placeholder='Job Title'
									onChange={(e) => handleEditChange(e)}
									value={mergeApplicationDetails.jobTitle}
								></input>
							</div>
							<div className='form-inner_container'>
								<label>Stage:</label>
								<select
									className='w-[68%]'
									onChange={(e) => handleEditChange(e)}
									name='stage'
									value={mergeApplicationDetails.stage}
								>
									<option value='submitted'>Submitted</option>
									<option value='follow up'>Follow Up</option>
									<option value='waiting on response'>Waiting on Response</option>
									<option value='interview'>Interview</option>
									<option value='assessment'>Assessment</option>
									<option value='offer'>Offer Received</option>
									<option value='withdraw'>Withdraw</option>
									<option value='rejected'>Rejected</option>
									<option value='accepted'>Accepted</option>
								</select>
							</div>
							<div className='form-inner_container-column'>
								<label>Notes</label>
								<textarea
									name='notes'
									className='form-textarea'
									placeholder='Notes'
									value={mergeApplicationDetails.notes}
									onChange={(e) => handleEditChange(e)}
								></textarea>
							</div>
							<div className='modal-button_container'>
								<button
									type='button'
									className='cancel'
									onClick={() => {
										setMergeApplicationDetails({});
										setShowEditModal(false);
									}}
								>
									Cancel
								</button>
								<button type='submit'>Merge</button>
							</div>
						</form>
					</Modal>
				)}
				{showAddModal && (
					<Modal
						allowClose={true}
						setShow={() => {
							setAddApplicationDetails({
								employer: '',
								jobTitle: '',
								stage: 'submitted',
								lastUpdated: today,
							});
							setShowAddModal(false);
						}}
					>
						<form
							onSubmit={(e) => {
								e.preventDefault();
							}}
							className='form p-8'
						>
							<h3 className='self-start'>Application Details</h3>
							<p></p>
							<div className='form-inner_container'>
								<label>Employer:</label>
								<input
									type='text'
									name='employer'
									className='form-input'
									placeholder='Employer'
									onChange={(e) => handleAddChange(e)}
									value={addApplicationDetails.employer}
								></input>
							</div>
							<div className='form-inner_container !mt-0'>
								<label>Job Title:</label>
								<input
									type='text'
									name='jobTitle'
									className='form-input'
									placeholder='Job Title'
									onChange={(e) => handleAddChange(e)}
									value={addApplicationDetails.jobTitle}
								></input>
							</div>
							<div className='form-inner_container'>
								<label>Stage:</label>
								<select
									className='w-[68%]'
									onChange={(e) => handleAddChange(e)}
									name='stage'
									value={addApplicationDetails.stage}
								>
									<option value='submitted'>Submitted</option>
									<option value='follow up'>Follow Up</option>
									<option value='waiting on response'>Waiting on Response</option>
									<option value='interview'>Interview</option>
									<option value='assessment'>Assessment</option>
									<option value='offer'>Offer Received</option>
									<option value='withdraw'>Withdraw</option>
									<option value='rejected'>Rejected</option>
									<option value='accepted'>Accepted</option>
								</select>
							</div>
							<div className='form-inner_container-column'>
								<label>Application Date:</label>
								<DatePicker
									autoComplete='off'
									name='date'
									selected={addApplicationDetails.lastUpdated} // set the selected date
									onChange={(date) => handleDateChange(date)} // handle date change
									className={`form-input text-bold cursor-pointer`}
									dateFormat='yyyy-MM-dd' // set the date format
									placeholderText='Select date' // set placeholder text
								/>
							</div>
							<div className='form-inner_container-column'>
								<label>Notes</label>
								<textarea
									name='notes'
									className='form-textarea'
									placeholder='Notes'
									value={addApplicationDetails.notes}
									onChange={(e) => handleAddChange(e)}
								></textarea>
							</div>
							<div className='modal-button_container'>
								<button
									type='button'
									className='cancel'
									onClick={() => {
										setAddApplicationDetails({
											employer: '',
											jobTitle: '',
											stage: '',
											lastUpdated: today,
										});
										setShowAddModal(false);
									}}
								>
									Cancel
								</button>
								<button
									type='submit'
									onClick={() => {
										handleAddApplication();
										setShowAddModal(false);
									}}
								>
									Add Application
								</button>
							</div>
						</form>
					</Modal>
				)}
			</AnimatePresence>
			<div id='actions'>
				<div className='filter'>
					<input
						type='text'
						name='Search'
						placeholder='Search applications'
						className=''
						onChange={(e) => handleSearch(e.target.value)}
						value={search}
					></input>
					<button
						title={` ${showFilters ? 'Hide Filters' : 'Show Filters'} `}
						onClick={() => {
							handleShowFilters();
						}}
						className={`secondary-button ml-2  ${showFilters ? 'active' : 'inactive'}`}
					>
						Filter <i className='fa-solid fa-filter ml-2'></i>
					</button>
					<AnimatePresence>
						{showFilters && (
							<motion.div
								initial='hidden'
								animate='visible'
								exit='hidden'
								variants={actionVariants}
								transition={{}}
								className=''
							>
								<select
									className='max-h-[200px]'
									name='employer'
									onChange={(e) => setFilters({ ...filters, employer: e.target.value })}
								>
									<option>Employer</option>
									{employers.map((employer, index) => (
										<option key={index}>{employer}</option>
									))}
								</select>
								<select
									name='jobTitle'
									onChange={(e) => setFilters({ ...filters, jobTitle: e.target.value })}
								>
									<option>Job Title</option>
									{jobTitles.map((jobTitle, index) => (
										<option key={index}>{jobTitle}</option>
									))}
								</select>
								<select
									className='stages'
									name='stage'
									onChange={(e) => setFilters({ ...filters, stage: e.target.value })}
								>
									<option>Stage</option>
									<option className='submitted'>Submitted</option>
									<option className='followup'>Follow Up</option>
									<option className='assessment'>Assessment</option>
									<option className='interview'>Interview</option>
									<option className='offer'>Offer</option>
									<option className='rejected'>Rejected</option>
									<option className='withdraw'>Withdraw</option>
									<option className='accepted'>Accepted</option>
								</select>
							</motion.div>
						)}
						<button
							className='secondary-button inactive ml-2 !py-[6px]'
							onClick={() => {
								setFilters({ employer: '', jobTitle: '', stage: '' });
								setSearch('');
							}}
						>
							Reset
						</button>
					</AnimatePresence>
				</div>
				<div className='action-buttons_container'>
					{showUpdated ? (
						<span className='mr-2 opacity-100 transition-opacity duration-500 ease-in-out flex items-center'>
							You're up to date
						</span>
					) : (
						''
					)}
					<button
						disabled={isDemo || (userData.settings && !userData.settings.autoImport)}
						title={
							userData.settings && userData.settings.autoImport
								? ''
								: formattedDate
								? `Last Import: ${formattedDate}`
								: 'Fetch New Emails'
						}
						onClick={() => {
							fetchNewEmails();
						}}
						className={`secondary-button ${isFetching ? 'active' : 'inactive'} ${
							userData.settings && userData.settings.autoImport ? '' : 'disabled'
						}`}
					>
						<i className={`fa-solid fa-rotate ${isFetching ? 'rotate-icon' : ''}`}></i>
					</button>
					<button
						title='Add New Application'
						onClick={() => {
							handleShowAddModal();
						}}
						className={`ml-2 secondary-button inactive`}
					>
						<i className={`fa-solid fa-plus`}></i>
					</button>
					<button
						title='Merge Selected Applications'
						disabled={isCheckedList.length < 2 ? 'disabled' : ''}
						onClick={() => {
							handleShowEditModal();
						}}
						className={`ml-2 secondary-button inactive ${isCheckedList.length < 2 ? 'disabled' : ''}`}
					>
						<i className='fa-solid fa-code-merge'></i>
					</button>
					<button
						title='Delete Selected Applications'
						disabled={isCheckedList.length < 1 ? 'disabled' : ''}
						onClick={() => {
							handleShowDeleteModal();
						}}
						className={`ml-2 secondary-button inactive ${isCheckedList.length < 1 ? 'disabled' : ''}`}
					>
						<i className='fa-solid fa-trash'></i>
					</button>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ApplicationActions;
