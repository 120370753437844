import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setShowNotifications } from '../../redux/modalDataReducer';
import '../../styles/UIElements/Navigation.css';
import Notifications from './Notifications';
import { setNavOpen } from '../../redux/userReducer';

const Navigation = (props) => {
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.user.userData);
	const isLoggedIn = useSelector((state) => state.loggedIn.loggedIn);
	const showNotifications = useSelector((state) => state.modalData.showNotifications);
	const isSubscribed = useSelector((state) => state.user.userSubscription);
	const navOpen = useSelector((state) => state.user.navOpen);

	const [colorMode, setColorMode] = useState(() => {
		// Determine initial color mode based on system preference
		return window.matchMedia('(prefers-color-scheme: dark)').matches;
	});

	const changeNavState = () => {
		dispatch(setNavOpen(!navOpen));
	};

	const toggleNotifications = () => {
		dispatch(setShowNotifications(!showNotifications));
	};

	// Effect to listen for changes in system color scheme preferences
	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const handleChange = (e) => {
			setColorMode(e.matches);
		};

		mediaQuery.addEventListener('change', handleChange);

		return () => mediaQuery.removeEventListener('change', handleChange);
	}, []);

	return (
		<nav>
			{!isLoggedIn && (
				<a href='/'>
					<img
						alt='jobWrangler Logo'
						src={colorMode ? '/images/jobWrangler-dark.png' : '/images/jobWrangler-light.png'}
						className='logo'
					/>
				</a>
			)}
			{isLoggedIn && (
				<div>
					<a href='/dashboard'>
						<img
							alt='jobWrangler Logo'
							src={colorMode ? '/images/jobWrangler-dark.png' : '/images/jobWrangler-light.png'}
							className='logo'
						/>
					</a>
				</div>
			)}
			<ul>
				{isLoggedIn && (
					<React.Fragment>
						<li className='no-mobile'>
							<a href='/dashboard'>Dashboard</a>
						</li>
						<li className='no-mobile'>
							<a
								className='ml-6'
								href='/feedback'
							>
								Feedback
							</a>
						</li>
						<li>
							<a
								className='relative ml-6'
								href='#notifications'
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									toggleNotifications();
								}}
							>
								<i className='fa-solid fa-bell'></i>
								{userData.notifications &&
									userData.notifications.filter((notification) => notification.seen === false).length > 0 && (
										<span className='notification-count'>
											{userData.notifications.filter((notification) => notification.seen === false).length}
										</span>
									)}
							</a>
							<Notifications />
							{!isSubscribed && (
								<div>
									<button
										onClick={() => {
											window.location.href = '/premium';
										}}
										className='premium no-mobile'
									>
										Get Premium <i className='fa-solid fa-crown'></i>
									</button>
								</div>
							)}
						</li>
						<li>
							<button
								onClick={() => {
									changeNavState();
								}}
							>
								{!navOpen ? (
									<i className='fa-solid fa-bars'></i>
								) : (
									<i className='fa-solid fa-xmark pl-[2px] pr-[1px]'></i>
								)}
							</button>
						</li>
					</React.Fragment>
				)}
				{!isLoggedIn && (
					<>
						<li>
							<a href='/login'>Login</a>
						</li>
						<li className='signup-button'>
							<a href='/signup'>Sign Up</a>
						</li>
					</>
				)}
			</ul>
		</nav>
	);
};

export default Navigation;
