import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { setLoggedIn } from '../redux/loggedInReducer';
import { setUserSubscription, setUserData, setUserTerms, setUserApplicationData } from '../redux/userReducer';
import { getAuthStatus, setAuthStatus, getSubscriptionStatus, setSubscriptionStatus } from '../utils/cookieUtils';
import { GA4React } from 'ga-4-react'; // Import GA4React directly from 'ga-4-react'

const AuthProvider = ({ children }) => {
	const dispatch = useDispatch();
	const currentPathname = window.location.pathname;

	const environment = process.env.REACT_APP_ENV;
	const testUserAuthToken = process.env.REACT_APP_FRONTEND_TEST_AUTH;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const checkDemoPage = () => {
		if (currentPathname === '/demo') {
			localStorage.setItem('authToken', testUserAuthToken);
		}
	};

	useEffect(() => {
		checkDemoPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPathname]);

	useEffect(() => {
		const checkAuthentication = async () => {
			try {
				const cachedAuthStatus = getAuthStatus();
				const cachedSubStatus = getSubscriptionStatus();

				if (
					(cachedAuthStatus && cachedAuthStatus.expiration > Date.now()) ||
					(cachedSubStatus !== null && JSON.parse(cachedSubStatus).expiration > Date.now())
				) {
					if (cachedAuthStatus) {
						dispatch(setLoggedIn(cachedAuthStatus.authenticated));
					}
					if (cachedSubStatus) {
						dispatch(setUserSubscription(JSON.parse(cachedSubStatus)));
					}
				} else {
					const response = await fetch(backendUrl + `auth/status`, {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
					});
					if (response.ok) {
						const data = await response.json();
						dispatch(setUserSubscription(data.user.subscription));
						dispatch(setUserData(data.user));
						dispatch(setUserTerms(data.user.settings.termsAccepted));
						dispatch(setUserApplicationData(data.user.applications));
						setSubscriptionStatus(data.user.subscription);
						setAuthStatus({ authenticated: data.authenticated, expiration: Date.now() + 60 * 60 * 1000 }); // 1 hour expiration
						dispatch(setLoggedIn(data.authenticated));
					} else {
						if (currentPathname === '/dashboard') {
							window.location.href = '/login';
						}
					}
				}
			} catch (error) {
				console.error('Error checking authentication:', error);
			}
		};

		checkAuthentication();
	}, [dispatch, backendUrl, currentPathname]);

	const handleAcceptCookie = async () => {
		const googleAnalyticsId = 'G-NRPEPP8TCW';
		if (googleAnalyticsId) {
			try {
				const ga4React = new GA4React(googleAnalyticsId);
				await ga4React.initialize();
			} catch (error) {
				console.error('Error initializing Google Analytics 4:', error);
			}
		}
	};

	const handleDeclineCookie = () => {
		// Remove Google Analytics cookies
		Cookies.remove('_ga');
		Cookies.remove('_ga_NRPEPP8TCW');
		Cookies.remove('_gat');
		Cookies.remove('_gid');
	};

	useEffect(() => {
		const isConsent = getCookieConsentValue();
		if (isConsent === 'true') {
			handlePageView();
		} else if (isConsent === 'false') {
			handleDeclineCookie();
		}
	}, [currentPathname]);

	const handlePageView = async () => {
		const googleAnalyticsId = 'G-NRPEPP8TCW';
		const isConsent = getCookieConsentValue();

		if (googleAnalyticsId && isConsent === 'true') {
			if (!GA4React.isInitialized()) {
				try {
					const ga4React = new GA4React(googleAnalyticsId);
					await ga4React.initialize();
					await ga4React.pageview({ page_path: window.location.pathname });
					console.log('Google Analytics 4 initialized successfully');
				} catch (error) {
					console.error('Error initializing Google Analytics 4:', error);
				}
			} else {
				try {
					const ga4React = new GA4React();
					await ga4React.pageview({ page_path: window.location.pathname });
					console.log('Google Analytics 4 page view tracked successfully');
				} catch (error) {
					console.error('Error tracking page view with Google Analytics 4:', error);
				}
			}
		}
	};

	return (
		<>
			{children}
			<CookieConsent
				enableDeclineButton
				onAccept={handleAcceptCookie}
				onDecline={handleDeclineCookie}
				buttonText='Accept'
				declineButtonText='Decline'
				containerClasses='cookie-consent_container'
				buttonClasses='btn accept-consent'
				declineButtonClasses='btn decline-consent'
			>
				We use cookies for analytics and advertising purposes, <a href='/cookies'>view our policy</a>
			</CookieConsent>
		</>
	);
};

export default AuthProvider;
