import React, { useState, useEffect } from 'react';

import '../../../styles/UIElements/Hero.css';

const Hero = () => {
	const [colorMode, setColorMode] = useState(() => {
		// Determine initial color mode based on system preference
		return window.matchMedia('(prefers-color-scheme: dark)').matches;
	});

	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const handleChange = (e) => {
			setColorMode(e.matches);
		};

		mediaQuery.addEventListener('change', handleChange);

		return () => mediaQuery.removeEventListener('change', handleChange);
	}, []);

	return (
		<React.Fragment>
			<section className='hero'>
				<div>
					<img
						alt='jobWrangler Logo'
						src={colorMode ? '/images/jobWrangler-dark.png' : '/images/jobWrangler-light.png'}
						className='logo'
					/>
					<h2>Your job search. Simplified.</h2>
				</div>
				<a
					href='/signup'
					className='button-link'
				>
					Sign Up
				</a>
			</section>
		</React.Fragment>
	);
};

export default Hero;
