import { createStore, combineReducers } from 'redux';
import userReducer from './userReducer';
import loggedInReducer from './loggedInReducer';
import modalDataReducer from './modalDataReducer';
import appDataReducer from './appDataReducer';
import taskDataReducer from './taskDataReducer';

const rootReducer = combineReducers({
	user: userReducer,
	loggedIn: loggedInReducer,
	modalData: modalDataReducer,
	appData: appDataReducer,
	taskData: taskDataReducer,
});

const store = createStore(rootReducer);

export default store;
