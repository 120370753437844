import React from 'react';

import '../../styles/UIElements/Footer.css';

const Footer = ({ isFixed }) => {
	return (
		<footer className={`${isFixed ? 'fixed w-full bottom-0' : ''} `}>
			<ul>
				<li>
					<span>© 2024 jobWrangler</span>
				</li>
				<li>
					<a href='/terms'>Terms</a>
				</li>
				<li>
					<a href='/privacy'>Privacy</a>
				</li>
				<li>
					<a href='/security'>Security</a>
				</li>
				<li>
					<a href='/feedback'>Feedback</a>
				</li>
			</ul>
		</footer>
	);
};

export default Footer;
