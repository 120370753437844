import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import { setUserSortedApplicationData } from '../../../redux/userReducer';

import Modal from '../Modal';
import '../../../styles/UIElements/ApplicationActions.css';
import { AnimatePresence, motion } from 'framer-motion';

const ApplicationActionsDemo = (props) => {
	const dispatch = useDispatch();

	// eslint-disable-next-line no-unused-vars
	const userApplicationData = useSelector((state) => state.user.userApplicationData);
	const userSortedApplicationData = useSelector((state) => state.user.userSortedApplicationData);
	const userData = useSelector((state) => state.user.userData);

	let isCheckedList = props.isCheckedList;

	const importTime = props.lastUpdated;

	const today = new Date();

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showUpdated] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [filters, setFilters] = useState({
		employer: '',
		jobTitle: '',
		stage: '',
	});
	const [search, setSearch] = useState('');
	const [mergeApplicationDetails, setMergeApplicationDetails] = useState({
		employer: '',
		jobTitle: '',
		stage: '',
		lastUpdated: today,
	});
	const [addApplicationDetails, setAddApplicationDetails] = useState({
		employer: '',
		jobTitle: '',
		stage: 'submitted',
		lastUpdated: '',
	});

	useEffect(() => {
		applySearchAndFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, filters]);

	let formattedDate;
	if (importTime) {
		const date = importTime.split('T')[0];
		const time = importTime.split('T')[1].slice(0, -8);

		formattedDate = date + ' at ' + time;
	}

	const employers = [
		...new Set(
			props.applicationData
				.filter((application) => application.employer !== null)
				.map((application) => application.employer)
		),
	].sort((a, b) => a.localeCompare(b));

	const jobTitles = [
		...new Set(
			props.applicationData
				.filter((application) => application.jobTitle !== null)
				.map((application) => application.jobTitle)
		),
	].sort((a, b) => a.localeCompare(b));

	const handleShowFilters = () => {
		setShowFilters((prevState) => !prevState);
		setFilters({ employer: '', jobTitle: '', stage: '' });
	};

	const applySearchAndFilter = () => {
		const filteredApplications = props.applicationData.filter((application) => {
			const employerMatches =
				!filters.employer ||
				(application.employer && application.employer.toLowerCase().includes(filters.employer.toLowerCase()));
			const jobTitleMatches =
				!filters.jobTitle ||
				(application.jobTitle && application.jobTitle.toLowerCase().includes(filters.jobTitle.toLowerCase()));
			const stageMatches =
				!filters.stage || (application.stage && application.stage.toLowerCase() === filters.stage.toLowerCase());

			const searchMatches =
				!search ||
				Object.values(application).some(
					(value) => value && value.toString().toLowerCase().includes(search.toLowerCase())
				) ||
				(application.emails &&
					application.emails[0] &&
					application.emails[0].sender &&
					application.emails[0].sender.toLowerCase().includes(search.toLowerCase()));

			return employerMatches && jobTitleMatches && stageMatches && searchMatches;
		});

		dispatch(setUserSortedApplicationData(filteredApplications));
	};

	const handleSearch = (search) => {
		setSearch(search);
	};

	const handleDeletion = async () => {
		const updatedApplications = userSortedApplicationData.filter(
			(application) => !isCheckedList.includes(application._id)
		);

		dispatch(setUserSortedApplicationData(updatedApplications));
		props.handleResetList();
	};

	const handleMerge = async () => {
		const currentDate = new Date().toISOString();
		let mergedApplicationIndex = -1;

		const updatedApplications = userSortedApplicationData
			.map((application, index) => {
				if (isCheckedList.includes(application._id)) {
					if (mergedApplicationIndex === -1) {
						// Merge details into the first selected application
						mergedApplicationIndex = index;
						return {
							...application,
							employer: mergeApplicationDetails.employer,
							jobTitle: mergeApplicationDetails.jobTitle,
							stage: mergeApplicationDetails.stage || 'submitted', // Use 'submitted' if stage is empty
							lastUpdated: currentDate,
							history: [
								...application.history,
								{
									stage: mergeApplicationDetails.stage || 'submitted', // Use 'submitted' if stage is empty
									date: currentDate,
									_id: Math.random().toString(36).substring(7), // Generate a random ID for the history entry
								},
							],
						};
					} else {
						return null; // Mark other selected applications for deletion
					}
				} else {
					return application; // Leave other applications unchanged
				}
			})
			.filter((application) => application !== null); // Remove applications marked for deletion

		if (mergedApplicationIndex !== -1) {
			const sortedUpdatedApplications = updatedApplications.slice().sort((a, b) => {
				const dateA = new Date(a.lastUpdated);
				const dateB = new Date(b.lastUpdated);
				return dateB - dateA; // Descending order
			});

			dispatch(setUserSortedApplicationData(sortedUpdatedApplications));
			setMergeApplicationDetails({
				employer: '',
				jobTitle: '',
				stage: '',
				lastUpdated: currentDate,
			});
			props.handleResetList();
			setShowEditModal(false);
		} else {
			// Handle case when no application was merged
			console.log('No application selected for merge.');
		}
	};

	const handleDateChange = (date) => {
		if (date instanceof Date) {
			const formattedDate = date.toISOString().split('T')[0] + 'T00:00:00.000Z';
			setAddApplicationDetails((prevState) => ({
				...prevState,
				lastUpdated: formattedDate,
			}));
		}
	};
	const handleAddChange = (e) => {
		const { name, value } = e.target;
		if (name === 'stage') {
			// Handle the select element separately
			setAddApplicationDetails((prevState) => ({
				...prevState,
				stage: value,
			}));
		} else {
			// For other input fields
			setAddApplicationDetails((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleEditChange = async (e) => {
		const { name, value } = e.target;
		if (name === 'stage') {
			// Handle the select element separately
			setMergeApplicationDetails((prevState) => ({
				...prevState,
				stage: value,
			}));
		} else {
			// For other input fields
			setMergeApplicationDetails((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleAddApplication = async () => {
		const randomId = Math.random().toString(36).substring(7);
		let currentDate;
		if (addApplicationDetails.lastUpdated) {
			currentDate = addApplicationDetails.lastUpdated;
		} else {
			currentDate = new Date().toISOString();
		}
		const newApplication = {
			_id: randomId,
			lastUpdated: currentDate,
			jobTitle: addApplicationDetails.jobTitle,
			employer: addApplicationDetails.employer,
			stage: addApplicationDetails.stage,
			followUp: false,
			userAdded: true,
			rejected: false,
			emails: [],
			review: false,
			history: [
				{
					stage: 'submitted',
					date: currentDate,
					_id: randomId,
				},
			],
			__v: 0,
		};
		const updatedApplications = [...userSortedApplicationData, newApplication];
		const sortedUserApplicationData = updatedApplications.slice().sort((a, b) => {
			const dateA = new Date(a.lastUpdated);
			const dateB = new Date(b.lastUpdated);
			return dateB - dateA; // Descending order
		});
		dispatch(setUserSortedApplicationData(sortedUserApplicationData));
		setAddApplicationDetails({
			employer: '',
			jobTitle: '',
			stage: 'submitted',
			lastUpdated: today,
		});
	};

	const handleShowDeleteModal = () => {
		setShowDeleteModal((prevState) => !prevState);
	};
	const handleShowEditModal = () => {
		setShowEditModal((prevState) => !prevState);
	};
	const handleShowAddModal = () => {
		setShowAddModal((prevState) => !prevState);
	};

	const [isDemo, setIsDemo] = useState(false);
	const currentPathname = window.location.pathname;

	const checkDemoPage = () => {
		if (currentPathname === '/demo') {
			setIsDemo(true);
		}
	};

	useEffect(() => {
		checkDemoPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPathname]);

	const actionVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<React.Fragment>
			<AnimatePresence>
				{showDeleteModal && (
					<Modal
						allowClose={true}
						setShow={() => setShowDeleteModal(false)}
					>
						<div className='p-8'>
							<h3>Are you sure you want to delete these applications?</h3>
							<p>
								If you are deleting them because they were incorrectly imported, please let us know by flagging and
								deleting them.{' '}
							</p>
							<div className='modal-button_container'>
								<button
									className='cancel'
									onClick={() => {
										setShowDeleteModal(false);
									}}
								>
									Cancel
								</button>
								<button
									className='secondary delete'
									onClick={() => {
										handleDeletion();
										setShowDeleteModal(false);
									}}
								>
									Delete
								</button>
								<button
									className='primary delete'
									onClick={async () => {
										handleDeletion();
										setShowDeleteModal(false);
									}}
								>
									Flag and Delete
								</button>
							</div>
						</div>
					</Modal>
				)}
				{showEditModal && (
					<Modal
						allowClose={true}
						setShow={() => setShowEditModal(false)}
					>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleMerge();
							}}
							className='form p-8'
						>
							<h3 className='self-start'>Application Details</h3>
							<p></p>
							<div className='form-inner_container'>
								<label>Employer:</label>
								<input
									type='text'
									name='employer'
									className='form-input'
									placeholder='Employer'
									onChange={(e) => handleEditChange(e)}
									value={mergeApplicationDetails.employer}
								></input>
							</div>
							<div className='form-inner_container !mt-0'>
								<label>Job Title:</label>
								<input
									type='text'
									name='jobTitle'
									className='form-input'
									placeholder='Job Title'
									onChange={(e) => handleEditChange(e)}
									value={mergeApplicationDetails.jobTitle}
								></input>
							</div>
							<div className='form-inner_container'>
								<label>Stage:</label>
								<select
									className='w-[68%]'
									onChange={(e) => handleEditChange(e)}
									name='stage'
									value={mergeApplicationDetails.stage}
								>
									<option value='submitted'>Submitted</option>
									<option value='follow up'>Follow Up</option>
									<option value='waiting on response'>Waiting on Response</option>
									<option value='interview'>Interview</option>
									<option value='assessment'>Assessment</option>
									<option value='offer'>Offer Received</option>
									<option value='withdraw'>Withdraw</option>
									<option value='rejected'>Rejected</option>
									<option value='accepted'>Accepted</option>
								</select>
							</div>
							<div className='form-inner_container-column'>
								<label>Notes</label>
								<textarea
									name='notes'
									className='form-textarea'
									placeholder='Notes'
									value={mergeApplicationDetails.notes}
									onChange={(e) => handleEditChange(e)}
								></textarea>
							</div>
							<div className='modal-button_container'>
								<button
									type='button'
									className='cancel'
									onClick={() => {
										setMergeApplicationDetails({});
										setShowEditModal(false);
									}}
								>
									Cancel
								</button>
								<button type='submit'>Merge</button>
							</div>
						</form>
					</Modal>
				)}
				{showAddModal && (
					<Modal
						allowClose={true}
						setShow={() => {
							setAddApplicationDetails({
								employer: '',
								jobTitle: '',
								stage: 'submitted',
								lastUpdated: today,
							});
							setShowAddModal(false);
						}}
					>
						<form
							onSubmit={(e) => {
								e.preventDefault();
							}}
							className='form p-8'
						>
							<h3 className='self-start'>Application Details</h3>
							<p></p>
							<div className='form-inner_container'>
								<label>Employer:</label>
								<input
									type='text'
									name='employer'
									className='form-input'
									placeholder='Employer'
									onChange={(e) => handleAddChange(e)}
									value={addApplicationDetails.employer}
								></input>
							</div>
							<div className='form-inner_container !mt-0'>
								<label>Job Title:</label>
								<input
									type='text'
									name='jobTitle'
									className='form-input'
									placeholder='Job Title'
									onChange={(e) => handleAddChange(e)}
									value={addApplicationDetails.jobTitle}
								></input>
							</div>
							<div className='form-inner_container'>
								<label>Stage:</label>
								<select
									className='w-[68%]'
									onChange={(e) => handleAddChange(e)}
									name='stage'
									value={addApplicationDetails.stage}
								>
									<option value='submitted'>Submitted</option>
									<option value='follow up'>Follow Up</option>
									<option value='waiting on response'>Waiting on Response</option>
									<option value='interview'>Interview</option>
									<option value='assessment'>Assessment</option>
									<option value='offer'>Offer Received</option>
									<option value='withdraw'>Withdraw</option>
									<option value='rejected'>Rejected</option>
									<option value='accepted'>Accepted</option>
								</select>
							</div>
							<div className='form-inner_container-column'>
								<label>Application Date:</label>
								<DatePicker
									autoComplete='off'
									name='date'
									selected={addApplicationDetails.lastUpdated} // set the selected date
									onChange={(date) => handleDateChange(date)} // handle date change
									className={`form-input text-bold cursor-pointer`}
									dateFormat='yyyy-MM-dd' // set the date format
									placeholderText='Select date' // set placeholder text
								/>
							</div>
							<div className='form-inner_container-column'>
								<label>Notes</label>
								<textarea
									name='notes'
									className='form-textarea'
									placeholder='Notes'
									value={addApplicationDetails.notes}
									onChange={(e) => handleAddChange(e)}
								></textarea>
							</div>
							<div className='modal-button_container'>
								<button
									type='button'
									className='cancel'
									onClick={() => {
										setAddApplicationDetails({
											employer: '',
											jobTitle: '',
											stage: '',
											lastUpdated: today,
										});
										setShowAddModal(false);
									}}
								>
									Cancel
								</button>
								<button
									type='submit'
									onClick={() => {
										handleAddApplication();
										setShowAddModal(false);
									}}
								>
									Add Application
								</button>
							</div>
						</form>
					</Modal>
				)}
			</AnimatePresence>
			<div id='actions'>
				<div className='filter'>
					<input
						type='text'
						name='Search'
						placeholder='Search applications'
						className=''
						onChange={(e) => handleSearch(e.target.value)}
						value={search}
					></input>
					<button
						title={` ${showFilters ? 'Hide Filters' : 'Show Filters'} `}
						onClick={() => {
							handleShowFilters();
						}}
						className={`secondary-button ml-2  ${showFilters ? 'active' : 'inactive'}`}
					>
						Filter <i className='fa-solid fa-filter ml-2'></i>
					</button>
					<AnimatePresence>
						{showFilters && (
							<motion.div
								initial='hidden'
								animate='visible'
								exit='hidden'
								variants={actionVariants}
								transition={{}}
								className=''
							>
								<select
									className='max-h-[200px]'
									name='employer'
									onChange={(e) => setFilters({ ...filters, employer: e.target.value })}
								>
									<option>Employer</option>
									{employers.map((employer, index) => (
										<option key={index}>{employer}</option>
									))}
								</select>
								<select
									name='jobTitle'
									onChange={(e) => setFilters({ ...filters, jobTitle: e.target.value })}
								>
									<option>Job Title</option>
									{jobTitles.map((jobTitle, index) => (
										<option key={index}>{jobTitle}</option>
									))}
								</select>
								<select
									className='stages'
									name='stage'
									onChange={(e) => setFilters({ ...filters, stage: e.target.value })}
								>
									<option>Stage</option>
									<option className='submitted'>Submitted</option>
									<option className='followup'>Follow Up</option>
									<option className='assessment'>Assessment</option>
									<option className='interview'>Interview</option>
									<option className='offer'>Offer</option>
									<option className='rejected'>Rejected</option>
									<option className='withdraw'>Withdraw</option>
									<option className='accepted'>Accepted</option>
								</select>
							</motion.div>
						)}
						<button
							className='secondary-button inactive ml-2 !py-[6px]'
							onClick={() => {
								setFilters({ employer: '', jobTitle: '', stage: '' });
								setSearch('');
							}}
						>
							Reset
						</button>
					</AnimatePresence>
				</div>
				<div className='action-buttons_container'>
					{showUpdated ? (
						<span className='mr-2 opacity-100 transition-opacity duration-500 ease-in-out flex items-center'>
							You're up to date
						</span>
					) : (
						''
					)}
					<button
						disabled={isDemo || (userData.settings && !userData.settings.autoImport)}
						title={
							userData.settings && userData.settings.autoImport
								? ''
								: formattedDate
								? `Last Import: ${formattedDate}`
								: 'Fetch New Emails'
						}
						onClick={() => {}}
						className={`secondary-button inactive ${
							userData.settings && userData.settings.autoImport ? '' : 'disabled'
						}`}
					>
						<i className={`fa-solid fa-rotate`}></i>
					</button>
					<button
						title='Add New Application'
						onClick={() => {
							handleShowAddModal();
						}}
						className={`ml-2 secondary-button inactive`}
					>
						<i className={`fa-solid fa-plus`}></i>
					</button>
					<button
						title='Merge Selected Applications'
						disabled={isCheckedList.length < 2 ? 'disabled' : ''}
						onClick={() => {
							handleShowEditModal();
						}}
						className={`ml-2 secondary-button inactive ${isCheckedList.length < 2 ? 'disabled' : ''}`}
					>
						<i className='fa-solid fa-code-merge'></i>
					</button>
					<button
						title='Delete Selected Applications'
						disabled={isCheckedList.length < 1 ? 'disabled' : ''}
						onClick={() => {
							handleShowDeleteModal();
						}}
						className={`ml-2 secondary-button inactive ${isCheckedList.length < 1 ? 'disabled' : ''}`}
					>
						<i className='fa-solid fa-trash'></i>
					</button>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ApplicationActionsDemo;
