import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { AnimatePresence, motion } from 'framer-motion';

import ProgressBar from '../ProgressBar';

import Modal from '../Modal';

import '../../../styles/UIElements/Forms.css';
import '../../../styles/UIElements/Modals.css';
import 'react-datepicker/dist/react-datepicker.css';
import { setUserTerms, setUserStartDate, setUserData } from '../../../redux/userReducer';

const SignUpWalkthrough = (props) => {
	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}
	const dispatch = useDispatch();

	const [currentStep, setCurrentStep] = useState(1);

	const [step1Data, setStep1Data] = useState({
		startedDate: '',
		keywords: [],
		industry: '',
	});
	const [step2Data, setStep2Data] = useState({
		autoImport: false,
		excludedWords: [],
		optOut: true,
	});

	const [step3Data, setStep3Data] = useState({
		termsAccepted: false,
	});

	const [formErrors, setFormErrors] = useState({});
	const [formSuccess, setFormSuccess] = useState('');

	const handleChangeStep1 = (e) => {
		const { name, value } = e.target;

		setStep1Data({
			...step1Data,
			[name]: value,
		});

		// Reset form errors and success
		setFormErrors({
			...formErrors,
			[name]: '',
		});
		setFormSuccess('');
	};

	const handleChangeStep2 = (e) => {
		const { name, type, value, checked } = e.target;
		if (type === 'checkbox') {
			setStep2Data({
				...step2Data,
				[name]: checked,
			});
		} else {
			setStep2Data({
				...step2Data,
				[name]: value,
			});
		}

		setFormErrors({
			...formErrors,
			[name]: '',
		});

		setFormSuccess('');
	};

	const handleChangeStep3 = (e) => {
		const { name, type, value, checked } = e.target;
		if (type === 'checkbox') {
			setStep3Data({
				...step3Data,
				[name]: checked,
			});
		} else {
			setStep3Data({
				...step3Data,
				[name]: value,
			});
		}

		setFormErrors({
			...formErrors,
			[name]: '',
		});

		setFormSuccess('');
	};

	// Handle form submission based on the current step
	const handleSubmit = async (e) => {
		e.preventDefault();
		// Validation logic for each step
		if (currentStep === 1) {
			const currentDate = new Date();
			const enteredDate = new Date(step1Data.startedDate);

			const errors = {};

			if (!step1Data.startedDate) {
				errors.startedDate = 'Please enter a start date.';
			}
			if (!step1Data.industry || step1Data.industry === 'Select Industry') {
				errors.startedDate = 'Please select your industry.';
			}
			if (enteredDate > currentDate) {
				errors.startedDate = 'Start date cannot be in the future.';
			}
			if (step1Data.keywords.length === 0) {
				errors.keywordInput = 'Please add some keywords.';
			}
			if (Object.keys(errors).length > 0) {
				// Set formErrors state to display errors beneath each field
				setFormErrors(errors);
				return;
			} else {
				setFormErrors({});
				setCurrentStep(2);
			}
		} else if (currentStep === 2) {
			// Step two doesn't really need validating
			setFormErrors({});
			setCurrentStep(3);
		} else if (currentStep === 3) {
			const errors = {};

			if (!step3Data.termsAccepted) {
				errors.termsAccepted = 'You must agree to the Terms and Conditions.';
			}
			if (Object.keys(errors).length > 0) {
				// Set formErrors state to display errors beneath each field
				setFormErrors(errors);
				return;
			} else {
				await submitForm();
			}
		}
	};

	const submitForm = async () => {
		try {
			const formDataOne = {
				settings: {
					industry: step1Data.industry,
					autoImport: Boolean(step2Data.autoImport),
					termsAccepted: Boolean(step3Data.termsAccepted),
					startedDate: step1Data.startedDate,
					optOut: Boolean(step2Data.optOut),
				},
			};
			const response = await fetch(backendUrl + 'user/update', {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(formDataOne),
			});
			const data = await response.json();
			if (response.ok) {
				dispatch(setUserStartDate(formDataOne.startedDate));
				setStep1Data({ startedDate: '' });
				setStep2Data({ autoImport: false, optOut: false });
				setStep3Data({ termsAccepted: false });
				dispatch(setUserData(data.user));
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
		try {
			const formDataTwo = {
				settings: {
					keywords: step1Data.keywords,
					excludedWords: step2Data.excludedWords,
				},
			};
			const response = await fetch(backendUrl + 'user/push', {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(formDataTwo),
			});
			if (response.ok) {
				setStep1Data({ keywords: [] });
				setStep2Data({ excludedWords: [] });
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
		dispatch(setUserTerms(true));
	};

	const formVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};
	const slideVariants = {
		hidden: { y: '100%', opacity: 0 },
		visible: { y: 0, opacity: 1 },
	};
	const slideUpVariants = {
		hidden: { y: '-20%' },
		visible: { y: 0 },
	};
	const keywordVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	// Render the form based on the current step
	const renderForm = () => {
		switch (currentStep) {
			case 1:
				return (
					<>
						<div className='mb-8'>
							<h2 className=''>Welcome to jobWrangler!</h2>
							<p>
								Please fill out the form below to help us learn a little bit about you and your search. This information
								will help us tailor the experience to your needs.
							</p>
						</div>
						<label>When did you start your job search?</label>
						<DatePicker
							autoComplete='off'
							selected={step1Data.startedDate} // set the selected date
							onChange={(date) => {
								setFormErrors({
									...formErrors,
									startedDate: '',
								});

								const currentDate = new Date();
								const sixMonthsAgo = new Date();
								sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

								if (date < sixMonthsAgo) {
									// Date is more than 6 months ago, handle accordingly (e.g., show error message)
									setFormErrors({
										...formErrors,
										startedDate: 'Start date cannot be more than 6 months ago.',
									});
									return;
								}

								if (date > currentDate) {
									// Date is in the future, handle accordingly (e.g., show error message)
									setFormErrors({
										...formErrors,
										startedDate: 'Start date cannot be in the future.',
									});
									return;
								}

								setStep1Data({ ...step1Data, startedDate: date });
							}} // handle date change
							className={`form-input text-bold cursor-pointer ${formErrors.startedDate ? 'input-error' : ''}`}
							dateFormat='yyyy-MM-dd' // set the date format
							placeholderText='Select start date' // set placeholder text
						/>
						{formErrors.startedDate && <div className='field-error'>{formErrors.startedDate}</div>}
						<label>What industry are you looking in?</label>
						<select
							className='industry w-full text-zinc-900 dark:text-zinc-400'
							onChange={(e) => {
								handleChangeStep1(e);
								setStep1Data({ ...step1Data, industry: e.target.value });
							}}
							name='industry'
							value={step1Data.industry}
						>
							<option value=''>Select industry</option>
							<option value='accounting'>Accounting</option>
							<option value='administration'>Administration & Office Support</option>
							<option value='advertising'>Advertising, Arts & Media</option>
							<option value='customerservice'>Customer Service</option>
							<option value='construction'>Construction</option>
							<option value='consulting'>Consulting & Strategy</option>
							<option value='designarchitecture'>Design & Architechture</option>
							<option value='education'>Education & Training</option>
							<option value='financial'>Financial Services</option>
							<option value='government'>Government & Defence</option>
							<option value='healthcare'>Healthcare & Medical</option>
							<option value='hospitality'>Hospitality & Tourism</option>
							<option value='humanresources'>Human Resources & Recruitment</option>
							<option value='IT'>Information Technology</option>
							<option value='insurance'>Insurance</option>
							<option value='legal'>Legal</option>
							<option value='manufacturing'>Manufacturing</option>
							<option value='marketing'>Marketing & Communications</option>
							<option value='retail'>Retail</option>
							<option value='sales'>Sales</option>
							<option value='science'>Science</option>
							<option value='sports'>Sports & Recreation</option>
							<option value='technology'>Technology</option>
							<option value='trades'>Trades & Services</option>
							<option value='transportation'>Transport & Logistics</option>
							<option value='other'>Other</option>
						</select>
						{formErrors.industry && <div className='field-error'>{formErrors.industry}</div>}
						<label>What types of roles are you applying for?</label>
						<div className='flex w-full relative'>
							<input
								type='text'
								name='keywordInput'
								placeholder='ie. Web Developer, Software Engineer, Data Analyst'
								className={`form-input flex-1 ${formErrors.keywordInput ? 'input-error' : ''}`}
								value={step1Data.keywordInput}
								onChange={(e) => {
									handleChangeStep1(e);
									setStep1Data({ ...step1Data, keywordInput: e.target.value });
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
										e.preventDefault();
										if (step1Data.keywords.length >= 15) {
											setFormErrors({
												...formErrors,
												keywordInput: 'Please limit yourself to 15 keywords.',
											});
											return;
										}
										if (step1Data.keywordInput.trim()) {
											if (step1Data.keywords.find((keyword) => keyword === step1Data.keywordInput.trim())) {
												setFormErrors({
													...formErrors,
													keywordInput: 'You have already added this keyword.',
												});
											} else {
												setStep1Data({
													...step1Data,
													keywords: [...step1Data.keywords, step1Data.keywordInput.trim().toLowerCase()],
													keywordInput: '', // Clear the input field
												});
											}
										}
									}
								}}
							/>
							<button
								className='absolute right-0 top-1 h-full px-3 py-2 fix-pad-right'
								onClick={(e) => {
									e.preventDefault();
									handleChangeStep1(e);
									if (step1Data.keywordInput) {
										setStep1Data({
											...step1Data,
											keywords: [...step1Data.keywords, step1Data.keywordInput.trim().toLowerCase()],
											keywordInput: '', // Clear the input field
										});
									}
								}}
							>
								<i className='fas fa-plus text-s font-bold text-sky-800 ml-3 hover:opacity-60 transition-all'></i>
							</button>
						</div>
						{formErrors.keywordInput && <div className='field-error'>{formErrors.keywordInput}</div>}
						{step1Data.keywords.length > 0 && (
							<div className='mt-2'>
								<strong>Selected Job Titles:</strong>
								<div className='flex flex-wrap mt-2'>
									<AnimatePresence>
										{step1Data.keywords.map((keyword, index) => (
											<motion.div
												key={index}
												initial='hidden'
												animate='visible'
												exit='exit'
												variants={keywordVariants}
												transition={{ duration: 0.2, ease: 'easeInOut' }}
												className='keywords-container'
											>
												<span className='mr-1 text-zinc-900 font-bold text-base'>{keyword}</span>
												<button
													className='text-zinc-900 font-bold'
													onClick={(e) => {
														e.preventDefault();
														setFormErrors({
															...formErrors,
															keywordInput: '',
														});
														const updatedKeywords = step1Data.keywords.filter((k, i) => i !== index);
														setStep1Data({ ...step1Data, keywords: updatedKeywords });
													}}
												>
													<i className='fas fa-x text-xs font-bold text-sky-800 ml-3 hover:opacity-60 transition-all'></i>
												</button>
											</motion.div>
										))}
									</AnimatePresence>
								</div>
							</div>
						)}
					</>
				);
			case 2:
				return (
					<>
						<motion.div
							initial='hidden'
							animate='visible'
							exit='hidden'
							variants={formVariants}
							transition={{}}
						>
							<motion.div
								initial=''
								animate={step2Data.autoImport ? 'hidden' : 'visible'}
								exit=''
								variants={slideUpVariants}
								transition={{ duration: 0.2 }}
							>
								<div className='mb-8'>
									<h2>The Important Part</h2>
									<p>
										Our greatest feature is scanning your inbox for jobs that you have applied to and automatically
										importing them. If you would like to try out this functionality please check this box. You can
										change your preferences at any time.
									</p>
								</div>
								<label className=''>Would you like us to import applications from your email?</label>
								<span className='checkbox-container'>
									<input
										type='checkbox'
										name='autoImport'
										placeholder='test'
										className='form-input'
										value={step2Data.autoImport}
										checked={step2Data.autoImport}
										onChange={(e) => {
											setStep2Data({ ...step2Data, autoImport: e.target.checked });
										}}
									/>
									<span>I agree to having my applications imported from my Gmail account</span>
								</span>
							</motion.div>
							<AnimatePresence>
								{step2Data.autoImport && (
									<motion.div
										initial='hidden'
										animate='visible'
										exit='hidden'
										variants={formVariants}
										transition={{}}
									>
										<motion.div
											initial=''
											animate={step2Data.autoImport ? 'visible' : 'hidden'}
											exit=''
											variants={slideVariants}
											transition={{ duration: 0.2 }}
										>
											<label className=''>Are there specific phrases you don't want us to scan for?</label>
											<div className='flex w-full relative mb-4'>
												<input
													type='text'
													name='excludedWordsInput'
													placeholder='ie. TD Bank, myHealthInformation, etc.'
													className={`form-input flex-1 ${formErrors.excludedWordsInput ? 'input-error' : ''}`}
													value={step2Data.excludedWordsInput}
													onChange={(e) => {
														handleChangeStep2(e);
														setStep2Data({ ...step2Data, excludedWordsInput: e.target.value });
													}}
													onKeyDown={(e) => {
														if (e.key === 'Enter' || e.key === 'Tab') {
															e.preventDefault();
															if (step2Data.excludedWords.length >= 15) {
																setFormErrors({
																	...formErrors,
																	excludedWordsInput: 'Please limit yourself to 15 excluded phrases.',
																});
																return;
															}
															if (step2Data.excludedWordsInput.trim()) {
																if (
																	step2Data.excludedWords.find(
																		(excludedWord) => excludedWord === step2Data.excludedWordsInput.trim()
																	)
																) {
																	setFormErrors({
																		...formErrors,
																		excludedWordsInput: 'You have already added this word to the exclusion list.',
																	});
																} else {
																	setStep2Data({
																		...step2Data,
																		excludedWords: [
																			...step2Data.excludedWords,
																			step2Data.excludedWordsInput.trim().toLowerCase(),
																		],
																		excludedWordsInput: '', // Clear the input field
																	});
																}
															}
														}
													}}
												/>
												<button
													className='absolute right-0 top-1 h-full px-3 py-2 fix-pad-right'
													onClick={(e) => {
														e.preventDefault();
														handleChangeStep2(e);
														if (step2Data.excludedWordsInput) {
															setStep2Data({
																...step2Data,
																excludedWords: [
																	...step2Data.excludedWords,
																	step2Data.excludedWordsInput.trim().toLowerCase(),
																],
																excludedWordsInput: '', // Clear the input field
															});
														}
													}}
												>
													<i className='fas fa-plus text-s font-bold text-sky-800 ml-3 hover:opacity-60 transition-all'></i>
												</button>
											</div>
											{formErrors.excludedWordsInput && (
												<div className='field-error'>{formErrors.excludedWordsInput}</div>
											)}
											{step2Data.excludedWords.length > 0 && (
												<div className='mt-4 mb-4'>
													<strong>Excluded Words:</strong>
													<div className='flex flex-wrap mt-2'>
														<AnimatePresence>
															{step2Data.excludedWords.map((excludedWord, index) => (
																<motion.div
																	key={index}
																	initial='hidden'
																	animate='visible'
																	exit='exit'
																	variants={keywordVariants}
																	transition={{ duration: 0.2, ease: 'easeInOut' }}
																	className='keywords-container'
																>
																	<span className='mr-1 text-zinc-900 font-bold text-base'>{excludedWord}</span>
																	<button
																		className='text-zinc-900 font-bold'
																		onClick={(e) => {
																			e.preventDefault();
																			setFormErrors({
																				...formErrors,
																				excludedWordsInput: '',
																			});
																			const updatedExcludedWords = step2Data.excludedWords.filter(
																				(k, i) => i !== index
																			);
																			setStep2Data({ ...step2Data, excludedWords: updatedExcludedWords });
																		}}
																	>
																		<i className='fas fa-x text-xs font-bold text-sky-800 ml-3 hover:opacity-60 transition-all'></i>
																	</button>
																</motion.div>
															))}
														</AnimatePresence>
													</div>
												</div>
											)}
											<p className='mb-4 mt-8'>
												We use a machine learning model to classify imported emails. This model is constantly growing
												and evolving to get better at what it does. The data given to the model does not include
												potentially sensitive user information like emails or phone numbers.
											</p>
											<label className=''>
												Can we use your emails to help improve our Machine Learning model?
												<br />
											</label>
											<span className='checkbox-container'>
												<input
													type='checkbox'
													name='optOut'
													placeholder=''
													className='form-input'
													checked={!step2Data.optOut}
													onChange={(e) => setStep2Data({ ...step2Data, optOut: !e.target.checked })}
												/>
												<span>I consent to including my emails for learning purposes</span>
											</span>
										</motion.div>
									</motion.div>
								)}
							</AnimatePresence>
						</motion.div>
					</>
				);
			case 3:
				return (
					<>
						<div>
							<h2>Terms and Conditions</h2>
							<section className='terms-scroller overflow-y-scroll max-h-64 text-sm'>
								<ol>
									<li>
										<h2>Account Creation and Authentication</h2>
										<ul>
											<li>
												1.1 To access certain features of our Services, you may be required to create an account. You
												can create an account using a username and password or by using Google social login.
											</li>
											<li>
												1.2 You are responsible for maintaining the confidentiality of your account information, and you
												agree to accept responsibility for all activities that occur under your account.
											</li>
										</ul>
									</li>

									<li>
										<h2>Email Access and Processing</h2>
										<ul>
											<li>
												2.1 Our Services may allow you to connect your Gmail account to extract job application-related
												emails.
											</li>
											<li>
												2.2 By connecting your Gmail account, you grant jobWrangler permission to access, view, and
												process your job application-related emails.
											</li>
											<li>
												2.3 We use machine learning algorithms to analyze and determine whether an email is a job
												application and to extract relevant details from such emails.
											</li>
											<li>
												2.4 We use artificial intelligence to analyze and extract the employer, job title, and
												application stage from emails classified as job applications.
											</li>
										</ul>
									</li>

									<li>
										<h2>Privacy and Data Security</h2>
										<ul>
											<li>
												3.1 We take privacy seriously. Please review our Privacy Policy for information on how we
												collect, use, and safeguard your personal information.
											</li>
											<li>
												3.2 While we strive to protect your data, we cannot guarantee the security of information
												transmitted over the internet. You acknowledge and agree that you provide information at your
												own risk.
											</li>
										</ul>
									</li>

									<li>
										<h2>User Responsibilities</h2>
										<ul>
											<li>4.1 You agree not to use our Services for any unlawful or unauthorized purpose.</li>
											<li>
												4.2 You are responsible for the accuracy and legality of the information you provide to us.
											</li>
										</ul>
									</li>

									<li>
										<h2>Disclaimer</h2>
										<ul>
											<li>
												5.1 Our Services are provided on an "as-is" basis. We make no warranties, expressed or implied,
												regarding the accuracy or reliability of the information obtained through our Services.
											</li>
										</ul>
									</li>

									<li>
										<h2>Termination</h2>
										<ul>
											<li>
												6.1 We reserve the right to suspend or terminate your access to the Services at our discretion,
												without notice, for any reason.
											</li>
										</ul>
									</li>

									<li>
										<h2>Changes to Terms</h2>
										<ul>
											<li>
												7.1 We may modify these Terms at any time. Your continued use of the Services after changes are
												posted constitutes your acceptance of the modified Terms.
											</li>
										</ul>
									</li>

									<li>
										<h2>Contact Information</h2>
										<ul>
											<li>
												8.1 For questions or concerns about these Terms, please contact us at [Your Contact Email].
											</li>
										</ul>
									</li>
								</ol>
							</section>
						</div>
						<span className='checkbox-container mt-4'>
							<input
								type='checkbox'
								name='termsAccepted'
								placeholder=''
								className='form-input'
								value={step3Data.termsAccepted}
								onChange={(e) => {
									handleChangeStep3(e);
									setStep3Data({ ...step3Data, termsAccepted: e.target.checked });
								}}
							/>
							<span>I agree to the Terms and Conditions</span>
						</span>
						{formErrors.termsAccepted && <div className='field-error'>{formErrors.termsAccepted}</div>}
					</>
				);
			default:
				return null;
		}
	};

	return (
		<React.Fragment>
			{formSuccess && <div className='success'>{formSuccess}</div>}
			{!formSuccess && (
				<Modal allowClose={false}>
					<AnimatePresence>
						<form
							onSubmit={handleSubmit}
							className='form p-16 pb-4'
						>
							<motion.div
								key={currentStep}
								initial='hidden'
								animate='visible'
								exit='hidden'
								variants={formVariants}
								transition={{}}
							>
								<div className={`form-step currentStep-${currentStep}`}>{renderForm()}</div>
								{currentStep === 1 && (
									<div className='modal-button_container modal-button_container-form'>
										<button
											type='submit'
											className='form-button'
										>
											{currentStep === 1 ? 'Next' : 'Submit'}
										</button>
									</div>
								)}
								{currentStep === 2 && (
									<div className='modal-button_container modal-button_container-form'>
										<button
											type='button'
											onClick={() => setCurrentStep(1)}
											className='form-button cancel'
										>
											Previous
										</button>
										<button
											type='button'
											onClick={() => setCurrentStep(3)}
											className='form-button'
										>
											Next
										</button>
									</div>
								)}
								{currentStep === 3 && (
									<div className='modal-button_container modal-button_container-form'>
										<button
											type='button'
											onClick={() => setCurrentStep(2)}
											className='form-button cancel'
										>
											Previous
										</button>
										<button
											type='submit'
											className='form-button'
										>
											{currentStep === 1 ? 'Next' : 'Submit'}
										</button>
									</div>
								)}
							</motion.div>
						</form>
					</AnimatePresence>
					<div className='m-auto walkthrough'>
						<ProgressBar
							stepNames={['Welcome', 'The Important Part', 'Terms & Conditions']}
							currentStep={currentStep}
							totalSteps={3}
						/>
					</div>
				</Modal>
			)}
		</React.Fragment>
	);
};

export default SignUpWalkthrough;
