import React from 'react';

import '../../../styles/UIElements/Banner.css';

const PremiumSubscribe = () => {
	return (
		<React.Fragment>
			<section className='banner'>
				<div className='inner-container'>
					<h3 className='text-center'>Ready to change the way you track?</h3>
					<p className='text-center'>Subscribe to jobWrangler Premium for just $9.99/month</p>
					<div className='mt-8'>
						<a
							className='button-link'
							href='/subscribe'
						>
							Subscribe <i className='fa-solid fa-crown text-yellow-400'></i>
						</a>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default PremiumSubscribe;
