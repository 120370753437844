import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';

import { setUserApplicationData } from '../../../redux/userReducer';

import Modal from '../Modal';
import ApplicationLimited from '../Applications/ApplicationLimited';
import '../../../styles/UIElements/Navigation.css';

const InboxSearch = (props) => {
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.user.userData);
	const isLoggedIn = useSelector((state) => state.loggedIn.loggedIn);
	const subsetData = useSelector((state) => state.user.userSortedApplicationData);
	const userApplicationData = useSelector((state) => state.user.userApplicationData);
	const [localSearchApplications, setLocalSearchApplications] = useState('');
	const isSubscribed = useSelector((state) => state.user.userSubscription);

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const searchRef = useRef(null);

	const today = new Date();

	const [appData, setAppData] = useState([...subsetData]);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [formErrors, setFormErrors] = useState({});
	const [isSearching, setIsSearching] = useState(false);
	const [selectedItemIndex, setSelectedItemIndex] = useState(null);
	const [isAddingList, setIsAddingList] = useState(Array(searchResults.length).fill(false));
	const [isAddedList, setIsAddedList] = useState(Array(searchResults.length).fill(false));
	const [showMergeModal, setShowMergeModal] = useState(false);
	const [formData, setFormData] = useState({
		lastUpdated: today,
		jobTitle: '',
		employer: '',
		stage: '',
		notes: '',
	});
	const [emailData, setEmailData] = useState({});
	const [applicationId, setApplicationId] = useState('');
	const [selectedAppId, setSelectedAppId] = useState('');

	const fetchQueriedEmails = async () => {
		if (userData.settings && userData.settings.autoImport) {
			const errors = {};
			if (searchQuery.length < 1) {
				errors.searchQuery = 'Please enter a search.';
			}
			if (Object.keys(errors).length > 0) {
				// Set formErrors state to display errors beneath each field
				setFormErrors(errors);
				return;
			}
			setIsSearching(true);
			setFormErrors({});
			try {
				const response = await fetch(backendUrl + `fetch-emails/google/${searchQuery}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				});
				if (response.ok) {
					const data = await response.json();
					setSearchResults(data.emailsData);
					setIsSearching(false);
				} else {
					if (response.status === 401 || response.status === 500) {
						try {
							const response = await fetch(backendUrl + `authtoken`, {
								method: 'GET',
								headers: {
									'Content-Type': 'application/json',
								},
								credentials: 'include',
							});
							if (response.ok) {
								setTimeout(() => {
									fetchQueriedEmails();
								}, 1000);
							} else if (response.status === 303) {
								const url = await response.json();
								window.location.href = url.url;
							} else {
								console.log('unable to refresh token.');
							}
						} catch (error) {
							console.log('test');
							console.log(error);
						}
					} else if (response.status === 500) {
						setFormErrors({ searchQuery: 'Unable to complete search, please check your excluded words.' });
						setIsSearching(false);
					} else {
						const errorData = await response.json();
						console.error(errorData.error); // Handle errors
						setIsSearching(false);
					}
				}
			} catch (error) {
				console.log('Error during signup: ' + error);
				setIsSearching(false);
			}
		} else {
			setIsSearching(false);
			setFormErrors({
				searchQuery: 'We are unable to search your inbox without consent. To use this feature enable it in settings.',
			});
		}
	};

	const handleFormChange = async (e) => {
		const { name, value } = e.target;
		if (name === 'stage') {
			// Handle the select element separately
			setFormData((prevState) => ({
				...prevState,
				stage: value,
			}));
		} else {
			// For other input fields
			setFormData((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleChange = (e) => {
		const value = e.target.value;

		setSearchQuery(value);
		setSelectedItemIndex(null);

		// Reset form errors and success
		setFormErrors({
			...formErrors,
			searchQuery: '',
		});
		//setFormSuccess('');
	};

	const handleMergeEmailToApplication = async (result, index) => {
		const body = {
			emailData: emailData,
			applicationId: applicationId,
			updatedApplicationData: formData,
		};
		try {
			const response = await fetch(backendUrl + `email/merge`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(body),
			});
			if (response.ok) {
				const data = await response.json();
				const updatedUserApplicationData = [...userApplicationData]; // Copy the original array

				// Find the index of the application in userApplicationData
				const applicationIndex = updatedUserApplicationData.findIndex(
					(application) => application._id === data.application._id
				);

				if (applicationIndex !== -1) {
					// If application found
					// Update the application by adding data.email to its email array
					updatedUserApplicationData[applicationIndex].emails.push(data.email);

					// Set the updated array back to state
					dispatch(setUserApplicationData(updatedUserApplicationData));
				} else {
					console.log('Application not found in userApplicationData');
				}
				setSearchResults([]);
				setShowMergeModal(false);
			} else {
				if (response.status === 401) {
					try {
						const response = await fetch(backendUrl + `authtoken`, {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
							},
							credentials: 'include',
						});
						if (response.ok) {
							setTimeout(() => {
								fetchQueriedEmails();
							}, 1000);
						} else if (response.status === 303) {
							const url = await response.json();
							window.location.href = url.url;
						} else {
							console.log('unable to refresh token.');
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					const errorData = await response.json();
					console.error(errorData.error); // Handle errors
				}
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
	};

	const handleAddApplication = async (result, index) => {
		// Create a copy of the isAddingList and isAddedList arrays
		const newIsAddingList = [...isAddingList];
		const newIsAddedList = [...isAddedList];

		// Set isAdding to true for the specific item
		newIsAddingList[index] = true;
		setIsAddingList(newIsAddingList);

		try {
			// Create applicationData and add application
			const applicationData = {
				emailId: result.emailId,
				threadId: result.threadId,
				subject: result.subject,
				bodySnippet: result.bodySnippet,
				sender: result.sender,
				rejected: false,
				jobTitle: result.jobTitle, // Add jobTitle from result
				employer: result.employer, // Add employer from result
				stage: result.stage, // Add stage from result
				notes: result.notes, // Add notes from result
				lastUpdated: result.timeReceived,
				timeReceived: result.timeReceived,
			};
			const applicationResponse = await fetch(backendUrl + `email`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify(applicationData),
			});

			if (applicationResponse.ok) {
				const newApplication = await applicationResponse.json();
				dispatch(setUserApplicationData([...userApplicationData, newApplication]));
				props.triggerForceUpdate();

				// Set isAdding to false and isAdded to true for the specific item
				newIsAddingList[index] = false;
				newIsAddedList[index] = false;

				setIsAddingList(newIsAddingList);
				setIsAddedList(newIsAddedList);
				setSearchResults([]);
				setSearchQuery('');
			} else {
				if (applicationResponse.status === 500) {
					setFormErrors({ searchQuery: 'Email already added.' });
				}
				// Set isAdding to false for the specific item if an error occurs
				newIsAddingList[index] = false;
				setIsAddingList(newIsAddingList);

				const errorData = await applicationResponse.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during adding application: ' + error);
			setIsSearching(false);
		}
	};

	const handleOutsideClick = (event) => {
		// Check if the clicked element is inside the modalRef (modal div)
		if (searchRef.current && !searchRef.current.contains(event.target)) {
			// Clicked outside the modal div, close the modal
			setSearchResults([]);
			setSearchQuery('');
			setFormErrors({ searchQuery: '' });
			setSelectedItemIndex(null);
			setIsAddedList(Array(searchResults.length).fill(false));
			setIsAddingList(Array(searchResults.length).fill(false));
		}
	};

	const handleShowDetails = (index) => {
		// Toggle details for the clicked item
		setSelectedItemIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const handleShowMergeModal = (result, index) => {
		setEmailData({});
		setTimeout(() => {
			setEmailData(result);
		}, 100);
		setShowMergeModal((prevState) => !prevState);
	};

	const trimBody = (content) => {
		const contentLength = 200;
		if (content.length > contentLength) {
			// Find the last space within the specified length
			const lastSpaceIndex = content.lastIndexOf(' ', contentLength);

			// If a space is found, trim at that position; otherwise, trim at the specified length
			const trimmedContent = lastSpaceIndex !== -1 ? content.slice(0, lastSpaceIndex) : content.slice(0, contentLength);

			const bodyWithApostrophe = trimmedContent.replace(/&#39;|'/g, "'");

			return bodyWithApostrophe + '...';
		}
		const bodyWithApostrophe = content.replace(/&#39;|'/g, "'");
		return bodyWithApostrophe;
	};

	const applySearchAndFilter = () => {
		let filteredApplications = subsetData;

		if (localSearchApplications) {
			filteredApplications = filteredApplications.filter((application) => {
				const searchMatches = Object.values(application).some(
					(value) => value && value.toString().toLowerCase().includes(localSearchApplications.toLowerCase())
				);
				return searchMatches;
			});
		}

		setAppData(filteredApplications);
	};

	const handleSearchApplications = (search) => {
		setLocalSearchApplications(search);
	};

	const handleClickApplication = (data) => {
		setApplicationId(data._id);
		setFormData({
			jobTitle: '',
			employer: '',
			stage: '',
			notes: '',
		});
		setTimeout(() => {
			setFormData({
				jobTitle: data.jobTitle,
				employer: data.employer,
				stage: data.stage,
				notes: data.notes,
			});
		}, 50);
		setSelectedAppId(data._id);
	};

	useEffect(() => {
		applySearchAndFilter();
		// Attach the click event listener when the component mounts
		document.addEventListener('click', handleOutsideClick);

		// Cleanup the event listener when the component unmounts
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localSearchApplications, subsetData, isLoggedIn, isSubscribed]);

	const modalVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
		exit: { opacity: 0 },
	};

	const [isDemo, setIsDemo] = useState(false);
	const currentPathname = window.location.pathname;

	const checkDemoPage = () => {
		if (currentPathname === '/demo') {
			setIsDemo(true);
		}
	};

	useEffect(() => {
		checkDemoPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPathname]);

	return (
		<div>
			<AnimatePresence>
				{showMergeModal && (
					<Modal
						allowClose={true}
						setShow={() => setShowMergeModal(false)}
					>
						<h3 className='self-start mb-0 px-8'>Application Details</h3>
						<p className='px-8'>
							Select the application you wish to merge this email into and update the application details.
						</p>
						<div
							id='actions'
							className='px-8 xs:!px-1'
						>
							<div className='filter'>
								<input
									type='text'
									name='Search'
									placeholder='Search applications'
									className='mb-2 mt-6'
									onChange={(e) => handleSearchApplications(e.target.value)}
								></input>
							</div>
						</div>
						<div className='h-[250px] overflow-y-scroll mx-8 xs:mx-1 xs:!p-0'>
							<div className='application-header limited'>
								<span className='col-span-2'>Employer</span>
								<span className='col-span-3'>Job Title</span>
								<span className='col-span-2 flex justify-center'>Stage</span>
								<span className='col-span-2 flex justify-center'>Last Updated</span>
							</div>
							{appData.map((application, index) => (
								<ApplicationLimited
									key={application._id}
									data={application}
									handleClickApplication={handleClickApplication}
									selectedAppId={selectedAppId}
								/>
							))}
						</div>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								setShowMergeModal(false);
								handleMergeEmailToApplication();
							}}
							className='form p-8'
						>
							<div className='form-inner_container'>
								<label>Employer:</label>
								<input
									type='text'
									name='employer'
									className='form-input'
									placeholder='Employer'
									onChange={(e) => handleFormChange(e)}
									value={formData.employer}
								></input>
							</div>
							<div className='form-inner_container !mt-0'>
								<label>Job Title:</label>
								<input
									type='text'
									name='jobTitle'
									className='form-input'
									placeholder='Job Title'
									onChange={(e) => handleFormChange(e)}
									value={formData.jobTitle}
								></input>
							</div>
							<div className='form-inner_container'>
								<label>Stage:</label>
								<select
									className='w-[68%]'
									onChange={(e) => handleFormChange(e)}
									name='stage'
									value={formData.stage}
								>
									<option value='submitted'>Submitted</option>
									<option value='follow up'>Follow Up</option>
									<option value='waiting on response'>Waiting on Response</option>
									<option value='interview'>Interview</option>
									<option value='assessment'>Assessment</option>
									<option value='offer'>Offer Received</option>
									<option value='withdraw'>Withdraw</option>
									<option value='rejected'>Rejected</option>
									<option value='accepted'>Accepted</option>
								</select>
							</div>
							<div className='form-inner_container-column'>
								<textarea
									name='notes'
									className='form-textarea'
									placeholder='Notes'
									value={formData.notes}
									onChange={(e) => handleFormChange(e)}
								></textarea>
							</div>
							<div className='modal-button_container'>
								<button
									type='button'
									className='cancel'
									onClick={() => {
										setShowMergeModal(false);
									}}
								>
									Cancel
								</button>
								<button type='submit'>Merge</button>
							</div>
						</form>
					</Modal>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{searchResults.length > 0 && (
					<motion.div
						key={1}
						initial='hidden'
						animate='visible'
						exit='exit'
						variants={modalVariants}
						transition={{ duration: 0.2, ease: 'easeInOut' }}
						className='search-overlay'
					>
						<div
							ref={searchRef}
							id='returned-emails'
						>
							{searchResults.map((result, index) => (
								<div
									className='returned-email'
									key={index}
								>
									<div className='email-title_container'>
										<h5>
											<i
												className='fa-solid fa-angle-right p-2 cursor-pointer'
												aria-hidden='true'
												onClick={() => handleShowDetails(index)}
											></i>
											{result.subject}
											<span>({result.timeReceived.split('T')[0].replace(/-/g, '/')})</span>
										</h5>
										<div className='email-action_container'>
											<button
												title='Add New Application'
												className={`secondary-button inactive ${isAddingList[index] ? 'disabled' : ''}`}
												onClick={() => handleAddApplication(result, index)}
												disabled={isAddingList[index] || isAddedList[index]}
											>
												{isAddingList[index] ? (
													<i
														className='fa-solid fa-rotate icon-rotate'
														aria-hidden='true'
													></i>
												) : isAddedList[index] ? (
													<i
														className='fa-solid fa-check'
														aria-hidden='true'
													></i>
												) : (
													<i
														className='fa-solid fa-plus'
														aria-hidden='true'
													></i>
												)}
											</button>
											<button
												title='Merge Email With Existing Application'
												className={`secondary-button inactive`}
												onClick={() => handleShowMergeModal(result, index)}
											>
												<i
													className='fa-solid fa-code-merge'
													aria-hidden='true'
												></i>
											</button>
										</div>
									</div>
									<div className={`email-details_container ${selectedItemIndex === index ? '' : 'hidden'}`}>
										<span>
											<strong>Sender: </strong>
											{result.sender}
										</span>
										<p>{trimBody(result.bodySnippet)}</p>
									</div>
								</div>
							))}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
			{isLoggedIn && (
				<div>
					<form
						disabled={isDemo || (userData?.settings && !userData.settings.autoImport)}
						id='email-search'
						onSubmit={(e) => {
							e.preventDefault();
							fetchQueriedEmails();
						}}
					>
						<input
							disabled={isDemo || (userData?.settings && !userData.settings.autoImport)}
							type='search'
							autoComplete='off'
							name='email-search'
							placeholder='Search inbox'
							value={searchQuery}
							className={`${
								(userData?.settings && userData.settings.autoImport) || isDemo ? '' : 'disabled'
							} form-input email-search`}
							onChange={(e) => handleChange(e)}
						/>
						<button
							disabled={isDemo || (userData?.settings && !userData.settings.autoImport)}
							type='submit'
							className={(userData?.settings && userData.settings.autoImport) || isDemo ? '' : 'disabled'}
						>
							{!isSearching ? (
								'Search'
							) : (
								<i
									className='fa-solid fa-rotate rotate-icon px-4'
									aria-hidden='true'
								></i>
							)}
						</button>
						{formErrors.searchQuery && <span className='field-error font-bold text-xs'>{formErrors.searchQuery}</span>}
					</form>
				</div>
			)}
		</div>
	);
};

export default InboxSearch;
