import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from '../pages/Home';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import TermsAndConditions from '../pages/Terms';
import Cookies from '../pages/Cookies';
import PrivacyPolicy from '../pages/Privacy';
import Feedback from '../pages/Feedback';
import Premium from '../pages/Premium';
import PaymentPage from '../pages/PaymentPage';
import PasswordReset from '../pages/PasswordReset';
import NewPassword from '../pages/NewPassword';
import SignUp from '../pages/SignUp';
import Demo from '../pages/Demo';

const AppRouter = () => {
	return (
		<Router>
			<Routes>
				<Route
					path='/'
					element={<Home />}
				/>
				<Route
					path='/login'
					element={<Login />}
				/>
				<Route
					path='/signup'
					element={<SignUp />}
				/>
				<Route
					path='/dashboard'
					element={<Dashboard />}
				/>
				<Route
					path='/demo'
					element={<Demo />}
				/>
				<Route
					path='/terms'
					element={<TermsAndConditions />}
				/>
				<Route
					path='/cookies'
					element={<Cookies />}
				/>
				<Route
					path='/privacy'
					element={<PrivacyPolicy />}
				/>
				<Route
					path='/feedback'
					element={<Feedback />}
				/>
				<Route
					path='/premium'
					element={<Premium />}
				/>
				<Route
					path='/subscribe'
					element={<PaymentPage />}
				/>
				<Route
					path='/reset-password'
					element={<PasswordReset />}
				/>
				<Route
					path='/new-password'
					element={<NewPassword />}
				/>
			</Routes>
		</Router>
	);
};

export default AppRouter;
