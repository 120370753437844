import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';

import AuthProvider from './components/AuthProvider';
import AppRouter from './components/AppRouter';

const App = () => {
	return (
		<Provider store={store}>
			<AuthProvider>
				<AppRouter />
			</AuthProvider>
		</Provider>
	);
};

export default App;
