import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoggedIn } from '../../../redux/loggedInReducer';

import { sanitizeStorage } from '../../../utils/sanitizeInputs';

import LoadingSpinner from '../LoadingSpinner';

import '../../../styles/UIElements/Forms.css';

const LoginForm = (props) => {
	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
	});
	const [formErrors, setFormErrors] = useState({});
	const [generalError, setGeneralError] = useState('');
	const [formSuccess, setFormSuccess] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [submissionStartTime, setSubmissionStartTime] = useState(0);
	const [dbResponseText, setDbResponseText] = useState(props.loadText);

	const handleOAuthLogin = async (provider) => {
		setSubmissionStartTime(performance.now());
		setIsLoading(true);
		window.location.href = `${backendUrl}auth/${provider}`;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});

		setFormErrors({
			...formErrors,
			[name]: '',
		});

		setFormSuccess('');
	};

	const handleSubmitLogin = async (e) => {
		setSubmissionStartTime(performance.now());
		setIsLoading(true);

		const errors = {};
		if (!formData.email) {
			errors.email = 'Please enter your email.';
		}
		if (!formData.password) {
			errors.password = 'Please enter your password.';
		}
		if (Object.keys(errors).length > 0) {
			// Set formErrors state to display errors beneath each field
			setFormErrors(errors);
			setIsLoading(false);
			return;
		}

		try {
			const response = await fetch(backendUrl + 'auth/login', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});
			if (response.ok) {
				setFormData({ email: '', password: '' });
				setGeneralError('');
				const data = await response.json();

				const sanitizedToken = sanitizeStorage(data.token);

				// Set cookies for authToken and expires
				document.cookie = `authToken=${sanitizedToken}; expires=${new Date(
					data.expiry
				).toUTCString()}; path=/; domain=.jobwrangler.io; Secure; SameSite=Strict;`;

				dispatch(setLoggedIn(true));
				setTimeout(() => {
					navigate('/dashboard');
					setIsLoading(false);
				}, 1000);
			} else {
				const errorData = await response.json();
				setIsLoading(false);
				setGeneralError(errorData.error);
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during login: ' + error);
		}
	};

	const handleSubmitSignup = async () => {
		setSubmissionStartTime(performance.now());
		setIsLoading(true);

		const errors = {};
		if (!formData.name) {
			errors.name = 'Please enter your name.';
		}
		if (!formData.email) {
			errors.email = 'Please enter your email.';
		}

		// Regular expression for a strong password
		const passwordMatch = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/;
		if (!formData.password) {
			errors.password = 'Please enter a password.';
		} else if (!passwordMatch.test(formData.password.trim())) {
			errors.password =
				'Invalid password. Your chosen password must have at least 8 characters and include letters, numbers, and at least one of the following special characters: @$!%*#?&-';
		}
		if (Object.keys(errors).length > 0) {
			// Set formErrors state to display errors beneath each field
			setFormErrors(errors);
			setIsLoading(false);
			return;
		}

		try {
			const response = await fetch(backendUrl + 'auth/signup', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});
			if (response.ok) {
				setFormData({ name: '', email: '', password: '' });
				setGeneralError('');
				const data = await response.json();

				const sanitizedToken = sanitizeStorage(data.token);

				// Set cookies for authToken and expires
				document.cookie = `authToken=${sanitizedToken}; expires=${new Date(
					data.expiry
				).toUTCString()}; path=/; domain=.jobwrangler.io; Secure; SameSite=Strict;`;

				dispatch(setLoggedIn(true));
				setTimeout(() => {
					navigate('/dashboard');
					setIsLoading(false);
				}, 1000);
			} else {
				const errorData = await response.json();
				setIsLoading(false);
				setGeneralError(errorData.error);
				console.error(errorData.error);
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
	};

	const resetStorage = () => {
		localStorage.clear();
		sessionStorage.clear();
	};

	useEffect(() => {
		const checkElapsedTime = () => {
			const elapsedTime = performance.now() - submissionStartTime;

			if (elapsedTime > 3000) {
				setDbResponseText('Please wait, this is taking a little longer than expected...');
			}
		};

		if (submissionStartTime > 0) {
			const intervalId = setInterval(checkElapsedTime, 1000);
			return () => clearInterval(intervalId);
		}

		return undefined;
	}, [submissionStartTime]);

	return (
		<React.Fragment>
			<div className='flex items-center'>
				<div className=''>
					<button
						class='gsi-material-button'
						onClick={() => {
							resetStorage();
							handleOAuthLogin('google');
						}}
					>
						<div class='gsi-material-button-state'></div>
						<div class='gsi-material-button-content-wrapper'>
							<div class='gsi-material-button-icon'>
								<svg
									version='1.1'
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 48 48'
									style={{ display: 'block' }}
								>
									<path
										fill='#EA4335'
										d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'
									></path>
									<path
										fill='#4285F4'
										d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'
									></path>
									<path
										fill='#FBBC05'
										d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'
									></path>
									<path
										fill='#34A853'
										d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'
									></path>
									<path
										fill='none'
										d='M0 0h48v48H0z'
									></path>
								</svg>
							</div>
							<span class='gsi-material-button-contents'>
								{props.isSignup ? 'Sign up with Google' : 'Sign in with Google'}
							</span>
							<span style={{ display: 'none' }}>{props.isSignup ? 'Sign up with Google' : 'Sign in with Google'}</span>
						</div>
					</button>
				</div>
				{/*<div className=''>
					<button
						disabled={true}
						title='Microsoft auth coming soon..'
						className='flex items-center group bg-slate-500 border-2 border-slate-500  text-zinc-50 font-bold py-2 px-4 rounded-md opacity-70'
					>
						{props.isSignup ? 'Sign up' : 'Login'} with{' '}
						<i className='fa-brands fa-microsoft text-white text-2xl ml-2'></i>
					</button>
	</div>*/}
			</div>
			<span className='text-zinc-50 py-4'>-or-</span>
			{formSuccess && <div className='success'>{formSuccess}</div>}
			{generalError && <span className='form-error py-2 px-5 mt-2 bg-red-600 font-medium'>{generalError}</span>}
			{!formSuccess && (
				<form
					onSubmit={(e) => {
						e.preventDefault();
						if (props.isSignup) {
							resetStorage();
							handleSubmitSignup();
						} else {
							resetStorage();
							handleSubmitLogin();
						}
					}}
					className='flex flex-col items-start max-w-md xl:w-1/4 2xl:w-1/5 mx-auto md:w-1/2 sm:w-11/12 xs:items-center'
				>
					{isLoading && (
						<LoadingSpinner
							asOverlay
							loadText={dbResponseText}
						/>
					)}
					{props.isSignup && (
						<input
							type='text'
							name='name'
							placeholder='Name'
							className='form-input text-black w-full px-4 py-3 mt-2 rounded-md focus:border-transparent focus:ring focus:ring-emerald-700'
							value={formData.name}
							onChange={handleChange}
						/>
					)}
					{formErrors.name && <div className='field-error font-medium'>{formErrors.name}</div>}
					<input
						type='email'
						name='email'
						placeholder='Email'
						className='form-input text-black w-full px-4 py-3 mt-2 rounded-md focus:border-transparent focus:ring focus:ring-emerald-700'
						value={formData.email}
						onChange={handleChange}
					/>
					{formErrors.email && <div className='field-error font-medium'>{formErrors.email}</div>}
					<input
						type='password'
						name='password'
						placeholder='Password'
						className='form-input text-black w-full px-4 py-3 mt-2 rounded-md focus:border-transparent focus:ring focus:ring-emerald-700'
						value={formData.password}
						onChange={handleChange}
					/>
					{formErrors.password && <div className='field-error font-medium'>{formErrors.password}</div>}
					<button
						type='submit'
						className='bg-sky-700 border-2 border-sky-700 text-zinc-50 font-bold w-1/3 py-3 px-4 mt-4 rounded-md focus:border-transparent focus:ring focus:ring-white hover:bg-white hover:text-sky-700 hover:border-sky-700 transition-all md:w-full'
					>
						{props.isSignup ? 'Sign up' : 'Sign in'}
					</button>
				</form>
			)}
		</React.Fragment>
	);
};

export default LoginForm;
