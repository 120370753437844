import React, { useRef, useEffect } from 'react';

import '../../styles/UIElements/Modals.css';
import { motion } from 'framer-motion';

const Modal = ({ children, onClose, allowClose, setShow }) => {
	const modalRef = useRef(null);
	const justOpenedRef = useRef(false);

	const handleModalClick = (event) => {
		if (justOpenedRef.current) {
			justOpenedRef.current = false;
			return;
		}

		// Check if the clicked element is inside the modalRef (modal div)
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			// Clicked outside the modal div, close the modal
			setShow(false);
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	useEffect(() => {
		// Attach the click event listener when the component mounts
		document.addEventListener('click', handleModalClick);

		// Cleanup the event listener when the component unmounts
		return () => {
			document.removeEventListener('click', handleModalClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Set the justOpened flag when the modal opens
		if (allowClose) {
			justOpenedRef.current = true;
		}
	}, [allowClose]);

	const modalVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
		exit: { opacity: 0 },
	};

	return (
		<motion.div
			key={1}
			className='modal-overlay'
			initial='hidden'
			animate='visible'
			exit='exit'
			variants={modalVariants}
			transition={{ duration: 0.2, ease: 'easeInOut' }}
		>
			<div
				ref={allowClose ? modalRef : null}
				className={`modal`}
				onClick={(e) => e.stopPropagation()}
			>
				{allowClose && (
					<button
						className='modal-close'
						onClick={() => handleClose()}
					>
						&times;
					</button>
				)}
				{children}
			</div>
		</motion.div>
	);
};

export default Modal;
