import React from 'react';

import '../../../styles/UIElements/Banner.css';

const Banner = () => {
	return (
		<React.Fragment>
			<section className='banner'>
				<div className='inner-container'>
					<h3 className='text-center'>Ready to simplify your search?</h3>
					<div className='mt-8'>
						<a
							href='/signup'
							className='button-link secondary'
						>
							Sign Up
						</a>
						<a
							className='button-link'
							href='/premium'
						>
							Get Premium <i className='fa-solid fa-crown text-yellow-400'></i>
						</a>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default Banner;
