import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import { setTaskNumber } from '../../../redux/taskDataReducer';

import Task from './Task';

import '../../../styles/UIElements/TaskPanel.css';

const TaskPanel = ({ emailsIsOpen, triggerForceUpdate }) => {
	const dispatch = useDispatch();

	const userApplicationData = useSelector((state) => state.user.userApplicationData);
	const followUpData = useSelector((state) => state.taskData.followUps);
	const taskCount = useSelector((state) => state.taskData.showTaskNumber);

	const [filteredInterviewData, setFilteredInterviewData] = useState([]);
	const [filteredAssessmentsData, setFilteredAssessmentsData] = useState([]);
	const [filteredFollowUpData, setFilteredFollowUpData] = useState([]);

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const removeTask = async (id) => {
		try {
			const updatedApplication = await fetch(backendUrl + `task/${id}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({ emailSent: true, status: 'read' }),
			});
			if (updatedApplication.ok) {
				setFilteredFollowUpData((prevData) => prevData.filter((task) => task._id !== id));
				const calculateCount = taskCount - 1;
				dispatch(setTaskNumber(calculateCount));
			}
		} catch (error) {
			console.log(error);
		}
	};

	const completeTask = async (id, applicationId) => {
		try {
			const updatedTask = await fetch(backendUrl + `task/${id}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({ emailSent: true, status: 'read' }),
			});
			if (updatedTask.ok) {
				triggerForceUpdate();
				setFilteredFollowUpData((prevData) => prevData.filter((task) => task._id !== id));
				const calculateCount = taskCount - 1;
				dispatch(setTaskNumber(calculateCount));
			}
		} catch (error) {
			console.log(error);
		}
		try {
			// eslint-disable-next-line no-unused-vars
			const updatedApplication = await fetch(backendUrl + `application/${applicationId}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({ followUp: true, stage: 'waiting on response', followUpEmail: true }),
			});
		} catch (error) {
			console.log(error);
		}
	};

	const dashboardVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	useEffect(() => {
		const filteredInterviews = followUpData.filter((task) => task.stage === 'interview' && task.status === 'unread');
		const filteredAssessments = followUpData.filter((task) => task.stage === 'assessment' && task.status === 'unread');
		const filteredFollowUps = followUpData.filter((task) => task.stage === 'follow up' && task.status === 'unread');

		setFilteredFollowUpData(filteredFollowUps);
		setFilteredInterviewData(filteredInterviews);
		setFilteredAssessmentsData(filteredAssessments);
	}, [followUpData]);

	return (
		<motion.div
			initial='hidden'
			animate='visible'
			variants={dashboardVariants}
			className={`px-8 pl-4 task-panel`}
		>
			<h2>Tasks</h2>
			{filteredInterviewData?.length < 1 && filteredAssessmentsData?.length < 1 && filteredFollowUpData?.length < 1 && (
				<span>It doesn't look like you have any tasks today.</span>
			)}
			{filteredInterviewData?.length > 0 && (
				<div className='task-list mb-12'>
					<h5 className={!emailsIsOpen ? 'col-span-12' : 'col-span-12'}>
						{filteredInterviewData.length > 1
							? 'You recently interviewed for these roles, have you followed up with them?'
							: 'You recently interviewed for this role, have you followed up on it?'}
					</h5>
					<AnimatePresence>
						{userApplicationData &&
							filteredInterviewData.map((task) => (
								<TaskMemo
									key={task._id}
									task={task}
									removeTask={() => removeTask(task._id)}
									completeTask={() => completeTask(task._id, task.application)}
								/>
							))}
					</AnimatePresence>
				</div>
			)}
			{filteredAssessmentsData?.length > 0 && (
				<div className='task-list mb-12'>
					<h5 className={!emailsIsOpen ? 'col-span-12' : 'col-span-12'}>
						{filteredAssessmentsData.length > 1
							? 'You recently did assessments for these roles, have you followed up on them?'
							: 'You recently did an assessment for this role, have you followed up on it?'}
					</h5>
					<AnimatePresence>
						{userApplicationData &&
							filteredAssessmentsData.map((task) => (
								<TaskMemo
									key={task._id}
									task={task}
									removeTask={() => removeTask(task._id)}
									completeTask={() => completeTask(task._id, task.application)}
								/>
							))}
					</AnimatePresence>
				</div>
			)}
			{filteredFollowUpData?.length > 0 && (
				<div className='task-list'>
					<h5 className={!emailsIsOpen ? 'col-span-12' : 'col-span-12'}>
						{filteredFollowUpData.length > 1
							? 'Have you followed up on these roles?'
							: 'Have you followed up on this role?'}
					</h5>
					<AnimatePresence>
						{userApplicationData &&
							filteredFollowUpData.map((task) => (
								<TaskMemo
									key={task._id}
									task={task}
									removeTask={() => removeTask(task._id)}
									completeTask={() => completeTask(task._id, task.application)}
								/>
							))}
					</AnimatePresence>
				</div>
			)}
		</motion.div>
	);
};

const TaskMemo = React.memo(Task);

export default TaskPanel;
