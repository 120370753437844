import React, { useState } from 'react';

import '../../../styles/UIElements/Forms.css';
import { AnimatePresence, motion } from 'framer-motion';

const FeedbackForm = (props) => {
	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const [formData, setFormData] = useState({
		email: '',
		feedback: '',
	});
	const [formErrors, setFormErrors] = useState({});
	const [generalError, setGeneralError] = useState('');
	const [formSuccess, setFormSuccess] = useState('');

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});

		setFormErrors({
			...formErrors,
			[name]: '',
		});

		setFormSuccess('');
	};

	const handleSubmit = async () => {
		const errors = {};
		if (!formData.email) {
			errors.email = 'Please enter your email.';
		}
		if (!formData.feedback) {
			errors.feedback = 'Please enter your feedback.';
		}
		if (Object.keys(errors).length > 0) {
			// Set formErrors state to display errors beneath each field
			setFormErrors(errors);
			return;
		}

		try {
			const body = {
				reply_to: formData.email,
				subject: 'Feedback Form Submission',
				text: formData.feedback,
			};
			const response = await fetch(backendUrl + 'send-email/feedback', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
			});
			if (response.ok) {
				setFormData({ feedback: '', email: '' });
				setGeneralError('');
				setFormSuccess('Thank you for submitting your feedback, we will review it as soon as possible.');
			} else {
				const errorData = await response.json();
				setGeneralError(errorData.error);
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
	};

	const notificationVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
		exit: { opacity: 0 },
	};

	return (
		<React.Fragment>
			<AnimatePresence>
				{formSuccess && (
					<motion.div
						key={'success'}
						initial='hidden'
						animate='visible'
						exit='exit'
						variants={notificationVariants}
						transition={{ opacity: { duration: 0.2, ease: 'easeInOut' } }}
						className='success'
					>
						{formSuccess}
					</motion.div>
				)}
				{generalError && (
					<motion.span
						key={'formError'}
						initial='hidden'
						animate='visible'
						exit='exit'
						variants={notificationVariants}
						transition={{ opacity: { duration: 0.2, ease: 'easeInOut' } }}
						className='form-error py-2 px-5 mt-2 bg-red-600 font-medium'
					>
						{generalError}
					</motion.span>
				)}
			</AnimatePresence>
			{!formSuccess && (
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
					className='flex flex-col items-center w-[80%]'
				>
					<input
						type='email'
						name='email'
						placeholder='Email'
						className='form-input text-black w-full px-4 py-3 mt-2 rounded-md focus:border-transparent focus:ring focus:ring-emerald-700'
						value={formData.email}
						onChange={handleChange}
					/>
					<AnimatePresence>
						{formErrors.email && (
							<motion.div
								key={'emailError'}
								initial='hidden'
								animate='visible'
								exit='exit'
								variants={notificationVariants}
								transition={{ opacity: { duration: 0.2, ease: 'easeInOut' } }}
								className='field-error font-medium self-start'
							>
								{formErrors.email}
							</motion.div>
						)}
					</AnimatePresence>
					<textarea
						type='textarea'
						name='feedback'
						placeholder='Enter your feedback'
						className='form-input text-black w-full px-4 py-3 mt-2 rounded-md focus:border-transparent focus:ring focus:ring-emerald-700'
						value={formData.feedback}
						onChange={handleChange}
					></textarea>
					<AnimatePresence>
						{formErrors.feedback && (
							<motion.div
								key={'feedbackError'}
								initial='hidden'
								animate='visible'
								exit='exit'
								variants={notificationVariants}
								transition={{ opacity: { duration: 0.2, ease: 'easeInOut' } }}
								className='field-error font-medium self-start'
							>
								{formErrors.feedback}
							</motion.div>
						)}
					</AnimatePresence>
					<button
						type='submit'
						className='primary'
					>
						Submit
					</button>
				</form>
			)}
		</React.Fragment>
	);
};

export default FeedbackForm;
