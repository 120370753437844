// Action types
export const SET_LOGGED_IN = 'SET_LOGGED_IN';

// Action creators
export const setLoggedIn = (value) => ({
	type: SET_LOGGED_IN,
	payload: value,
});

// Initial state
const initialState = {
	loggedIn: false,
};

// Reducer
const loggedInReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOGGED_IN:
			return {
				...state,
				loggedIn: action.payload,
			};
		default:
			return state;
	}
};

export default loggedInReducer;
