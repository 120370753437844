// Action types
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER_TERMS = 'SET_USER_TERMS';
export const SET_USER_AUTOIMPORT = 'SET_USER_AUTOIMPORT';
export const SET_USER_LASTIMPORT = 'SET_USER_LASTIMPORT';
export const SET_USER_STARTDATE = 'SET_USER_STARTDATE';
export const SET_USER_APPLICATION_DATA = 'SET_USER_APPLICATION_DATA';
export const SET_USER_SORTED_APPLICATION_DATA = 'SET_USER_SORTED_APPLICATION_DATA';
export const SET_USER_EMAIL_DATA = 'SET_USER_EMAIL_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_SUBSCRIPTION = 'SET_USER_SUBSCRIPTION';
export const SET_USER_NAV_OPEN = 'SET_USER_NAV_OPEN';
export const SET_USER_DISMISSED_CHANGES = 'SET_USER_DATA_DISMISSED_CHANGES';

// Action creators
export const setUserToken = (userToken) => ({
	type: SET_USER_TOKEN,
	payload: userToken,
});
export const setUserTerms = (userTerms) => ({
	type: SET_USER_TERMS,
	payload: userTerms,
});
export const setUserAutoImport = (userAutoImport) => ({
	type: SET_USER_AUTOIMPORT,
	payload: userAutoImport,
});
export const setUserLastImport = (userLastImport) => ({
	type: SET_USER_LASTIMPORT,
	payload: userLastImport,
});
export const setUserStartDate = (userStartDate) => ({
	type: SET_USER_STARTDATE,
	payload: userStartDate,
});
export const setUserApplicationData = (userApplicationData) => ({
	type: SET_USER_APPLICATION_DATA,
	payload: userApplicationData,
});
export const setUserSortedApplicationData = (userSortedApplicationData) => ({
	type: SET_USER_SORTED_APPLICATION_DATA,
	payload: userSortedApplicationData,
});
export const setUserEmailData = (userEmailData) => ({
	type: SET_USER_EMAIL_DATA,
	payload: userEmailData,
});
export const setUserData = (userData) => ({
	type: SET_USER_DATA,
	payload: userData,
});
export const setUserSubscription = (userSubscription) => ({
	type: SET_USER_SUBSCRIPTION,
	payload: userSubscription,
});
export const setNavOpen = (navOpen) => ({
	type: SET_USER_NAV_OPEN,
	payload: navOpen,
});

// Initial state
const initialState = {
	userToken: null,
	userTerms: false,
	userAutoImport: false,
	userLastImport: '',
	userStartDate: null,
	userApplicationData: [],
	userSortedApplicationData: [],
	userEmailData: {},
	userData: {},
	userSubscription: false,
	navOpen: false,
};

// Reducer
const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_TOKEN:
			return {
				...state,
				userToken: action.payload,
			};
		case SET_USER_TERMS:
			return {
				...state,
				userTerms: action.payload,
			};
		case SET_USER_AUTOIMPORT:
			return {
				...state,
				userAutoImport: action.payload,
			};
		case SET_USER_LASTIMPORT:
			return {
				...state,
				userLastImport: action.payload,
			};
		case SET_USER_STARTDATE:
			return {
				...state,
				userStartDate: action.payload,
			};
		case SET_USER_APPLICATION_DATA:
			return {
				...state,
				userApplicationData: action.payload,
			};
		case SET_USER_SORTED_APPLICATION_DATA:
			return {
				...state,
				userSortedApplicationData: action.payload,
			};
		case SET_USER_EMAIL_DATA:
			return {
				...state,
				userEmailData: action.payload,
			};
		case SET_USER_DATA:
			return {
				...state,
				userData: action.payload,
			};
		case SET_USER_SUBSCRIPTION:
			return {
				...state,
				userSubscription: action.payload,
			};
		case SET_USER_NAV_OPEN:
			return {
				...state,
				navOpen: action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
