import React, { useState } from 'react';
import { motion } from 'framer-motion';

import '../../../styles/UIElements/TaskPanel.css';

const Task = ({ task, removeTask, completeTask }) => {
	const [trimmedTitle] = useState(trimTitle(task.jobTitle));
	const [trimmedEmployer] = useState(trimTitle(task.employer));

	const dashboardVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	function trimTitle(title) {
		const contentLength = 50;
		if (title && title.length > contentLength) {
			const lastSpaceIndex = title.lastIndexOf(' ', contentLength);
			const trimmedContent = lastSpaceIndex !== -1 ? title.slice(0, lastSpaceIndex) : title.slice(0, contentLength);
			return trimmedContent.replace(/&#39;|'/g, "'") + '..';
		}
		return title ? title.replace(/&#39;|'/g, "'") : ''; // Return empty string if title is null or undefined
	}

	return (
		<motion.div
			initial='hidden'
			animate='visible'
			exit='hidden'
			variants={dashboardVariants}
			transition={{}}
			className={`task`}
		>
			<span>
				<span>
					<button
						onClick={() => {
							completeTask();
						}}
						className='task-complete'
					>
						<i className='fa-solid fa-check'></i>
					</button>
					<button
						onClick={() => {
							removeTask();
						}}
						className='task-remove'
					>
						<i className='fa-solid fa-xmark'></i>
					</button>
				</span>
				<span>
					<span>
						<strong>{trimmedEmployer}</strong>
					</span>{' '}
					<span>{trimmedTitle}</span>
				</span>
			</span>
		</motion.div>
	);
};

export default Task;
