import React from 'react';

import Navigation from '../components/UIElements/Navigation';
import Footer from '../components/UIElements/Footer';
import NewPasswordForm from '../components/UIElements/Forms/NewPasswordForm';
import MobileNav from '../components/UIElements/Landing/MobileNav';

//import '../styles/Login.css';

const Feedback = () => {
	return (
		<React.Fragment>
			<Navigation />
			<MobileNav />
			<section className='relative pt-16 pb-8 bg-section dark:bg-zinc-950 bg-zinc-100 bg-cover bg-center dark:text-white text-zinc-900'>
				<div className='inset-0 p-4 pt-8 flex flex-col items-center justify-center max-w-[70%] m-auto'>
					<h1 className=' mb-8'>Reset your Password</h1>
					<NewPasswordForm />
				</div>
			</section>
			<Footer isFixed />
		</React.Fragment>
	);
};

export default Feedback;
