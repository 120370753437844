const STORAGE_KEYS = {
	AUTH_STATUS: 'authStatus',
	SUBSCRIPTION: 'subscription',
};

const setAuthStatus = (authStatus) => {
	sessionStorage.setItem(STORAGE_KEYS.AUTH_STATUS, JSON.stringify(authStatus));
};

const setSubscriptionStatus = (subscriptionStatus) => {
	sessionStorage.setItem(STORAGE_KEYS.SUBSCRIPTION, subscriptionStatus);
};

const getAuthStatus = () => {
	const authStatus = sessionStorage.getItem(STORAGE_KEYS.AUTH_STATUS);
	return authStatus ? JSON.parse(authStatus) : null;
};

const getSubscriptionStatus = () => {
	return sessionStorage.getItem(STORAGE_KEYS.SUBSCRIPTION);
};

const removeAuthStatus = () => {
	sessionStorage.removeItem(STORAGE_KEYS.AUTH_STATUS);
};

const removeSubscriptionStatus = () => {
	sessionStorage.removeItem(STORAGE_KEYS.SUBSCRIPTION);
};

export {
	setAuthStatus,
	getAuthStatus,
	removeAuthStatus,
	setSubscriptionStatus,
	getSubscriptionStatus,
	removeSubscriptionStatus,
};
