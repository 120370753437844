import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const NewPasswordForm = () => {
	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}
	const location = useLocation(); // useLocation hook to access location object
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search); // Access query parameters

	const token = searchParams.get('token');

	const [formData, setFormData] = useState({ password: '', confirmPassword: '' });
	const [formErrors, setFormErrors] = useState({});
	const [generalError, setGeneralError] = useState('');
	const [formSuccess, setFormSuccess] = useState('');

	// Password validation logic imported from LoginForm
	const passwordMatchRegex = new RegExp('^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&-])[A-Za-z\\d@$!%*#?&-]{8,}$');

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		setFormErrors({ ...formErrors, [name]: '' });
		setFormSuccess('');
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const errors = {};
		if (!formData.password) {
			errors.password = 'Please enter your new password.';
		} else if (!passwordMatchRegex.test(formData.password.trim())) {
			errors.password =
				'Invalid password. Your chosen password must have at least 8 characters and include letters, numbers, and at least one of the following special characters: @$!%*#?&-';
		}
		if (!formData.confirmPassword) {
			errors.confirmPassword = 'Please confirm your new password.';
		}
		if (formData.password !== formData.confirmPassword) {
			errors.confirmPassword = 'Passwords do not match.';
		}
		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			return;
		}

		try {
			const response = await fetch(`${backendUrl}auth/change-password`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					password: formData.password,
					token: token,
				}),
			});
			if (response.ok) {
				setFormData({ password: '', confirmPassword: '' });
				setGeneralError('');
				setFormSuccess('Your password has been updated. Please log in again.');
				setTimeout(() => {
					navigate('/login');
				}, 1000);
			} else {
				const errorData = await response.json();
				setGeneralError(errorData.error);
			}
		} catch (error) {
			console.error('Error during password reset:', error);
		}
	};

	const notificationVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
		exit: { opacity: 0 },
	};

	return (
		<React.Fragment>
			<AnimatePresence>
				{formSuccess && (
					<motion.div
						key='success'
						initial='hidden'
						animate='visible'
						exit='exit'
						variants={notificationVariants}
						transition={{ opacity: { duration: 0.2, ease: 'easeInOut' } }}
						className='success'
					>
						{formSuccess}
					</motion.div>
				)}
				{generalError && (
					<motion.div
						key='formError'
						initial='hidden'
						animate='visible'
						exit='exit'
						variants={notificationVariants}
						transition={{ opacity: { duration: 0.2, ease: 'easeInOut' } }}
						className='form-error py-2 px-5 mt-2 bg-red-600 font-medium'
					>
						{generalError}
					</motion.div>
				)}
			</AnimatePresence>
			{!formSuccess && (
				<form
					onSubmit={handleSubmit}
					className='flex flex-col items-center w-[80%]'
				>
					<input
						type='password'
						name='password'
						placeholder='New Password'
						className='form-input text-black w-full px-4 py-3 mt-2 rounded-md focus:border-transparent focus:ring focus:ring-emerald-700'
						value={formData.password}
						onChange={handleChange}
					/>
					<input
						type='password'
						name='confirmPassword'
						placeholder='Confirm Password'
						className='form-input text-black w-full px-4 py-3 mt-2 rounded-md focus:border-transparent focus:ring focus:ring-emerald-700'
						value={formData.confirmPassword}
						onChange={handleChange}
					/>
					<AnimatePresence>
						{formErrors.password && (
							<motion.div
								key='passwordError'
								initial='hidden'
								animate='visible'
								exit='exit'
								variants={notificationVariants}
								transition={{ opacity: { duration: 0.2, ease: 'easeInOut' } }}
								className='field-error font-medium self-start'
							>
								{formErrors.password}
							</motion.div>
						)}
						{formErrors.confirmPassword && (
							<motion.div
								key='confirmPasswordError'
								initial='hidden'
								animate='visible'
								exit='exit'
								variants={notificationVariants}
								transition={{ opacity: { duration: 0.2, ease: 'easeInOut' } }}
								className='field-error font-medium self-start'
							>
								{formErrors.confirmPassword}
							</motion.div>
						)}
					</AnimatePresence>
					<button
						type='submit'
						className='primary'
					>
						Submit
					</button>
				</form>
			)}
		</React.Fragment>
	);
};

export default NewPasswordForm;
