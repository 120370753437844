import React, { useEffect, useState } from 'react';

import Nav from '../components/UIElements/Navigation';
import Footer from '../components/UIElements/Footer';
import PremiumSubscribe from '../components/UIElements/Payments/PremiumSubscribe';
import MobileNav from '../components/UIElements/Landing/MobileNav';

const Premium = () => {
	const [colorMode, setColorMode] = useState(() => {
		// Determine initial color mode based on system preference
		return window.matchMedia('(prefers-color-scheme: dark)').matches;
	});

	// Effect to listen for changes in system color scheme preferences
	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const handleChange = (e) => {
			setColorMode(e.matches);
		};

		mediaQuery.addEventListener('change', handleChange);

		return () => mediaQuery.removeEventListener('change', handleChange);
	}, []);
	useEffect(() => {}, []);

	return (
		<React.Fragment>
			<Nav />
			<MobileNav />
			<h1 className='pt-24 mb-1 text-slate-700 dark:text-white text-center'>Get Premium</h1>
			<p className='text-slate-700 dark:text-white text-center'>Unlock the future of application tracking</p>
			<section className='features'>
				<div className='feature-history'>
					<div className='feature-content'>
						<h3>History</h3>
						<p>
							<i className='fa-solid fa-check text-green-600'></i> Track your applications as they progress through each
							stage.
						</p>
						<p>
							<i className='fa-solid fa-check text-green-600'></i> Daily recap emails to stay up to date on changes in
							your applications.
						</p>
					</div>
					<img
						alt='application history'
						className='rounded-md max-h-[110px] h-[110px] '
						src={colorMode ? '/images/historydark.png' : '/images/historylight.png'}
					/>
				</div>
				<div className='feature-history'>
					<img
						alt='application insights'
						className='rounded-md max-w-[50%]'
						src={colorMode ? '/images/insightsdark.png' : '/images/insightslight.png'}
					/>
					<div className='feature-content'>
						<h3>Insights</h3>
						<p>
							<i className='fa-solid fa-check text-green-600'></i> Generate graphs and tables to help you easier analyze
							the data.
						</p>
					</div>
				</div>
				<div className='feature-history'>
					<div className='feature-content'>
						<h3>Automated Tasks</h3>
						<p>
							<i className='fa-solid fa-check text-green-600'></i> Daily tasks to help you find any loose ends to tie
							up.
						</p>
						<p>
							<i className='fa-solid fa-check text-green-600'></i> Receive a daily task reminder email to ensure you
							don't miss anything(coming soon)
						</p>
						<p>
							<i className='fa-solid fa-check text-green-600'></i> Google Calendar integration(coming soon)
						</p>
					</div>
					<img
						alt='application tasks'
						className='rounded-md max-w-[50%]'
						src={colorMode ? '/images/tasksdark.PNG' : '/images/taskslight.PNG'}
					/>
				</div>
			</section>
			<PremiumSubscribe />
			<Footer />
		</React.Fragment>
	);
};

export default Premium;
