import React, { useMemo } from 'react';

const ApplicationWidgets = (props) => {
	const sortedApplications = props.sortedApplications;

	const isDateInLastThreeMonths = (dateString) => {
		const inputDate = new Date(dateString);
		const currentDate = new Date();

		// Calculate the difference in months
		const monthsDifference =
			(currentDate.getFullYear() - inputDate.getFullYear()) * 12 + currentDate.getMonth() - inputDate.getMonth();

		// Check if the date is within the last three months
		if (monthsDifference < 2) {
			return true;
		} else if (monthsDifference === 2) {
			// If the difference is exactly three months, check the day of the month
			return currentDate.getDate() >= inputDate.getDate();
		}

		return false;
	};

	const countEmails = useMemo(() => {
		return sortedApplications.reduce((totalEmails, application) => {
			// Check if review is false and emails array exists
			if (!application.review && application.emails && application.emails.length > 0) {
				return totalEmails + application.emails.length;
			}
			return totalEmails;
		}, 0);
	}, [sortedApplications]);

	const countRejections = useMemo(() => {
		return sortedApplications.reduce((totalRejections, application) => {
			if (application.stage === 'rejected') {
				return totalRejections + 1;
			}
			return totalRejections;
		}, 0);
	}, [sortedApplications]);

	const countApplications = useMemo(() => {
		return sortedApplications.reduce((totalApplications, application) => {
			// Check if review is false
			if (!application.review) {
				return totalApplications + 1;
			}
			return totalApplications;
		}, 0);
	}, [sortedApplications]);

	const countInterviews = useMemo(() => {
		return sortedApplications.reduce((totalInterviews, application) => {
			if (application.history && Array.isArray(application.history) && application.history.length > 0) {
				const hasInterviewInHistory = application.history.some((entry) => entry.stage === 'interview');
				if (hasInterviewInHistory) {
					return totalInterviews + 1;
				}
			}
			return totalInterviews;
		}, 0);
	}, [sortedApplications]);

	const countGhosted = useMemo(() => {
		return sortedApplications.reduce((totalGhosted, application) => {
			// Check if application has emails and if timeReceived is defined
			if (
				application.emails &&
				Array.isArray(application.emails) &&
				application.emails.length > 0 &&
				application.emails[0]?.timeReceived &&
				(application.stage === 'submitted' ||
					(application.stage === 'follow up' && isDateInLastThreeMonths(application.emails[0].timeReceived)))
			) {
				// Check if review is false
				if (!application.review) {
					return totalGhosted + 1;
				}
			}
			return totalGhosted;
		}, 0);
	}, [sortedApplications]);

	return (
		<div id='application-widgets'>
			<div id='application-widget'>
				<span className='number'>{countApplications}</span>
				<span className='text'>Applications</span>
			</div>
			<div id='emails-widget'>
				<span className='number'>{countEmails}</span>
				<span className='text'>Emails</span>
			</div>
			<div id='interviews-widget'>
				<span className='number'>{countInterviews}</span>
				<span className='text'>Interviews</span>
			</div>
			<div id='rejections-widget'>
				<span className='number'>{countRejections}</span>
				<span className='text'>Rejections</span>
			</div>
			<div id='ghosted-widget'>
				<span className='number'>{countGhosted}</span>
				<span className='text'>Ghosted</span>
			</div>
		</div>
	);
};

export default ApplicationWidgets;
