// Action types
export const SET_APP_DATA_CHECKED_LIST = 'SET_APP_DATA_CHECKED_LIST';
export const SET_APP_DATA_DELETE = 'SET_APP_DATA_DELETE';
export const SET_APP_DATA_EDIT = 'SET_APP_DATA_EDIT';
export const SET_APP_DATA_EMAIL_DELETE = 'SET_APP_DATA_EMAIL_DELETE';
export const SET_APP_DATA_EMAILS = 'SET_APP_DATA_EMAILS';
export const SET_APP_DATA_APPLICATIONS = 'SET_APP_DATA_APPLICATIONS';
export const SET_APP_DATA_DETAILS = 'SET_APP_DATA_DETAILS';
export const SET_APP_DATA_USER_PANEL = 'SET_APP_DATA_USER_PANEL';
export const SET_APP_DATA_APP_PANEL = 'SET_APP_DATA_APP_PANEL';
export const SET_APP_DATA_TASK_PANEL = 'SET_APP_DATA_TASK_PANEL';
export const SET_APP_DATA_INSIGHTS_PANEL = 'SET_APP_DATA_INSIGHTS_PANEL';
export const SET_APP_DATA_TASK_NUMBER = 'SET_APP_DATA_TASK_NUMBER';
export const SET_APP_DATA_REVIEW_QUEUE = 'SET_APP_DATA_REVIEW_QUEUE';

// Action creators
export const setCheckedList = (value) => ({
	type: SET_APP_DATA_CHECKED_LIST,
	payload: value,
});

export const setAppToDelete = (value) => ({
	type: SET_APP_DATA_DELETE,
	payload: value,
});

export const setAppToEdit = (value) => ({
	type: SET_APP_DATA_EDIT,
	payload: value,
});

export const setEmailToDelete = (value) => ({
	type: SET_APP_DATA_EMAIL_DELETE,
	payload: value,
});

export const setSortedEmails = (value) => ({
	type: SET_APP_DATA_EMAILS,
	payload: value,
});

export const setSortedApplications = (value) => ({
	type: SET_APP_DATA_APPLICATIONS,
	payload: value,
});

export const setEditApplicationDetails = (value) => ({
	type: SET_APP_DATA_DETAILS,
	payload: value,
});

export const setShowUserPanel = (value) => ({
	type: SET_APP_DATA_USER_PANEL,
	payload: value,
});
export const setShowApplicationPanel = (value) => ({
	type: SET_APP_DATA_APP_PANEL,
	payload: value,
});
export const setShowTaskPanel = (value) => ({
	type: SET_APP_DATA_TASK_PANEL,
	payload: value,
});
export const setShowInsightsPanel = (value) => ({
	type: SET_APP_DATA_INSIGHTS_PANEL,
	payload: value,
});
export const setTaskNumber = (value) => ({
	type: SET_APP_DATA_TASK_NUMBER,
	payload: value,
});
export const setReviewQueue = (value) => ({
	type: SET_APP_DATA_REVIEW_QUEUE,
	payload: value,
});

// Initial state
const initialState = {
	checkedList: [],
	appToDelete: '',
	appToEdit: {},
	emailToDelete: '',
	sortedEmails: [],
	sortedApplications: [],
	editApplicationDetails: {
		employer: '',
		jobTitle: '',
		stage: '',
	},
	showUserPanel: false,
	showApplicationPanel: true,
	showTaskPanel: false,
	showInsightsPanel: false,
	showTaskNumber: 0,
	reviewQueue: [],
};

// Reducer
const appDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_APP_DATA_CHECKED_LIST:
			return {
				...state,
				checkedList: action.payload,
			};
		case SET_APP_DATA_DELETE:
			return {
				...state,
				appToDelete: action.payload,
			};
		case SET_APP_DATA_EDIT:
			return {
				...state,
				appToEdit: action.payload,
			};
		case SET_APP_DATA_EMAIL_DELETE:
			return {
				...state,
				emailToDelete: action.payload,
			};
		case SET_APP_DATA_EMAILS:
			return {
				...state,
				sortedEmails: action.payload,
			};
		case SET_APP_DATA_APPLICATIONS:
			return {
				...state,
				sortedApplications: action.payload,
			};
		case SET_APP_DATA_DETAILS:
			return {
				...state,
				editApplicationDetails: action.payload,
			};
		case SET_APP_DATA_USER_PANEL:
			return {
				...state,
				showUserPanel: action.payload,
			};
		case SET_APP_DATA_APP_PANEL:
			return {
				...state,
				showApplicationPanel: action.payload,
			};
		case SET_APP_DATA_TASK_PANEL:
			return {
				...state,
				showTaskPanel: action.payload,
			};
		case SET_APP_DATA_INSIGHTS_PANEL:
			return {
				...state,
				showInsightsPanel: action.payload,
			};
		case SET_APP_DATA_TASK_NUMBER:
			return {
				...state,
				showTaskNumber: action.payload,
			};
		case SET_APP_DATA_REVIEW_QUEUE:
			return {
				...state,
				reviewQueue: action.payload,
			};
		default:
			return state;
	}
};

export default appDataReducer;
