import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

import Modal from './Modal';
import '../../styles/UIElements/Email.css';
import { AnimatePresence } from 'framer-motion';

const Email = (props) => {
	const navigate = useNavigate();
	const application = props.data;

	const emailId = application.emails[0].emailId;
	const contentToTrim = application.emails[0].bodySnippet;

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	/* Local State */
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [emailData, setEmailData] = useState({});

	const trimBody = (body) => {
		const contentLength = 100;
		const trimmedBody = body.trim();
		let content;
		if (application.emails[0].bodySnippet.length > contentLength) {
			// Find the last space before the specified length
			const lastSpaceIndex = trimmedBody.lastIndexOf(' ', contentLength);

			// Cut the string at the last space (or use contentLength if there's no space)
			content = trimmedBody.slice(0, lastSpaceIndex === -1 ? contentLength : lastSpaceIndex) + '...';
		} else {
			content = trimmedBody;
		}
		return content;
	};

	const trimDetailsBody = (body) => {
		// Remove style-related HTML and CSS
		const cleanedBody = body.replace(/<style([\s\S]*?)<\/style>/gi, '');

		// Remove all HTML tags except &nbsp; and <br>&zwnj;
		const bodyWithNbsp = cleanedBody.replace(/<(?!br\s*\/?>&nbsp;)[^>]*>/g, '');

		const bodyWithoutZwnj = bodyWithNbsp.replace(/&zwnj;/g, '');

		// Replace &nbsp; with actual line breaks
		const bodyWithLineBreaks = bodyWithoutZwnj.replace(/&nbsp;/g, '<br />');

		// Limit consecutive line breaks to two
		const bodyWithLimitedLineBreaks = bodyWithLineBreaks.replace(/(<br\s*\/?>){4,}/g, '<br /><br />');

		const trimmedBody = bodyWithLimitedLineBreaks.trim();

		return trimmedBody;
	};

	const getDate = () => {
		const date = emailData.emailData.timeReceived.split('T')[0];
		const time = emailData.emailData.timeReceived.split('T')[1].slice(0, -3);

		const formattedDate = date + ' at ' + time;
		return formattedDate;
	};

	const fetchEmailData = async () => {
		try {
			const response = await fetch(backendUrl + `fetch-emails/google/details/${emailId}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (response.ok) {
				const email = await response.json();
				console.log(email);
				setEmailData(email);
				setShowDetailsModal(true);
			} else {
				if (response.status === 401) {
					try {
						const response = await fetch(backendUrl + `authtoken`, {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json',
							},
							credentials: 'include',
						});
						if (response.ok) {
							setTimeout(() => {
								fetchEmailData();
							}, 1000);
						} else if (response.status === 401) {
							navigate('/login');
						} else if (response.status === 303) {
							const url = await response.json();
							window.location.href = url.url;
						} else {
							console.log('unable to refresh token.');
						}
					} catch (error) {
						console.log(error);
					}
				}
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
	};

	return (
		<React.Fragment>
			<AnimatePresence>
				{showDetailsModal && (
					<Modal
						allowClose={true}
						setShow={() => setShowDetailsModal(false)}
					>
						<div className='p-8 max-w-[600px]'>
							<h3>{emailData.emailData.subject}</h3>
							<span>
								<strong>Sender:</strong>{' '}
								<a href={`mailto:${emailData.emailData.sender}`}>{emailData.emailData.sender}</a>
							</span>
							<br></br>
							<span>
								<strong>Received:</strong> {getDate().replace(/-/g, '/')}
							</span>
							<p>{DOMPurify.sanitize(trimDetailsBody(emailData.emailData.body))}</p>
							<div className='modal-button_container'>
								<button
									className='cancel'
									onClick={() => {
										setShowDetailsModal(false);
									}}
								>
									Delete
								</button>
								<button
									className=''
									onClick={() => {
										setShowDetailsModal(false);
									}}
								>
									Close
								</button>
							</div>
						</div>
					</Modal>
				)}
			</AnimatePresence>
			<div
				key={emailId}
				className='email'
				onClick={() => {
					fetchEmailData();
				}}
			>
				<h5>{application.emails[0].subject}</h5>

				<p>{DOMPurify.sanitize(trimBody(contentToTrim))}</p>
			</div>
		</React.Fragment>
	);
};

export default Email;
