import React, { useEffect } from 'react';

import Navigation from '../components/UIElements/Navigation';
import Footer from '../components/UIElements/Footer';
import MobileNav from '../components/UIElements/Landing/MobileNav';

const TermsAndConditions = () => {
	useEffect(() => {}, []);

	return (
		<React.Fragment>
			<Navigation />
			<MobileNav />
			<section className='relative pt-16 pb-4 bg-section dark:bg-zinc-950 bg-cover bg-center dark:text-white text-zinc-900'>
				<div className='inset-0 p-4 pt-8 flex flex-col items-center max-w-[80%] m-auto sm:max-w-[90%] xs:max-w-[95%]'>
					<h1 className='mb-8 '>Terms and Conditions</h1>
					<div>
						<section className='terms-scroller text-sm bg-white dark:bg-zinc-950'>
							<ol>
								<li>
									<h2>Account Creation and Authentication</h2>
									<ul>
										<li>
											1.1 To access the features of our Services, you will be required to create an account. You can
											create an account using a username and password or by using a social login(ex. Google).
										</li>
										<li>
											1.2 You are responsible for maintaining the confidentiality of your account information, and you
											agree to accept responsibility for all activities that occur under your account.
										</li>
									</ul>
								</li>

								<li>
									<h2>Email Access and Processing</h2>
									<ul>
										<li>
											2.1 Our Services may allow you to connect your Gmail account to extract job application-related
											emails.
										</li>
										<li>
											2.2 By connecting your Gmail account, you grant jobWrangler permission to access, view, store, and
											process your job application-related emails.
										</li>
										<li>
											2.3 We use machine learning algorithms to analyze and determine whether an email is a job
											application and to extract relevant details from such emails.
										</li>
										<li>
											2.4 We use artificial intelligence to analyze and extract the employer, job title, and application
											stage from emails classified as job applications.
										</li>
									</ul>
								</li>

								<li>
									<h2>Privacy and Data Security</h2>
									<ul>
										<li>
											3.1 We take privacy seriously. Please review our Privacy Policy for information on how we collect,
											use, and safeguard your personal information.
										</li>
										<li>
											3.2 While we strive to protect your data, we cannot guarantee the security of information
											transmitted over the internet. You acknowledge and agree that you provide information at your own
											risk.
										</li>
									</ul>
								</li>

								<li>
									<h2>User Responsibilities</h2>
									<ul>
										<li>4.1 You agree not to use our Services for any unlawful or unauthorized purpose.</li>
										<li>4.2 You are responsible for the accuracy and legality of the information you provide to us.</li>
									</ul>
								</li>

								<li>
									<h2>Disclaimer</h2>
									<ul>
										<li>
											5.1 Our Services are provided on an "as-is" basis. We make no warranties, expressed or implied,
											regarding the accuracy or reliability of the information obtained through our Services.
										</li>
									</ul>
								</li>

								<li>
									<h2>Termination</h2>
									<ul>
										<li>
											6.1 We reserve the right to suspend or terminate your access to the Services at our discretion,
											without notice, for any reason.
										</li>
									</ul>
								</li>

								<li>
									<h2>Changes to Terms</h2>
									<ul>
										<li>
											7.1 We may modify these Terms at any time. Your continued use of the Services after changes are
											posted constitutes your acceptance of the modified Terms.
										</li>
									</ul>
								</li>

								<li>
									<h2>Contact Information</h2>
									<ul>
										<li>
											8.1 For questions or concerns about these Terms, please contact{' '}
											<a href='mailto:hello@carterclackson.ca'>here</a>.
										</li>
									</ul>
								</li>
							</ol>
						</section>
					</div>
				</div>
			</section>
			<Footer />
		</React.Fragment>
	);
};

export default TermsAndConditions;
