import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';

import {
	setUserTerms,
	setUserAutoImport,
	setUserLastImport,
	setUserStartDate,
	setUserData,
} from '../../../redux/userReducer';

import UserDetails from './UserDetails';

const UserPanel = (props) => {
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.user.userData);
	const triggerForceUpdate = () => {
		props.triggerForceUpdate();
	};

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	useEffect(() => {
		const fetchUserSettings = async () => {
			try {
				const response = await fetch(backendUrl + 'user', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				});
				if (response.ok) {
					const userData = await response.json();
					dispatch(setUserTerms(userData.user.settings.termsAccepted));
					dispatch(setUserAutoImport(userData.user.settings.autoImport));
					dispatch(setUserLastImport(userData.user.settings.lastImport));
					dispatch(setUserStartDate(userData.user.settings.startedDate));
					dispatch(setUserData(userData.user));
				} else {
					const errorData = await response.json();
					console.error(errorData.error); // Handle errors
				}
			} catch (error) {
				console.log('Error during signup: ' + error);
			}
		};

		// Fetch user terms only if there is no user data in storage already
		if (!userData) {
			fetchUserSettings();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, backendUrl]);

	const dashboardVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<React.Fragment>
			{Object.keys(userData).length > 0 && (
				<>
					<motion.div
						initial='hidden'
						animate='visible'
						exit='hidden'
						variants={dashboardVariants}
						transition={{ delay: 0.5 }}
						id='user-panel'
					>
						<UserDetails
							data={userData}
							triggerForceUpdate={triggerForceUpdate}
						/>
					</motion.div>
				</>
			)}
		</React.Fragment>
	);
};

export default UserPanel;
