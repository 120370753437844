import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import '../../../styles/UIElements/History.css';

const History = ({ historyData }) => {
	const historyVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<>
			<h4>History</h4>
			<div className='history-container'>
				<AnimatePresence>
					{historyData
						.sort((a, b) => {
							const dateA = new Date(a.date);
							const dateB = new Date(b.date);
							return dateA - dateB; // Ascending order
						})
						.map((historyItem, index) => (
							<React.Fragment key={index}>
								{index > 0 && (
									<motion.div
										initial={{ width: '0%' }}
										animate={{ width: '2%' }}
										exit={{ width: '0%' }}
										transition={{ duration: 0.5 }}
										className={`history-line`}
									></motion.div>
								)}
								<motion.div
									key={index}
									initial='hidden'
									animate='visible'
									exit='hidden'
									variants={historyVariants}
									transition={{ duration: 0.5 }}
									className={`history-step ${historyItem.stage}`}
								>
									<span className='history-stage'>{historyItem.stage}</span>
									<span className='history-date'>{historyItem.date.split('T')[0]}</span>
								</motion.div>
							</React.Fragment>
						))}
				</AnimatePresence>
			</div>
		</>
	);
};

export default History;
