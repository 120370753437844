import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';

import { setNavOpen } from '../../../redux/userReducer';
import { setTaskNumber, setTaskFollowUps } from '../../../redux/taskDataReducer';
import { setLoggedIn } from '../../../redux/loggedInReducer';

const MobileNav = () => {
	const dispatch = useDispatch();

	const navOpen = useSelector((state) => state.user.navOpen);
	const userData = useSelector((state) => state.user.userData);
	const isSubscribed = useSelector((state) => state.user.userSubscription);
	const taskCount = useSelector((state) => state.taskData.showTaskNumber);
	const lastImport = useSelector((state) => state.user.userLastImport);

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const changeNavState = () => {
		dispatch(setNavOpen(!navOpen));
	};

	const convertToUserTime = (lastImport) => {
		// Parse the lastImport string into a JavaScript Date object
		const lastImportTime = new Date(lastImport);

		// Define options to customize the time format
		const options = {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true, // Use 12-hour format
		};

		// Convert the lastImport time to the user's local time with specified options
		const adjustedLastImportTime = lastImportTime.toLocaleString(undefined, options);

		return adjustedLastImportTime;
	};
	const adjustedLastImportTime = convertToUserTime(lastImport);

	const handleLogout = async () => {
		try {
			const response = await fetch(backendUrl + 'auth/logout', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (response.ok) {
				dispatch(setLoggedIn(false));
				localStorage.removeItem('authToken');
				localStorage.removeItem('expires');
				localStorage.removeItem('lastFetchTime');
				sessionStorage.removeItem('subscription');
				sessionStorage.removeItem('authStatus');
				document.cookie =
					'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.jobwrangler.io; Secure; SameSite=Strict;';
				window.location.href = '/login';
			} else {
				console.error('Logout failed:', response.statusText);
			}
		} catch (error) {
			console.error('Error during logout:', error);
		}
	};

	useEffect(() => {
		if (isSubscribed) {
			const taskData = async () => {
				try {
					const response = await fetch(backendUrl + 'task/all', {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
					});
					const data = await response.json();
					if (response.ok) {
						if (data.length > 0) {
							const filteredData = data.filter((task) => task.status === 'unread');
							dispatch(setTaskNumber(filteredData.length));
							dispatch(setTaskFollowUps(data));
						}
					} else {
						console.error('Logout failed:', response.statusText);
					}
				} catch (error) {
					console.error('Error during logout:', error);
				}
			};

			taskData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskCount, isSubscribed]);

	const dashboardVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<>
			{navOpen && <div className='overlay-nav mobile-nav'></div>}
			<div className={`user-menu ${navOpen ? 'slide-nav' : 'slide-out-nav'} mobile-nav`}>
				<div>
					{userData.email && (
						<div className='user-details'>
							<div className='profile'>
								{/*<img src={userData.icon} />*/}
								<i className='fa-regular fa-user'></i>
								<div>
									<h6>
										{userData.name}
										{isSubscribed && <i className='fa-solid fa-crown text-yellow-400 text-xs ml-2'></i>}
									</h6>
									<span>{userData.email}</span>
								</div>
							</div>
						</div>
					)}
					{!isSubscribed && userData.email && (
						<div>
							<button
								onClick={() => {
									window.location.href = '/premium';
								}}
								className='premium'
							>
								Get Premium <i className='fa-solid fa-crown'></i>
							</button>
						</div>
					)}
					<ul>
						<li className=''>
							<button
								onClick={() => {
									window.location.href = '/dashboard';
								}}
							>
								<i className='fa-solid fa-table-list'></i> Applications
							</button>
						</li>
						<li
							className={``}
							disabled={`${!isSubscribed ? 'disabled' : ''}`}
							title='Tasks'
						>
							<button
								className={`${!isSubscribed ? 'disabled' : ''}`}
								disabled={`${!isSubscribed ? 'disabled' : ''}`}
								onClick={() => {
									window.location.href = '/dashboard?panel=tasks';
								}}
							>
								<i className='fa-solid fa-list-check'></i> Tasks{' '}
								{isSubscribed && (
									<AnimatePresence>
										{taskCount > 0 && (
											<motion.span
												initial='hidden'
												animate='visible'
												exit='hidden'
												variants={dashboardVariants}
												transition={{}}
												className='task-count'
											>
												{taskCount}
											</motion.span>
										)}
									</AnimatePresence>
								)}
								{!isSubscribed && <i className='fa-solid fa-crown text-yellow-400 text-base ml-2'></i>}
							</button>
						</li>
						<li
							className={`${!isSubscribed ? 'disabled' : ''}`}
							disabled={`${!isSubscribed ? 'disabled' : ''}`}
							title=''
						>
							<button
								onClick={() => {
									window.location.href = '/dashboard?panel=insights';
								}}
								className={`${!isSubscribed ? 'disabled' : ''}`}
								disabled={`${!isSubscribed ? 'disabled' : ''}`}
							>
								<i className='fa-solid fa-lightbulb'></i> Insights{' '}
								{!isSubscribed && <i className='fa-solid fa-crown text-yellow-400 text-base ml-2'></i>}
							</button>
						</li>
					</ul>
				</div>
				<div>
					<ul>
						<li className='no-desktop'>
							<a href='/dashboard'>Dashboard</a>
						</li>
						<li className='no-desktop'>
							<a
								className=''
								href='/feedback'
							>
								Feedback
							</a>
						</li>
					</ul>
				</div>
				<div>
					<ul>
						<li className={``}>
							<button
								onClick={() => {
									window.location.href = '/dashboard?panel=settings';
								}}
							>
								<i className='fa-solid fa-gear'></i> Settings
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									handleLogout();
									changeNavState();
								}}
							>
								<i className='fa-solid fa-arrow-right-from-bracket'></i> Logout
							</button>
						</li>
					</ul>
					{lastImport && <span className='user-last-sync'>Last Sync: {adjustedLastImportTime.toString()}</span>}
				</div>
			</div>
		</>
	);
};

export default MobileNav;
