import React, { useEffect } from 'react';

import Nav from '../components/UIElements/Navigation';
import Hero from '../components/UIElements/Landing/Hero';
import Features from '../components/UIElements/Landing/Features';
import Banner from '../components/UIElements/Landing/Banner';
import Footer from '../components/UIElements/Footer';
import MobileNav from '../components/UIElements/Landing/MobileNav';

const HomePage = () => {
	useEffect(() => {}, []);

	return (
		<React.Fragment>
			<Nav />
			<MobileNav />
			<Hero />
			<Features />
			<Banner />
			<Footer />
		</React.Fragment>
	);
};

export default HomePage;
