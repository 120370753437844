import React, { useEffect } from 'react';

import Navigation from '../components/UIElements/Navigation';
import Footer from '../components/UIElements/Footer';
import MobileNav from '../components/UIElements/Landing/MobileNav';

const PrivacyPolicy = () => {
	useEffect(() => {}, []);

	return (
		<React.Fragment>
			<Navigation />
			<MobileNav />
			<section className='relative pt-16 pb-4 bg-section dark:bg-zinc-950 bg-cover bg-center dark:text-white text-zinc-900'>
				<div className='inset-0 p-4 pt-8 flex flex-col items-center max-w-[70%] m-auto sm:max-w-[90%] xs:max-w-[95%]'>
					<h1 className='mb-8'>Privacy Policy</h1>
					<p className='mb-4'>
						jobWrangler ("we" or "us") is committed to protecting the privacy of our users. This Privacy Policy outlines
						the types of personal information we collect, how we use it, and how we safeguard your information when you
						use our application ("the App").
					</p>
					<div>
						<section className='terms-scroller text-sm bg-white dark:bg-zinc-950'>
							<ol>
								<li>
									<h2>Information We Collect</h2>
									<ul>
										<li>
											1.1 <strong>User-Provided Information:</strong> When you sign up for an account on our App, you
											provide us with personal information such as your name, email address, and password. You may also
											choose to sign in via social login services like Google, in which case we collect your information
											from those services in accordance with their privacy policies.
										</li>
										<li>
											1.2 <strong>Application Tracking Data:</strong> Users have the ability to manually add
											applications to track within the App. When you do this, we may collect information about the
											applications you add and your interactions with them.
										</li>
										<li>
											1.3 <strong>Email Scanning:</strong> If you choose to enable the feature, our App may scan your
											Gmail account for application-related emails. These emails are processed through a machine
											learning algorithm to automatically classify them for organizational purposes and then
											subsequently through artificial intelligence to extract the employer, job title, and application
											stage.
										</li>
									</ul>
								</li>

								<li>
									<h2>How We Use Your Information</h2>
									<ul>
										<li>
											2.1 <strong>To Provide and Improve the App:</strong> We use the information collected to operate,
											maintain, and improve our App, including providing personalized features and content.
										</li>
										<li>
											2.2 <strong>For Email Classification:</strong> The information obtained from email scanning is
											used solely for the purpose of classifying application-related emails to enhance the user
											experience within the App.
										</li>
										<li>
											2.3 <strong>Communication:</strong> We may use your email address to send you important updates,
											notifications, or promotional materials related to the App. You can opt out of receiving these
											communications at any time.
										</li>
									</ul>
								</li>

								<li>
									<h2>Data Security</h2>
									<p>
										We do not sell, trade, or rent your personal information to third parties. However, we may share
										your information with trusted third-party service providers who assist us in operating our App,
										conducting our business, or servicing you, as long as those parties agree to keep this information
										confidential.
									</p>
									<p>
										<strong>Data Protection Mechanisms for Sensitive Data:</strong> We prioritize the security of
										sensitive data. Our systems are designed with encryption protocols and access controls to safeguard
										any sensitive information collected.
									</p>
									<p>
										<strong>Retention or Deletion of Google User Data:</strong> We store your personal information for a
										period of teim that is consistent with our business purposes. You may request for your data to be
										deleted by using the "Delete Account" button within the User Settings panel or by emailing us at{' '}
										<a href='mailto:hello@carterclackson.ca'>hello@carterclackson.ca</a>
									</p>
								</li>

								<li>
									<h2>Children's Privacy</h2>
									<p>
										Our App is not directed to children under the age of 16, and we do not knowingly collect personal
										information from children. If you are under 16, please do not use our App or provide any personal
										information.
									</p>
								</li>

								<li>
									<h2>Changes to this Privacy Policy</h2>
									<p>
										We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any
										changes by posting the new Privacy Policy on this page. Your continued use of the App after any
										modifications to the Privacy Policy will constitute your acknowledgment of the modifications and
										your consent to abide and be bound by the modified Privacy Policy.
									</p>
								</li>

								<li>
									<h2>Contact Us</h2>
									<p>
										If you have any questions or concerns about this Privacy Policy or our practices, please contact us
										at <a href='mailto:hello@carterclackson.ca'>hello@carterclackson.ca</a>.
									</p>
								</li>
							</ol>
						</section>
					</div>
				</div>
			</section>
			<Footer />
		</React.Fragment>
	);
};

export default PrivacyPolicy;
