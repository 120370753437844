// UpdatePaymentForm.js
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const UpdatePaymentForm = ({ updatePayment, customerId }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setLoading(true);

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		});

		if (error) {
			console.error(error);
			setError(error.message);
			setLoading(false);
			return;
		}

		// Call the createSubscription function passed as props
		const result = await updatePayment(customerId, paymentMethod.id);
		setLoading(false);
		if (Object.keys(result).length === 0) {
			setError('You are already subscribed.');
		} else {
			setSuccess(true);
		}
	};
	const darkModeStyles = {
		base: {
			color: 'white',
			fontFamily: 'Inter, sans-serif',
		},
		invalid: {
			color: 'red',
		},
	};
	const lightModeStyles = {
		base: {
			color: 'grey',
			fontFamily: 'Inter, sans-serif',
		},
		invalid: {
			color: 'red',
		},
	};

	const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

	const cardElementOptions = {
		style: prefersDarkMode ? darkModeStyles : lightModeStyles,
	};

	return (
		<div className='checkout-form !w-full'>
			<form onSubmit={handleSubmit}>
				<CardElement options={cardElementOptions} />
				{error && <div style={{ color: 'red' }}>{error}</div>}
				<button
					type='submit'
					className='primary'
					disabled={!stripe || loading}
				>
					{loading ? 'Processing...' : 'Update Payment Information'}
				</button>
				{success && <span className='text-center mt-6'>Payment succeeeded</span>}
			</form>
		</div>
	);
};

export default UpdatePaymentForm;
