import React, { useEffect } from 'react';

import Navigation from '../components/UIElements/Navigation';
import Footer from '../components/UIElements/Footer';
import MobileNav from '../components/UIElements/Landing/MobileNav';

const Cookies = () => {
	useEffect(() => {}, []);

	return (
		<React.Fragment>
			<Navigation />
			<MobileNav />
			<section className='relative pt-16 pb-4 bg-section dark:bg-zinc-950 bg-cover bg-center dark:text-white text-zinc-900'>
				<div className='inset-0 p-4 pt-8 flex flex-col max-w-[70%] m-auto sm:max-w-[90%] xs:max-w-[95%]'>
					<h1 className='mb-8 text-center'>Cookie Policy</h1>
					<h2>What are cookies?</h2>
					<p>
						Cookies are small text files that are stored on your computer or mobile device when you visit a website.
						They are widely used to make websites work or to work more efficiently, as well as to provide information to
						the owners of the site.
					</p>

					<h2 className='mt-4'>How do we use cookies?</h2>
					<p className='mb-2'>We use cookies for a variety of purposes, including:</p>
					<ul>
						<li className='mb-2'>
							<strong>Essential cookies:</strong> These cookies are necessary for the website to function properly. They
							enable core functionality such as security, network management, accessibility, and payment processing. You
							cannot opt out of these cookies as they are essential for the operation of the website.
						</li>
						<li className='mb-2'>
							<strong>Analytics cookies:</strong> These cookies allow us to analyze how visitors interact with our
							website, including how they access the site, which pages they visit, and how long they spend on each page.
							This information helps us improve the website and tailor it to the needs of our users.
						</li>
						<li className='mb-2'>
							<strong>Marketing cookies:</strong> These cookies are used to track visitors across websites. The
							intention is to display ads that are relevant and engaging for the individual user and thereby more
							valuable for publishers and third-party advertisers.
						</li>
					</ul>

					<h2 className='mt-4'>How to control cookies</h2>
					<p>
						You can control and/or delete cookies as you wish. You can delete all cookies that are already on your
						computer and you can set most browsers to prevent them from being placed. If you do this, however, you may
						have to manually adjust some preferences every time you visit a site and some services and functionalities
						may not work.
					</p>

					<h2 className='mt-4'>Changes to this Cookie Policy</h2>
					<p>
						We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new
						Cookie Policy on this page.
					</p>

					<h2 className='mt-4'>Contact Us</h2>
					<p>If you have any questions about our Cookie Policy, please contact us:</p>
					<ul>
						<li>
							Email: <a href='mailto:hello@carterclackson.ca'>hello@carterclackson.ca</a>
						</li>
					</ul>
				</div>
			</section>
			<Footer />
		</React.Fragment>
	);
};

export default Cookies;
