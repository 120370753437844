import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
import { setUserApplicationData } from '../../../redux/userReducer';
import { setReviewQueue } from '../../../redux/appDataReducer';

import Modal from '../Modal';
import '../../../styles/UIElements/Application.css';
import { AnimatePresence, motion } from 'framer-motion';

const ApplicationStarter = (props) => {
	const dispatch = useDispatch();
	//const userAutoImport = useSelector((state) => state.user.userAutoImport);
	const userData = useSelector((state) => state.user.userData);
	const userStartDate = useSelector((state) => state.user.userStartDate);
	const userApplicationData = useSelector((state) => state.user.userApplicationData);

	const today = new Date();

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const [isFetching, setIsFetching] = useState(false);
	const [fetchingMessage, setFetchingMessage] = useState('');
	const [showAddModal, setShowAddModal] = useState(false);

	const [addApplicationDetails, setAddApplicationDetails] = useState({
		employer: '',
		jobTitle: '',
		stage: 'submitted',
		lastUpdated: '',
	});

	const isDateInLastThreeMonths = (dateString) => {
		const inputDate = new Date(dateString);
		const currentDate = new Date();

		// Calculate the difference in months
		const monthsDifference =
			(currentDate.getFullYear() - inputDate.getFullYear()) * 12 + currentDate.getMonth() - inputDate.getMonth();

		// Check if the date is within the last three months
		if (monthsDifference < 12) {
			return true;
		} else if (monthsDifference === 12) {
			// If the difference is exactly three months, check the day of the month
			return currentDate.getDate() <= inputDate.getDate();
		}

		return false;
	};

	const fetchNewEmails = async () => {
		if (isFetching) return;

		setIsFetching(true);

		const timeout = setTimeout(() => {
			setFetchingMessage(
				"Looks like you have a lot of emails, this may take a bit. We'll send you an email when the import has finished."
			);
		}, 10000);

		const dateString = userData?.settings?.startedDate.split('T')[0];
		const userStartDateAsDate = new Date(dateString);

		let formattedDate;

		if (isDateInLastThreeMonths(userStartDateAsDate)) {
			formattedDate = dateString; // Use the original string if it's in the last four months
		} else {
			const today = new Date();
			const FourMonthsAgo = new Date(today);
			FourMonthsAgo.setMonth(today.getMonth() - 3);

			const formattedFourMonthsAgo = FourMonthsAgo.toISOString().split('T')[0];
			formattedDate = formattedFourMonthsAgo;
		}

		try {
			const response = await fetch(backendUrl + `fetch-emails/google?date=${formattedDate}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});

			if (response.ok) {
				const newEmailData = await response.json();
				if (newEmailData.reviewQueue.length > 0) {
					dispatch(setReviewQueue(newEmailData.reviewQueue));
				}
				const currentTime = new Date().getTime();
				localStorage.setItem('lastFetchTime', currentTime);
			} else if (response.status === 205 || response.status === 401) {
				// Refresh token
				const tokenResponse = await fetch(backendUrl + `authtoken`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				});
				const url = await tokenResponse.json();
				if (tokenResponse.status === 303 || tokenResponse.status === 205) {
					window.location.href = url.url;
				}
				if (tokenResponse.ok) {
					setTimeout(() => {
						fetchNewEmails();
					}, 1000);
				} else {
					console.log('unable to refresh token.');
					setIsFetching(false);
				}
			} else if (response.status === 303) {
				const url = await response.json();
				window.location.href = url.url;
			} else if (response.status === 503) {
				setTimeout(() => {
					window.location.href = '/dashboard';
				}, 120000);
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle other errors
				setIsFetching(false);
			}
		} catch (error) {
			console.log('Not authorized to fetch emails: ' + error);
			setIsFetching(false);
			//window.location.href = `${backendUrl}authtoken`; // Redirect to backend auth route
		} finally {
			clearTimeout(timeout);
			setIsFetching(false);
		}
	};

	const handleAddApplication = async () => {
		const body = JSON.stringify({ ...addApplicationDetails });
		try {
			const response = await fetch(backendUrl + 'application', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: body,
			});
			if (response.ok) {
				const data = await response.json();
				const updatedApplications = [...userApplicationData, data.application];
				dispatch(setUserApplicationData(updatedApplications));
				setAddApplicationDetails({
					employer: '',
					jobTitle: '',
					stage: 'submitted',
					lastUpdated: today,
				});
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
	};

	const handleDateChange = (date) => {
		if (date instanceof Date) {
			const formattedDate = date.toISOString().split('T')[0] + 'T00:00:00.000Z';
			setAddApplicationDetails((prevState) => ({
				...prevState,
				lastUpdated: formattedDate,
			}));
		}
	};
	const handleAddChange = (e) => {
		const { name, value } = e.target;
		if (name === 'stage') {
			// Handle the select element separately
			setAddApplicationDetails((prevState) => ({
				...prevState,
				stage: value,
			}));
		} else {
			// For other input fields
			setAddApplicationDetails((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleShowAddModal = () => {
		setShowAddModal((prevState) => !prevState);
	};

	const [isDemo, setIsDemo] = useState(false);
	const currentPathname = window.location.pathname;

	const checkDemoPage = () => {
		if (currentPathname === '/demo') {
			setIsDemo(true);
		}
	};

	useEffect(() => {
		checkDemoPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPathname]);

	useEffect(() => {
		if (userStartDate !== undefined) {
		}
	}, [userStartDate]);

	const fetchVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<React.Fragment>
			<AnimatePresence>
				{showAddModal && (
					<Modal
						allowClose={true}
						setShow={() => {
							setAddApplicationDetails({
								employer: '',
								jobTitle: '',
								stage: '',
								lastUpdated: today,
							});
							setShowAddModal(false);
						}}
					>
						<form
							onSubmit={(e) => {
								e.preventDefault();
							}}
							className='form p-8'
						>
							<h3 className='self-start'>Application Details</h3>
							<p></p>
							<div className='form-inner_container'>
								<label>Employer:</label>
								<input
									type='text'
									name='employer'
									className='form-input'
									placeholder='Employer'
									onChange={(e) => handleAddChange(e)}
									value={addApplicationDetails.employer}
								></input>
							</div>
							<div className='form-inner_container !mt-0'>
								<label>Job Title:</label>
								<input
									type='text'
									name='jobTitle'
									className='form-input'
									placeholder='Job Title'
									onChange={(e) => handleAddChange(e)}
									value={addApplicationDetails.jobTitle}
								></input>
							</div>
							<div className='form-inner_container'>
								<label>Stage:</label>
								<select
									className='w-[68%]'
									onChange={(e) => handleAddChange(e)}
									name='stage'
									value={addApplicationDetails.stage}
								>
									<option value='submitted'>Submitted</option>
									<option value='follow up'>Follow Up</option>
									<option value='waiting on response'>Waiting on Response</option>
									<option value='interview'>Interview</option>
									<option value='assessment'>Assessment</option>
									<option value='offer'>Offer Received</option>
									<option value='withdraw'>Withdraw</option>
									<option value='rejected'>Rejected</option>
									<option value='accepted'>Accepted</option>
								</select>
							</div>
							<div className='form-inner_container-column'>
								<label>Application Date:</label>
								<DatePicker
									autoComplete='off'
									name='date'
									selected={addApplicationDetails.lastUpdated} // set the selected date
									onChange={(date) => handleDateChange(date)} // handle date change
									className={`form-input text-bold cursor-pointer`}
									dateFormat='yyyy-MM-dd' // set the date format
									placeholderText='Select date' // set placeholder text
								/>
							</div>
							<div className='form-inner_container-column'>
								<label>Notes</label>
								<textarea
									name='notes'
									className='form-textarea'
									placeholder='Notes'
									value={addApplicationDetails.notes}
									onChange={(e) => handleAddChange(e)}
								></textarea>
							</div>
							<div className='modal-button_container'>
								<button
									type='button'
									className='cancel'
									onClick={() => {
										setAddApplicationDetails({
											employer: '',
											jobTitle: '',
											stage: '',
											lastUpdated: today,
										});
										setShowAddModal(false);
									}}
								>
									Cancel
								</button>
								<button
									type='submit'
									onClick={() => {
										handleAddApplication();
										setShowAddModal(false);
									}}
								>
									Add Application
								</button>
							</div>
						</form>
					</Modal>
				)}
			</AnimatePresence>
			<div id='application-starter_container'>
				<h2>It doesn't look like you have added any applications yet.</h2>
				<span>Let's get started.</span>
				<div className='button-container'>
					<button
						className='cancel'
						onClick={() => {
							handleShowAddModal();
						}}
					>
						Add New Application
					</button>
					{userData?.settings?.autoImport && (
						<button
							disabled={isDemo || (userData?.settings && !userData.settings.autoImport)}
							className=''
							onClick={() => {
								if (!isFetching) {
									fetchNewEmails();
								}
							}}
						>
							Fetch New Applications <i className={`ml-2 fa-solid fa-rotate ${isFetching ? 'rotate-icon' : ''}`}></i>
						</button>
					)}
				</div>
				<AnimatePresence>
					{fetchingMessage && (
						<motion.p
							initial='hidden'
							animate='visible'
							exit='hidden'
							variants={fetchVariants}
							transition={{}}
							className='font-bold mt-4 text-lg text-center'
						>
							{fetchingMessage}
						</motion.p>
					)}
				</AnimatePresence>
			</div>
		</React.Fragment>
	);
};

export default ApplicationStarter;
