import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { setUserApplicationData, setUserSortedApplicationData, setUserData } from '../redux/userReducer';

import Navigation from '../components/UIElements/Navigation';
import Application from '../components/UIElements/Applications/Application';
import ApplicationActionsDemo from '../components/UIElements/Applications/ApplicationActionsDemo';
import ApplicationWidgets from '../components/UIElements/Applications/ApplicationWidgets';
import ApplicationStarter from '../components/UIElements/Applications/ApplicationStarter';
import Email from '../components/UIElements/Email';
import Modal from '../components/UIElements/Modal';
import Footer from '../components/UIElements/Footer';
import UserPanel from '../components/UIElements/Users/UserPanel';

import '../styles/Dashboard.css';
import {
	setShowUserPanel,
	setShowApplicationPanel,
	setShowTaskPanel,
	setShowInsightsPanel,
} from '../redux/appDataReducer';
import UserMenu from '../components/UIElements/Users/UserMenu';

const Dashboard = () => {
	const dispatch = useDispatch();
	const userData = {
		settings: {
			keywords: [
				'web developer',
				'software engineer',
				'full stack developer',
				'front end developer',
				'back end developer',
			],
			excludedWords: ['td bank'],
			autoImport: true,
			termsAccepted: true,
			optOut: false,
			notificationPreferences: ['assessment', 'interview', 'offer', 'rejected', 'accepted', 'submitted', 'withdraw'],
			lastLogin: '2024-02-24T13:48:08.332Z',
			lastImport: '2024-02-24T13:42:47.686Z',
			industry: 'IT',
			startedDate: '2023-08-01T05:00:00.000Z',
			subscription: false,
		},
		_id: '65d9f257d3e2edcbc7891601',
		name: 'Demo User',
		email: 'demo@jobwrangler.io',
		password: '$2b$10$m4.HqwRWHZH46W.BLzoPgeq4GDyrnS6f0n4UYDsW1sOxXDKPfyPqy',
		applications: [
			'65da194bf8e73236a340a0d5',
			'65da19d4f8e73236a340a0e9',
			'65da19fbf8e73236a340a0f3',
			'65da1a22f8e73236a340a0fd',
			'65da1a35f8e73236a340a107',
			'65da1a48f8e73236a340a111',
			'65da1a5ff8e73236a340a11b',
			'65da1a77f8e73236a340a125',
			'65da1a98f8e73236a340a12f',
			'65da1aaff8e73236a340a139',
			'65da1ac2f8e73236a340a143',
			'65da1ad1f8e73236a340a14d',
			'65da1ae2f8e73236a340a157',
			'65da1b09f8e73236a340a16b',
			'65da1e7cf8e73236a340a43b',
		],
		reviewQueue: [],
		subscription: false,
		socialAccounts: [],
		notifications: [],
		__v: 35,
	};
	const lastImport = '2024-02-24T13:42:47.686Z';
	const userApplicationData = [
		{
			_id: '65da1e7cf8e73236a340a43b',
			lastUpdated: '2024-03-01T16:51:06.413Z',
			jobTitle: 'Data Scientist',
			employer: 'Microsoft',
			stage: 'assessment',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-03-04T04:52:24.000Z',
					subject: 'Assessment Invitation: Data Scientist Position at Microsoft',
					bodySnippet:
						"Congratulations! You've been shortlisted for the Data Scientist position at Microsoft. Please complete the assessment at your earliest convenience.",
					sender: 'recruitment@microsoft.com',
				},
				{
					timeReceived: '2024-02-01T15:15:00.000Z',
					subject: 'Application Acknowledgement',
					bodySnippet:
						"Thank you for applying for the Data Scientist position at Microsoft. We'll review your application and get back to you soon.",
					sender: 'recruitment@microsoft.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'assessment',
					date: '2024-03-04T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43c',
			lastUpdated: '2024-03-01T16:51:06.413Z',
			jobTitle: 'UX Designer',
			employer: 'Apple',
			stage: 'interview',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-03-04T04:52:24.000Z',
					subject: 'Interview Invitation: UX Designer Position at Apple',
					bodySnippet:
						"We're impressed with your portfolio and would like to invite you for an interview for the UX Designer position at Apple. Please let us know your availability.",
					sender: 'recruitment@apple.com',
				},
				{
					timeReceived: '2024-02-01T15:15:00.000Z',
					subject: 'Thank You for Applying',
					bodySnippet:
						"Thank you for your interest in the UX Designer position at Apple. We'll review your application and get back to you soon.",
					sender: 'recruitment@apple.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'interview',
					date: '2024-03-04T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43e',
			lastUpdated: '2024-03-04T16:51:06.413Z',
			jobTitle: 'Product Manager',
			employer: 'Facebook',
			stage: 'rejected',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-02-28T11:35:55.000Z',
					subject: 'Interview Invitation: Product Manager Position at Facebook',
					bodySnippet:
						"We're excited to invite you for an interview for the Product Manager position at Facebook. Please confirm your availability for the interview.",
					sender: 'recruitment@facebook.com',
				},
				{
					timeReceived: '2024-02-15T17:30:20.000Z',
					subject: 'Thank You for Your Application',
					bodySnippet:
						"Thank you for applying for the Product Manager position at Facebook. We'll review your application and get back to you soon.",
					sender: 'recruitment@facebook.com',
				},
				{
					timeReceived: '2024-03-04T15:45:30.000Z',
					subject: 'Application Update: Product Manager Position at Facebook',
					bodySnippet:
						'We regret to inform you that your application for the Product Manager position at Facebook has been unsuccessful. Thank you for your interest.',
					sender: 'recruitment@facebook.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'rejected',
					date: '2024-03-04T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'interview',
					date: '2024-02-28T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-15T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43f',
			lastUpdated: '2024-03-01T16:51:06.413Z',
			jobTitle: 'Software Developer',
			employer: 'Amazon',
			stage: 'submitted',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-02-01T19:10:55.000Z',
					subject: 'Application Acknowledgement',
					bodySnippet:
						"Thank you for applying for the Software Developer position at Amazon. We'll review your application and get back to you soon.",
					sender: 'recruitment@amazon.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'submitted',
					date: '2024-03-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43g',
			lastUpdated: '2024-02-01T16:51:06.413Z',
			jobTitle: 'HR Manager',
			employer: 'Netflix',
			stage: 'follow up',
			followUp: false,
			rejected: true,
			emails: [
				{
					timeReceived: '2024-02-01T21:00:00.000Z',
					subject: 'Application Received',
					bodySnippet:
						"Thank you for your application for the HR Manager position at Netflix. We'll review it and be in touch soon regarding next steps.",
					sender: 'recruitment@netflix.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'follow up',
					date: '2024-02-24T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43d',
			lastUpdated: '2024-03-05T16:51:06.413Z',
			jobTitle: 'Financial Analyst',
			employer: 'JPMorgan Chase',
			stage: 'accepted',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-03-03T04:52:24.000Z',
					subject: 'Job Offer: Financial Analyst Position at JPMorgan Chase',
					bodySnippet:
						'Congratulations! We are pleased to offer you the Financial Analyst position at JPMorgan Chase. Please review the attached offer letter and let us know your decision.',
					sender: 'recruitment@jpmorganchase.com',
				},
				{
					timeReceived: '2024-02-01T15:15:00.000Z',
					subject: 'Application Confirmation',
					bodySnippet:
						"Thank you for applying for the Financial Analyst position at JPMorgan Chase. We'll review your application and be in touch soon.",
					sender: 'recruitment@jpmorganchase.com',
				},
				{
					timeReceived: '2024-02-15T14:30:00.000Z',
					subject: 'Assessment Invitation: Financial Analyst Position at JPMorgan Chase',
					bodySnippet:
						'Congratulations on making it to the assessment stage for the Financial Analyst position at JPMorgan Chase. Please find attached the assessment details.',
					sender: 'recruitment@jpmorganchase.com',
				},
				{
					timeReceived: '2024-02-25T09:45:00.000Z',
					subject: 'Interview Invitation: Financial Analyst Position at JPMorgan Chase',
					bodySnippet:
						'We are pleased to invite you for an interview for the Financial Analyst position at JPMorgan Chase. Please confirm your availability.',
					sender: 'recruitment@jpmorganchase.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'accepted',
					date: '2024-03-05T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'offer',
					date: '2024-03-03T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'interview',
					date: '2024-02-25T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'assessment',
					date: '2024-02-15T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'follow up',
					date: '2024-02-13T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43b',
			lastUpdated: '2024-03-04T16:51:06.413Z',
			jobTitle: 'Software Engineer',
			employer: 'Google',
			stage: 'interview',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-03-04T04:52:24.000Z',
					subject: 'Interview Invitation: Software Engineer Position at Google',
					bodySnippet:
						'Congratulations! We are impressed with your application and would like to invite you for an interview for the Software Engineer position at Google. Please let us know your availability.',
					sender: 'recruitment@google.com',
				},
				{
					timeReceived: '2024-02-01T15:15:00.000Z',
					subject: 'Thank You for Your Application',
					bodySnippet:
						'Thank you for applying for the Software Engineer position at Google. We are reviewing applications and will be in touch soon.',
					sender: 'recruitment@google.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'interview',
					date: '2024-03-04T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43c',
			lastUpdated: '2024-03-04T16:51:06.413Z',
			jobTitle: 'Marketing Manager',
			employer: 'Amazon',
			stage: 'assessment',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-03-04T04:52:24.000Z',
					subject: 'Assessment Details: Marketing Manager Position at Amazon',
					bodySnippet:
						'Thank you for your interest in the Marketing Manager position at Amazon. We would like to invite you to complete an assessment as part of our evaluation process.',
					sender: 'recruitment@amazon.com',
				},
				{
					timeReceived: '2024-02-01T15:15:00.000Z',
					subject: 'Application Received',
					bodySnippet:
						'We have received your application for the Marketing Manager position at Amazon. We will review it and be in touch soon regarding next steps.',
					sender: 'recruitment@amazon.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'assessment',
					date: '2024-03-04T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43b',
			lastUpdated: '2024-03-02T16:51:06.413Z',
			jobTitle: 'Accountant',
			employer: 'KPMG Canada',
			stage: 'withdraw',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-03-02T04:52:24.000Z',
					subject: 'Application Withdrawal: Accountant',
					bodySnippet:
						'Hello, I would like to formally withdraw my application for the Accountant position. Thank you for considering my application.',
					sender: 'gord@candidate.com',
				},
				{
					timeReceived: '2024-02-01T15:15:00.000Z',
					subject: 'Thanks for applying to KPMG',
					bodySnippet:
						'Thanks for your application for the Accountant role here at KPMG Canada. We will review your resume and reach out if there is a fit.',
					sender: 'noreply@KPMGCanada.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'withdraw',
					date: '2024-03-02T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43b',
			lastUpdated: '2024-02-24T16:51:06.413Z',
			jobTitle: 'Data Analyst',
			employer: 'Qualtrics',
			stage: 'interview',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-02-13T04:52:24.000Z',
					subject: 'Application Update: Data Analyst',
					bodySnippet:
						'Hi there! Would you able available for an interview this week? Please get back to us with some times you are available.',
					sender: 'noreply@qualtrics.com',
				},
				{
					timeReceived: '2024-02-01T15:15:00.000Z',
					subject: 'Application Received: Data Analyst',
					bodySnippet:
						'Thanks for your application for the Data Analyst role here at Qualtrics. We will review your resume and reach out of there is a fit.',
					sender: 'noreply@qualtrics.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'follow up',
					date: '2024-02-24T00:00:00.000Z',
					_id: '65da1e7cf8e73236a340a43d',
				},
				{
					stage: 'interview',
					date: '2024-02-13T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			notes: 'Referred by Michael',
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43h',
			lastUpdated: '2024-02-22T16:51:06.413Z',
			jobTitle: 'Data Analyst',
			employer: 'Tesla',
			stage: 'submitted',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-02-22T14:10:45.000Z',
					subject: 'Thank You for Your Application',
					bodySnippet:
						"Thank you for applying for the Data Analyst position at Tesla. We'll review your application and get back to you soon.",
					sender: 'recruitment@tesla.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'submitted',
					date: '2024-02-22T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43i',
			lastUpdated: '2024-02-22T16:51:06.413Z',
			jobTitle: 'Marketing Specialist',
			employer: 'Uber',
			stage: 'submitted',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-02-22T15:30:20.000Z',
					subject: 'Application Acknowledgement',
					bodySnippet:
						"Thank you for applying for the Marketing Specialist position at Uber. We'll review your application and be in touch soon.",
					sender: 'recruitment@uber.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'submitted',
					date: '2024-02-22T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43j',
			lastUpdated: '2024-03-01T16:51:06.413Z',
			jobTitle: 'Business Analyst',
			employer: 'Netflix',
			stage: 'rejected',
			followUp: false,
			rejected: true,
			emails: [
				{
					timeReceived: '2024-03-04T11:20:40.000Z',
					subject: 'Application Update: Business Analyst Position at Netflix',
					bodySnippet:
						'We regret to inform you that your application for the Business Analyst position at Netflix has been unsuccessful. Thank you for your interest.',
					sender: 'recruitment@netflix.com',
				},
				{
					timeReceived: '2024-02-01T16:55:00.000Z',
					subject: 'Application Received',
					bodySnippet:
						"Thank you for your application for the Business Analyst position at Netflix. We'll review it and be in touch soon regarding next steps.",
					sender: 'recruitment@netflix.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'rejected',
					date: '2024-03-04T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43k',
			lastUpdated: '2024-03-01T16:51:06.413Z',
			jobTitle: 'Content Writer',
			employer: 'Airbnb',
			stage: 'interview',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-03-04T12:10:15.000Z',
					subject: 'Interview Invitation: Content Writer Position at Airbnb',
					bodySnippet:
						"We're excited to invite you for an interview for the Content Writer position at Airbnb. Please confirm your availability for the interview.",
					sender: 'recruitment@airbnb.com',
				},
				{
					timeReceived: '2024-02-01T17:45:30.000Z',
					subject: 'Thank You for Your Application',
					bodySnippet:
						"Thank you for applying for the Content Writer position at Airbnb. We'll review your application and get back to you soon.",
					sender: 'recruitment@airbnb.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'interview',
					date: '2024-03-04T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43l',
			lastUpdated: '2024-02-06T16:51:06.413Z',
			jobTitle: 'Graphic Designer',
			employer: 'IBM',
			stage: 'follow up',
			followUp: false,
			rejected: false,
			emails: [
				{
					timeReceived: '2024-02-06T13:35:20.000Z',
					subject: 'Interview Invitation: Graphic Designer Position at IBM',
					bodySnippet:
						'Your assessment was done very well, we would love to have you join us for an interview! What is your earliest availability?',
					sender: 'recruitment@ibm.com',
				},
				{
					timeReceived: '2024-01-24T13:35:20.000Z',
					subject: 'Assessment Invitation: Graphic Designer Position at IBM',
					bodySnippet:
						"Congratulations! You've been shortlisted for the Graphic Designer position at IBM. Please complete the assessment at your earliest convenience.",
					sender: 'recruitment@ibm.com',
				},
				{
					timeReceived: '2024-01-12T18:20:45.000Z',
					subject: 'Application Acknowledgement',
					bodySnippet:
						"Thank you for applying for the Graphic Designer position at IBM. We'll review your application and be in touch soon.",
					sender: 'recruitment@ibm.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'follow up',
					date: '2024-02-24T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'interview',
					date: '2024-02-06T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'assessment',
					date: '2024-01-24T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-01-12T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
		{
			_id: '65da1e7cf8e73236a340a43m',
			lastUpdated: '2024-02-16T16:51:06.413Z',
			jobTitle: 'Software Tester',
			employer: 'Intel',
			stage: 'rejected',
			followUp: false,
			rejected: true,
			emails: [
				{
					timeReceived: '2024-02-16T14:00:55.000Z',
					subject: 'Application Update: Software Tester Position at Intel',
					bodySnippet:
						'We regret to inform you that your application for the Software Tester position at Intel has been unsuccessful. Thank you for your interest.',
					sender: 'recruitment@intel.com',
				},
				{
					timeReceived: '2024-02-01T19:05:10.000Z',
					subject: 'Application Received',
					bodySnippet:
						"Thank you for your application for the Software Tester position at Intel. We'll review it and be in touch soon regarding next steps.",
					sender: 'recruitment@intel.com',
				},
			],
			review: false,
			history: [
				{
					stage: 'rejected',
					date: '2024-02-16T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
				{
					stage: 'submitted',
					date: '2024-02-01T16:51:06.413Z',
					_id: '65da1e7cf8e73236a340a43e',
				},
			],
			__v: 1,
		},
	];
	const userSortedApplicationData = useSelector((state) => state.user.userSortedApplicationData);
	const showApplicationPanel = useSelector((state) => state.appData.showApplicationPanel);
	const showUserPanel = useSelector((state) => state.appData.showUserPanel);
	const isSubscribed = useSelector((state) => state.user.userSubscription);

	const environment = process.env.REACT_APP_ENV;
	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}
	const authToken = localStorage.getItem('authToken');
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const urlToken = searchParams.get('token');
	const expiry = searchParams.get('expiry');
	const panel = searchParams.get('panel');

	/* Local state */
	// eslint-disable-next-line no-unused-vars
	const [initialLoad, setInitialLoad] = useState(false);
	const [applicationToDelete, setApplicationToDelete] = useState('');
	const [applicationToEdit, setApplicationToEdit] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [emailsIsOpen, setEmailsIsOpen] = useState(false);
	const [isCheckedList, setIsCheckedList] = useState([]);

	const [editApplicationDetails, setEditApplicationDetails] = useState({
		employer: '',
		jobTitle: '',
		stage: '',
	});

	const handleShowDeleteModal = async (applicationId, data) => {
		setApplicationToDelete(applicationId);
		setShowDeleteModal(true);
	};
	const handleShowEditModal = async (application) => {
		setApplicationToEdit(application);
		setEditApplicationDetails(application);
		setShowEditModal(true);
	};

	const handleDeleteApplication = async (applicationId) => {
		const applicationWithoutDeleted = userApplicationData.filter((application) => application._id !== applicationId);
		dispatch(setUserApplicationData(applicationWithoutDeleted));
		const sortedApplicationsWithoutDeleted = userSortedApplicationData.filter(
			(application) => application._id !== applicationId
		);
		dispatch(setUserSortedApplicationData(sortedApplicationsWithoutDeleted));
		setShowDeleteModal(false);
	};

	const handleEditApplication = async (applicationToEdit) => {
		try {
			// Find the index of the edited application in sortedApplications
			const index = userApplicationData.findIndex((app) => app._id === applicationToEdit._id);

			if (index !== -1) {
				// Make a shallow copy of the application at that index
				const updatedApplicationCopy = { ...userApplicationData[index] };
				// Update only the fields you want to modify
				updatedApplicationCopy.employer = editApplicationDetails.employer;
				updatedApplicationCopy.jobTitle = editApplicationDetails.jobTitle;
				updatedApplicationCopy.stage = editApplicationDetails.stage;
				updatedApplicationCopy.notes = editApplicationDetails.notes;

				// Update the data for the application at that index
				userApplicationData[index] = updatedApplicationCopy;

				const sortedUserApplicationData = userApplicationData.slice().sort((a, b) => {
					const dateA = new Date(a.lastUpdated);
					const dateB = new Date(b.lastUpdated);
					return dateB - dateA; // Descending order
				});
				console.log(userApplicationData[index]);
				// Update the state with the modified sortedUserApplicationData
				dispatch(setUserSortedApplicationData(sortedUserApplicationData));
			}
		} catch (error) {
			console.error('Error during edit application:', error);
		}
		setShowEditModal(false);
	};

	const handleIsChecked = (data) => {
		setIsCheckedList([...isCheckedList, data]);
	};
	const handleUnchecked = (data) => {
		const updatedList = isCheckedList.filter((item) => item !== data);
		setIsCheckedList(updatedList);
	};
	const handleResetList = async () => {
		setIsCheckedList([]);
	};

	const handleEditChange = async (e) => {
		const { name, value } = e.target;
		if (name === 'stage') {
			// Handle the select element separately
			setEditApplicationDetails((prevState) => ({
				...prevState,
				stage: value,
			}));
		} else {
			// For other input fields
			setEditApplicationDetails((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const openEmailPanel = () => {
		setEmailsIsOpen((prevState) => !prevState);
	};

	const sortedApplications = userApplicationData.slice().sort((a, b) => {
		const dateA = new Date(a.lastUpdated);
		const dateB = new Date(b.lastUpdated);
		return dateB - dateA; // Descending order
	});

	// Loop through each application and sort its emails
	const sortedApplicationsWithSortedEmails = userSortedApplicationData.map((application) => {
		if (application.emails && application.emails.length > 0) {
			const sortedEmails = application.emails.slice().sort((a, b) => {
				const dateA = new Date(a.timeReceived);
				const dateB = new Date(b.timeReceived);
				return dateB - dateA; // Descending order
			});
			return { ...application, emails: sortedEmails };
		} else {
			// No emails, return the application as is
			return { ...application };
		}
	});

	const fortyEightHoursAgo = new Date().getTime() - 48 * 60 * 60 * 1000;

	// Filter applications based on the last 48 hours
	const updatedApplications = sortedApplicationsWithSortedEmails.filter((application) => {
		if (application.emails && application.emails.length > 0 && application.emails[0] !== null) {
			const lastUpdateTimestamp = new Date(application.emails[0].timeReceived).getTime();
			return lastUpdateTimestamp > fortyEightHoursAgo;
		} else {
			// No emails, consider the application as not updated
			return false;
		}
	});

	const handleShowAddModal = () => {
		setShowAddModal(true);
	};

	const resetPanels = () => {
		dispatch(setShowUserPanel(false));
		dispatch(setShowApplicationPanel(false));
		dispatch(setShowTaskPanel(false));
		dispatch(setShowInsightsPanel(false));
	};

	useEffect(() => {
		// Check if there is a token in the URL
		if (urlToken && expiry) {
			// Set the token in the cookie
			localStorage.setItem('authToken', urlToken);
			localStorage.setItem('expires', expiry);

			// Remove the token from the URL to avoid exposing it in the browser history
			window.history.replaceState({}, document.title, location.pathname);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [urlToken, location.search]);

	useEffect(() => {
		let initialUserFetchCompleted = false;

		const fetchUserSettings = async () => {
			dispatch(
				setUserData({
					settings: {
						keywords: [
							'web developer',
							'software engineer',
							'full stack developer',
							'front end developer',
							'back end developer',
						],
						excludedWords: ['td bank'],
						autoImport: true,
						termsAccepted: true,
						optOut: false,
						notificationPreferences: [
							'assessment',
							'interview',
							'offer',
							'rejected',
							'accepted',
							'submitted',
							'withdraw',
						],
						lastLogin: '2024-02-24T13:48:08.332Z',
						lastImport: '2024-02-24T13:42:47.686Z',
						industry: 'IT',
						startedDate: '2023-08-01T05:00:00.000Z',
						subscription: false,
					},
					_id: '65d9f257d3e2edcbc7891601',
					name: 'Demo User',
					email: 'demo@jobwrangler.io',
					password: '$2b$10$m4.HqwRWHZH46W.BLzoPgeq4GDyrnS6f0n4UYDsW1sOxXDKPfyPqy',
					applications: [
						'65da194bf8e73236a340a0d5',
						'65da19d4f8e73236a340a0e9',
						'65da19fbf8e73236a340a0f3',
						'65da1a22f8e73236a340a0fd',
						'65da1a35f8e73236a340a107',
						'65da1a48f8e73236a340a111',
						'65da1a5ff8e73236a340a11b',
						'65da1a77f8e73236a340a125',
						'65da1a98f8e73236a340a12f',
						'65da1aaff8e73236a340a139',
						'65da1ac2f8e73236a340a143',
						'65da1ad1f8e73236a340a14d',
						'65da1ae2f8e73236a340a157',
						'65da1b09f8e73236a340a16b',
						'65da1e7cf8e73236a340a43b',
					],
					reviewQueue: [],
					subscription: false,
					socialAccounts: [],
					notifications: [],
					__v: 35,
				})
			);
		};

		if (!initialUserFetchCompleted && authToken) {
			fetchUserSettings();
			initialUserFetchCompleted = true;
		}
		//fetchUserImage();
	}, [authToken, backendUrl, dispatch]);

	useEffect(() => {
		if (panel === 'insights') {
			resetPanels();
			dispatch(setShowInsightsPanel(true));
		} else if (panel === 'tasks') {
			resetPanels();
			dispatch(setShowTaskPanel(true));
		} else if (panel === 'settings') {
			resetPanels();
			dispatch(setShowUserPanel(true));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [panel, dispatch]);

	const applicationVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};
	const dashboardVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};
	const dashboardMarginVariants = {
		margin: { marginRight: '-31%' },
		noMargin: { marginRight: 0 },
	};
	const latestChangesVariants = {
		hidden: { right: '-110%' },
		visible: { right: 0 },
	};

	return (
		<React.Fragment>
			<AnimatePresence>
				{showDeleteModal && (
					<Modal
						allowClose='deleteModal'
						setShow={() => setShowDeleteModal(false)}
					>
						<div className='p-8'>
							<h3>Are you sure you want to delete this application?</h3>
							<p>
								If you are deleting this because it was incorrectly imported, please let us know by flagging and
								deleting it.{' '}
							</p>
							<div className='modal-button_container'>
								<button
									className='cancel'
									onClick={() => {
										setShowDeleteModal(false);
									}}
								>
									Cancel
								</button>
								<button
									className='delete secondary'
									onClick={() => {
										handleDeleteApplication(applicationToDelete);
										setShowDeleteModal(false);
									}}
								>
									Delete
								</button>
								<button
									className='delete primary'
									onClick={async () => {
										handleDeleteApplication(applicationToDelete);
										setShowDeleteModal(false);
									}}
								>
									Flag and Delete
								</button>
							</div>
						</div>
					</Modal>
				)}
				{showEditModal && (
					<Modal
						allowClose={true}
						setShow={() => setShowEditModal(false)}
					>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleEditApplication(applicationToEdit);
							}}
							className='form p-8'
						>
							<h3 className='self-start'>Application Details</h3>
							<p></p>
							<div className='form-inner_container'>
								<label>Employer:</label>
								<input
									type='text'
									name='employer'
									className='form-input'
									placeholder='Employer'
									onChange={(e) => handleEditChange(e)}
									value={editApplicationDetails.employer}
								></input>
							</div>
							<div className='form-inner_container !mt-0'>
								<label>Job Title:</label>
								<input
									type='text'
									name='jobTitle'
									className='form-input'
									placeholder='Job Title'
									onChange={(e) => handleEditChange(e)}
									value={editApplicationDetails.jobTitle}
								></input>
							</div>
							<div className='form-inner_container'>
								<label>Stage:</label>
								<select
									className='w-[68%]'
									onChange={(e) => handleEditChange(e)}
									name='stage'
									value={editApplicationDetails.stage}
								>
									<option value='submitted'>Submitted</option>
									<option value='follow up'>Follow Up</option>
									<option value='waiting on response'>Waiting on Response</option>
									<option value='interview'>Interview</option>
									<option value='assessment'>Assessment</option>
									<option value='offer'>Offer Received</option>
									<option value='withdraw'>Withdraw</option>
									<option value='rejected'>Rejected</option>
									<option value='accepted'>Accepted</option>
								</select>
							</div>
							<div className='form-inner_container-column'>
								<label>Notes</label>
								<textarea
									name='notes'
									className='form-textarea'
									placeholder='Notes'
									value={editApplicationDetails.notes}
									onChange={(e) => handleEditChange(e)}
								></textarea>
							</div>
							<div className='modal-button_container'>
								<button
									type='button'
									className='cancel'
									onClick={() => {
										setShowEditModal(false);
									}}
								>
									Cancel
								</button>
								<button
									type='submit'
									onClick={() => {
										setShowEditModal(false);
									}}
								>
									Save
								</button>
							</div>
						</form>
					</Modal>
				)}
			</AnimatePresence>
			<Navigation applications={sortedApplications} />
			<main className='flex'>
				<AnimatePresence>
					<motion.div
						initial='hidden'
						animate='visible'
						exit='hidden'
						variants={dashboardVariants}
						transition={{ delay: 0.4 }}
					>
						<UserMenu />
					</motion.div>
				</AnimatePresence>
				{userData && showUserPanel && (
					<AnimatePresence>
						<UserPanel />
					</AnimatePresence>
				)}
				{userApplicationData && showApplicationPanel && (
					<AnimatePresence>
						<motion.div
							initial='hidden'
							animate='visible'
							exit='hidden'
							variants={dashboardVariants}
							transition={{ delay: 0.4 }} // Adjust the delay as needed
							className={`main-content px-8 pl-0`}
						>
							<motion.div
								initial='noMargin'
								animate={emailsIsOpen ? 'visible' : 'margin'}
								exit='noMargin'
								variants={dashboardMarginVariants}
							>
								<ApplicationWidgets
									sortedApplications={sortedApplications}
									checkedList={isCheckedList}
								/>
								<div id='application-section'>
									<ApplicationActionsDemo
										lastUpdated={lastImport}
										applicationData={sortedApplications}
										isCheckedList={isCheckedList}
										handleResetList={handleResetList}
										showAddModal={showAddModal}
									/>
									<div className='application-header'>
										<span className='col-span-1 flex items-center'></span>
										<span className='col-span-2'>Employer</span>
										<span className='col-span-3'>Job Title</span>
										<span className='col-span-2 flex justify-center w-[90%]'>Stage</span>
										<span className='col-span-1 flex justify-center w-[90%]'>Emails</span>
										<span className='col-span-2 flex justify-center w-[90%]'>Last Updated</span>
										<span className='col-span-1'></span>
									</div>
									{sortedApplications.length > 0 && (
										<div className='application_container'>
											<AnimatePresence>
												{userSortedApplicationData.map((application, index) => (
													<React.Fragment key={index}>
														<motion.div
															initial='hidden'
															animate='visible'
															exit='hidden'
															variants={applicationVariants}
															transition={{ delay: index * 0.01 }}
														>
															<Application
																key={application._id}
																data={application}
																handleDeleteApplication={() => handleShowDeleteModal(application._id)}
																handleEditApplication={() => handleShowEditModal(application)}
																handleChecked={() => handleIsChecked(application._id)}
																handleUnchecked={() => handleUnchecked(application._id)}
															/>
														</motion.div>
														{!isSubscribed && userSortedApplicationData.length > 0 && (index + 1) % 5 === 0 && (
															<div className='google-ads'>
																<script
																	async
																	src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1030788272342852'
																	crossOrigin='anonymous'
																></script>
																<ins
																	className='adsbygoogle'
																	style={{ display: 'block' }}
																	data-ad-format='fluid'
																	data-ad-layout-key='-gw-3+1f-3d+2z'
																	data-ad-client='ca-pub-1030788272342852'
																	data-ad-slot='3290249374'
																></ins>
																<script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>
															</div>
														)}
													</React.Fragment>
												))}
											</AnimatePresence>
										</div>
									)}
								</div>
								{!sortedApplications.length && <ApplicationStarter showAddModal={handleShowAddModal} />}
							</motion.div>
						</motion.div>
					</AnimatePresence>
				)}
				<AnimatePresence>
					<motion.aside
						initial='hidden'
						animate={emailsIsOpen ? 'visible' : 'hidden'}
						exit='hidden'
						variants={latestChangesVariants}
						transition={{}}
						id='recent-updates'
						className={` ${emailsIsOpen ? 'flex' : ''}`}
					>
						<button
							onClick={() => {
								openEmailPanel();
							}}
							className='email-drawer-button email-inner-button'
						>
							<i
								className={`${emailsIsOpen ? '' : 'rotate-180'} fa-solid fa-angle-right `}
								aria-hidden='true'
							></i>
						</button>
						<div>
							<h4 className='text-base'>Latest Changes</h4>
							{updatedApplications.length === 0 && (
								<div className='emails-container'>
									<p>No new emails.</p>
								</div>
							)}
							<div className='emails-container'>
								<AnimatePresence>
									{updatedApplications.map((application, index) => (
										<motion.div
											key={index}
											initial='hidden'
											animate='visible'
											exit='hidden'
											variants={applicationVariants}
											transition={{ delay: index * 0.15 }} // Adjust the delay as needed
										>
											<Email
												key={index}
												data={application}
											/>
										</motion.div>
									))}
								</AnimatePresence>
							</div>
						</div>
					</motion.aside>
				</AnimatePresence>
			</main>
			<Footer />
		</React.Fragment>
	);
};

export default Dashboard;
