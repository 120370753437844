// Action types
export const SET_TASK_DATA_NUMBER = 'SET_TASK_DATA_NUMBER';
export const SET_TASK_DATA_FOLLOW_UP = 'SET_APP_DATA_TASK_FOLLOW_UP';

// Action creators
export const setTaskNumber = (value) => ({
	type: SET_TASK_DATA_NUMBER,
	payload: value,
});
export const setTaskFollowUps = (value) => ({
	type: SET_TASK_DATA_FOLLOW_UP,
	payload: value,
});

// Initial state
const initialState = {
	showTaskNumber: 0,
	followUps: [],
};

// Reducer
const taskDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TASK_DATA_NUMBER:
			return {
				...state,
				showTaskNumber: action.payload,
			};
		case SET_TASK_DATA_FOLLOW_UP:
			return {
				...state,
				followUps: action.payload,
			};
		default:
			return state;
	}
};

export default taskDataReducer;
