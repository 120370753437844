import React from 'react';

import '../../../styles/UIElements/Application.css';

const ApplicationLimited = (props) => {
	const applicationData = props.data;
	const selectedAppId = props.selectedAppId;

	const dateObject = applicationData.lastUpdated ? new Date(applicationData.lastUpdated) : null;
	const formattedDate = dateObject ? dateObject.toISOString().split('T')[0].replace(/-/g, '/') : null;

	const trimEmployerName = (employer) => {
		const contentLength = 15;
		if (employer.length > contentLength) {
			return employer.slice(0, contentLength) + '...';
		}
		return employer;
	};

	return (
		<React.Fragment>
			<div
				id={`${applicationData.threadId}`}
				className={`application limited ${selectedAppId === applicationData._id ? 'selected' : ''}`}
				onClick={() => props.handleClickApplication(applicationData)}
			>
				<span className='col-span-2 flex items-center'>
					{applicationData.employer
						? trimEmployerName(applicationData.employer)
						: trimEmployerName(applicationData.emails[0].sender)}
				</span>
				<span className='col-span-3 flex items-center'>{applicationData.jobTitle}</span>
				<span className={`stage`}>
					<span className={`${applicationData.stage} `}>{applicationData.stage}</span>
				</span>
				<span className='col-span-2 flex justify-center items-center'>{formattedDate}</span>
			</div>
		</React.Fragment>
	);
};

export default ApplicationLimited;
