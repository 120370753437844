import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';

import Modal from '../Modal';
import History from './History';
import '../../../styles/UIElements/Application.css';

const Application = (props) => {
	const applicationData = props.data;

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const isSubscribed = useSelector((state) => state.user.userSubscription);

	const [showOptions, setShowOptions] = useState(false);
	const [showEmailChain, setShowEmailChain] = useState(false);
	const [emailToDelete, setEmailToDelete] = useState('');
	const [showEmailDeleteModal, setShowEmailDeleteModal] = useState(false);
	const applicationRef = useRef(null);
	const [sortedEmails, setSortedEmails] = useState(
		applicationData.emails
			? applicationData.emails.slice().sort((a, b) => {
					const dateA = a.timeReceived ? new Date(a.timeReceived) : null;
					const dateB = b.timeReceived ? new Date(b.timeReceived) : null;

					// Handle the case where timeReceived is not defined
					if (dateA && dateB) {
						return dateB - dateA;
					} else if (!dateA && dateB) {
						return 1; // b comes before a
					} else if (dateA && !dateB) {
						return -1; // a comes before b
					} else {
						return 0; // both dates are not defined
					}
			  })
			: []
	);

	const handleDelete = () => {
		props.handleDeleteApplication(applicationData._id);
	};

	const handleDeleteEmail = async (data) => {
		try {
			const response = await fetch(backendUrl + `email/${data}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (response.ok) {
				const updatedEmails = sortedEmails.filter((email) => email._id !== data);
				setSortedEmails(updatedEmails);
				props.triggerForceUpdate();
			} else if (response.status === 401) {
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
	};

	const handleEdit = (data) => {
		setShowOptions((prevState) => !prevState);
		props.handleEditApplication(data);
	};

	const handleShowOptions = (e) => {
		e.stopPropagation();
		setShowOptions((prevState) => !prevState);
	};

	const handleShowEmailChain = () => {
		setShowEmailChain((prevState) => !prevState);
	};

	const handleDocumentClick = (event) => {
		// Check if the clicked element is inside the applicationRef (application div)
		if (applicationRef.current && !applicationRef.current.contains(event.target)) {
			// Clicked outside the application div, close the options
			setShowOptions(false);
		}
	};

	useEffect(() => {
		// Attach the click event listener when the component mounts
		document.addEventListener('click', handleDocumentClick);

		// Cleanup the event listener when the component unmounts
		return () => {
			document.removeEventListener('click', handleDocumentClick);
		};
	}, [isSubscribed]);

	const dateObject = applicationData.lastUpdated ? new Date(applicationData.lastUpdated) : null;
	const formattedDate = dateObject ? dateObject.toISOString().split('T')[0].replace(/-/g, '/') : null;

	const trimDetailsBody = (body) => {
		// Replace &#39; with a comma
		const bodyWithApostrophe = body.replace(/&#39;|'/g, "'");
		// Remove style-related HTML and CSS
		const cleanedBody = bodyWithApostrophe.replace(/<style([\s\S]*?)<\/style>/gi, '');
		// Limit consecutive line breaks to two
		const bodyWithLimitedLineBreaks = cleanedBody.replace(/(<br\s*\/?>){4,}/g, '<br /><br />');
		const trimmedBody = bodyWithLimitedLineBreaks.trim();

		return trimmedBody;
	};

	const trimEmployerName = (employer) => {
		const contentLength = 15;
		if (employer.length > contentLength) {
			return employer.slice(0, contentLength) + '...';
		}
		return employer;
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleChecked = (e) => {
		if (e.target.checked) {
			handleAddChecked(applicationData.threadId);
		} else {
			handleRemoveChecked(applicationData.threadId);
		}
	};
	const handleAddChecked = (applicationId) => {
		props.handleChecked();
		//call function in dashboard to update state to add another applicationId
	};
	const handleRemoveChecked = (applicationId) => {
		props.handleUnchecked();
		//call function in dashboard to update state to remove applicationId
	};

	const emailVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
		exit: { opacity: 0 },
	};

	const [isDemo, setIsDemo] = useState(false);
	const currentPathname = window.location.pathname;

	const checkDemoPage = () => {
		if (currentPathname === '/demo') {
			setIsDemo(true);
		}
	};

	useEffect(() => {
		checkDemoPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPathname]);

	return (
		<React.Fragment>
			<AnimatePresence>
				{showEmailDeleteModal && (
					<Modal
						allowClose={true}
						setShow={() => setShowEmailDeleteModal(false)}
					>
						<div className='p-8'>
							<h3>Are you sure you want to delete this email?</h3>
							<div className='modal-button_container'>
								<button
									className='cancel'
									onClick={() => {
										setShowEmailDeleteModal(false);
									}}
								>
									Cancel
								</button>
								<button
									className='primary delete'
									onClick={() => {
										handleDeleteEmail(emailToDelete);
										setShowEmailDeleteModal(false);
									}}
								>
									Delete
								</button>
							</div>
						</div>
					</Modal>
				)}
			</AnimatePresence>
			<div
				id={`${applicationData.threadId}`}
				className={`application`}
			>
				<span className='col-span-1 flex items-center'>
					<input
						onChange={(e) => {
							handleChecked(e);
						}}
						type='checkbox'
						className='mr-4 sm:mr-2'
						name={`${applicationData.threadId}`}
					></input>
					<i
						onClick={() => {
							handleShowEmailChain();
						}}
						className={`${showEmailChain ? 'rotate-arrow' : ''} fa-solid fa-angle-right p-2 cursor-pointer`}
					></i>
				</span>
				{applicationData.emails && applicationData.emails.length > 0 && applicationData.emails[0] !== null && (
					<span
						onClick={() => {
							handleShowEmailChain();
						}}
						className='col-span-2 flex items-center'
					>
						{applicationData.employer
							? trimEmployerName(applicationData.employer.replace(/"/g, '').split('@')[0])
							: trimEmployerName(applicationData.emails[0].sender)}
					</span>
				)}
				{applicationData.emails && applicationData.emails.length < 1 && applicationData.employer !== null && (
					<span
						onClick={() => {
							handleShowEmailChain();
						}}
						className='col-span-2 flex items-center'
					>
						{trimEmployerName(applicationData.employer)}
					</span>
				)}
				{applicationData.emails && applicationData.emails.length < 1 && applicationData.employer === null && (
					<span
						onClick={() => {
							handleShowEmailChain();
						}}
						className='col-span-2 flex items-center'
					></span>
				)}
				<span
					onClick={() => {
						handleShowEmailChain();
					}}
					className='col-span-3 flex items-center'
				>
					{applicationData.jobTitle}
				</span>
				<span
					onClick={() => {
						handleShowEmailChain();
					}}
					className={`stage`}
				>
					<span className={`${applicationData.stage} `}>{applicationData.stage}</span>
				</span>
				<span
					onClick={() => {
						handleShowEmailChain();
					}}
					className='col-span-1 flex justify-center items-center'
				>
					{applicationData.emails && applicationData.emails.length}
				</span>
				<span
					onClick={() => {
						handleShowEmailChain();
					}}
					className='col-span-2 flex justify-center items-center'
				>
					{formattedDate}
				</span>
				<span className='flex justify-end items-center col-span-1 relative'>
					{showOptions && (
						<div
							ref={applicationRef}
							className='application-options'
						>
							<span
								onClick={() => {
									handleEdit(applicationData);
								}}
							>
								Edit <i className='fas fa-pen-to-square ml-3'></i>
							</span>
							<span
								onClick={() => {
									handleDelete();
								}}
							>
								Delete <i className='fas fa-trash ml-3'></i>
							</span>
						</div>
					)}
					<i
						title='Show Application Options'
						className='fa-solid fa-ellipsis-vertical hover:opacity-60 transition-all px-4 py-2 -mr-2'
						onClick={(e) => {
							handleShowOptions(e);
						}}
					></i>
				</span>
			</div>
			<AnimatePresence>
				{showEmailChain && (
					<React.Fragment>
						<motion.div
							initial='hidden'
							animate='visible'
							exit='exit'
							variants={emailVariants}
							transition={{ duration: 0.2, ease: 'easeInOut' }}
							id='email-thread_container'
						>
							{applicationData.notes && (
								<div className='notes-container'>
									<h5>Notes:</h5>
									<div style={{ whiteSpace: 'pre-wrap' }}>
										<ul>
											{applicationData.notes.split('\n').map((line, index) => (
												<li key={index}>{line}</li>
											))}
										</ul>
									</div>
								</div>
							)}
							{sortedEmails.length < 1 && (
								<div className='email-thread_single'>There are no emails assigned to this application.</div>
							)}
							{sortedEmails.map((email, index) => (
								<div
									className='email-thread_single'
									key={email._id}
								>
									<div>
										<h4>
											{email.subject} <span className='text-xs font-light'>({email.timeReceived.split('T')[0]})</span>
										</h4>
										<span>
											<strong>Sender:</strong>{' '}
											{isValidEmail(email.sender) ? (
												<a href={`mailto:${email.sender}`}>{email.sender}</a>
											) : (
												email.sender
											)}
										</span>
										<p>{trimDetailsBody(email.bodySnippet)}...</p>
									</div>
									<button
										title='Delete Selected Email'
										onClick={() => {
											setEmailToDelete(email._id);
											setShowEmailDeleteModal(true);
										}}
										className={`ml-2 secondary-button inactive`}
									>
										<i className='fa-solid fa-trash'></i>
									</button>
								</div>
							))}
						</motion.div>
						<div
							className={`${
								isSubscribed ? 'application-history_container' : 'application-history_container not-premium'
							}`}
						>
							<div className='flex flex-col justify-center items-center w-full'>
								{isSubscribed && <History historyData={applicationData.history} />}
								{isDemo && <History historyData={applicationData.history} />}
								{!isSubscribed && (
									<>
										<h4 className={`${isDemo ? 'mt-4' : ''}`}>Application History is a premium feature.</h4>
										<button
											onClick={() => {
												window.location.href = '/premium';
											}}
											className='premium'
										>
											Get Premium <i className='fa-solid fa-crown'></i>
										</button>
									</>
								)}
							</div>
						</div>
					</React.Fragment>
				)}
			</AnimatePresence>
		</React.Fragment>
	);
};

export default Application;
