import React, { useEffect, useState } from 'react';

import LoginForm from '../components/UIElements/Forms/LoginForm';
import Navigation from '../components/UIElements/Navigation';
import Footer from '../components/UIElements/Footer';
import MobileNav from '../components/UIElements/Landing/MobileNav';

//import '../styles/Login.css';

const Login = () => {
	const [colorMode, setColorMode] = useState(() => {
		// Determine initial color mode based on system preference
		return window.matchMedia('(prefers-color-scheme: dark)').matches;
	});

	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const handleChange = (e) => {
			setColorMode(e.matches);
		};

		mediaQuery.addEventListener('change', handleChange);

		return () => mediaQuery.removeEventListener('change', handleChange);
	}, []);

	return (
		<React.Fragment>
			<Navigation />
			<MobileNav />
			<div className='inset-0 bg-black-50 text-white p-4 py-44 flex flex-col justify-center items-center h-svh'>
				<img
					alt='jobWrangler Logo'
					src={colorMode ? '/images/jobWrangler-dark.png' : '/images/jobWrangler-light.png'}
					className='logo'
				/>
				<LoginForm
					loadText='Please wait...'
					isSignup={false}
				/>
			</div>
			<Footer isFixed />
		</React.Fragment>
	);
};

export default Login;
