import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import { setShowNotifications } from '../../redux/modalDataReducer';
import { setUserData, setUserSortedApplicationData } from '../../redux/userReducer';
import { setShowUserPanel, setShowApplicationPanel } from '../../redux/appDataReducer';

import '../../styles/UIElements/Notifications.css';

const Notifications = () => {
	const notificationRef = useRef(null);
	const justOpenedRef = useRef(false);
	const [displayCount, setDisplayCount] = useState(10);

	const dispatch = useDispatch();

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}
	const userData = useSelector((state) => state.user.userData);
	const showNotifications = useSelector((state) => state.modalData.showNotifications);
	const userApplicationData = useSelector((state) => state.user.userApplicationData);

	const handleModalClick = (event) => {
		if (justOpenedRef.current) {
			justOpenedRef.current = false;
			return;
		}

		// Check if the clicked element is inside the notificationRef (notification div)
		if (notificationRef.current && !notificationRef.current.contains(event.target)) {
			// Clicked outside the notification div, close the notification div
			dispatch(setShowNotifications(false));
			setDisplayCount(10);
		}
	};

	const showInSearch = async (emailId, notificationId) => {
		const filteredApplications = userApplicationData.filter((application) => {
			// Iterate through the emails array of each application
			for (const email of application.emails) {
				// Check if the emailId matches
				if (email._id === emailId) {
					return true; // If match found, include this application
				}
			}
			return false; // If no match found, exclude this application
		});
		try {
			const response = await fetch(backendUrl + `user/clear-notification/${notificationId}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			const data = await response.json();
			if (response.ok) {
				dispatch(setUserSortedApplicationData(filteredApplications));
				dispatch(setUserData(data.user));
				dispatch(setShowNotifications(false));
				setDisplayCount(10);
			} else {
				const errorData = await response.json();
				console.error(errorData.error);
			}
		} catch (error) {
			console.log('Error clearing notifications: ' + error);
		}
	};

	const clearAllNotifications = async () => {
		try {
			const response = await fetch(backendUrl + 'user/clear-notifications', {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			const data = await response.json();
			console.log(data.user);
			if (response.ok) {
				dispatch(setUserData(data.user));
				dispatch(setShowNotifications(false));
			} else {
				const errorData = await response.json();
				console.error(errorData.error);
			}
		} catch (error) {
			console.log('Error clearing notifications: ' + error);
		}
	};

	const notificationTime = (time) => {
		const createdTime = new Date(time);
		const currentTime = new Date();

		// Get the user's timezone offset in minutes
		const userTimezoneOffset = currentTime.getTimezoneOffset();

		createdTime.setMinutes(createdTime.getMinutes() - userTimezoneOffset);

		const timeDifferenceInSeconds = Math.floor((currentTime - createdTime) / 1000);

		if (timeDifferenceInSeconds < 60) {
			return 'less than a minute ago';
		} else if (timeDifferenceInSeconds < 3600) {
			const minutes = Math.floor(timeDifferenceInSeconds / 60);
			return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
		} else if (timeDifferenceInSeconds < 86400) {
			const hours = Math.floor(timeDifferenceInSeconds / 3600);
			return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
		} else {
			const days = Math.floor(timeDifferenceInSeconds / 86400);
			return `${days} day${days !== 1 ? 's' : ''} ago`;
		}
	};

	useEffect(() => {
		// Attach the click event listener when the component mounts
		document.addEventListener('click', handleModalClick);

		// Cleanup the event listener when the component unmounts
		return () => {
			document.removeEventListener('click', handleModalClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resetPanels = () => {
		dispatch(setShowUserPanel(false));
		dispatch(setShowApplicationPanel(false));
	};

	const notificationVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
		exit: { opacity: 0 },
	};

	return (
		<React.Fragment>
			{showNotifications && (
				<motion.div
					key={1}
					className='notifications-container'
					initial='hidden'
					animate='visible'
					exit='exit'
					variants={notificationVariants}
					transition={{ opacity: { duration: 0.2, ease: 'easeInOut' } }}
				>
					<div>
						<i
							onClick={() => {
								resetPanels();
								dispatch(setShowUserPanel(true));
							}}
							title='Notification Settings'
							className='notification-settings fa-solid fa-gear'
						></i>
						{userData.notifications.length > 0 && (
							<div className='mark-as-read'>
								<button
									title='Mark all notifications as read'
									onClick={(e) => {
										e.preventDefault();
										clearAllNotifications();
									}}
									className='text-xs py-2 pl-2'
								>
									Mark all as read
								</button>
							</div>
						)}
						<div
							ref={notificationRef}
							className={`notification`}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							{userData.notifications.length < 1 && (
								<span className='self-start'>You don't have any notifications yet.</span>
							)}
							{userData.notifications.length > 0 &&
								userData.notifications
									.sort((a, b) => new Date(b.timeReceived) - new Date(a.timeReceived))
									.slice(0, displayCount)
									.map((notification, index) => (
										<div
											onClick={() => {
												showInSearch(notification.emailId, notification._id);
											}}
											className={`notification-single`}
											key={notification._id}
										>
											<div className='pr-2'>
												<h6>{notification.subject}</h6>
												<p>{notification.content}</p>
												<span>{notificationTime(notification.timeReceived)}</span>
											</div>
											<div>
												{!notification.seen && (
													<span className='new-dot'>
														<i className='fa-solid fa-circle'></i>
													</span>
												)}
											</div>
										</div>
									))}
							{userData.notifications.length > displayCount && (
								<button
									className='primary'
									onClick={() => setDisplayCount(displayCount + 10)}
								>
									View More
								</button>
							)}
						</div>
					</div>
				</motion.div>
			)}
		</React.Fragment>
	);
};

export default Notifications;
