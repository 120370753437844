import React, { useEffect, useState } from 'react';

import '../../../styles/UIElements/Features.css';

const Features = () => {
	const [colorMode, setColorMode] = useState(() => {
		// Determine initial color mode based on system preference
		return window.matchMedia('(prefers-color-scheme: dark)').matches;
	});

	// Effect to listen for changes in system color scheme preferences
	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const handleChange = (e) => {
			setColorMode(e.matches);
		};

		mediaQuery.addEventListener('change', handleChange);

		return () => mediaQuery.removeEventListener('change', handleChange);
	}, []);

	return (
		<React.Fragment>
			<section className='features'>
				<div>
					<div className='feature-content'>
						<h3>Email Integration</h3>
						<ul>
							<li>Effortlessly streamline your application tracking</li>
							<li>Gmail integration feature eliminates manual sorting</li>
							<li>Machine learning classifies and imports relevant emails into your dashboard</li>
							<li>Centralized access to all job application correspondence</li>
							<li>Simplify workflow, save time, and stay organized in your job search</li>
						</ul>
					</div>
					<img
						alt='Gmail Integration'
						className='rounded-md'
						src={colorMode ? '/images/gmailDark.png' : '/images/gmailLight.png'}
					/>
				</div>
				<div>
					<img
						alt='Gmail Integration'
						className='rounded-md'
						src={colorMode ? '/images/notificationsDark.PNG' : '/images/notificationsLight.PNG'}
					/>
					<div className='feature-content'>
						<h3>Notifications</h3>
						<ul>
							<li>Stay informed and in control of your job applications</li>
							<li>Get updates on changes in application status</li>
						</ul>
					</div>
				</div>
				<div className='feature-insights'>
					<div className='feature-content'>
						<h3>
							Insights & History <i className='fa-solid fa-crown text-yellow-400 text-base ml-2'></i>
						</h3>
						<ul>
							<li>Dive into application histories</li>
							<li>Visualize your journey with comprehensive graphs and analytics</li>
							<li>Track progress, identify trends, and optimize your strategy</li>
							<li>Empower your search with data-driven decision-making</li>
						</ul>
					</div>
					<img
						alt='Insights'
						className='rounded-md'
						src={colorMode ? '/images/insightsdark.png' : '/images/insightslight.png'}
					/>
				</div>
				<div className='feature-insights'>
					<img
						alt='Insights'
						className='rounded-md'
						src={colorMode ? '/images/tasksdark.PNG' : '/images/taskslight.PNG'}
					/>
					<div className='feature-content'>
						<h3>
							Tasks <i className='fa-solid fa-crown text-yellow-400 text-base ml-2'></i>
						</h3>
						<ul>
							<li>Stay on top of your applications with automated tasks</li>
							<li>Daily email reminders for applications that require your attention(coming soon)</li>
							<li>Generate follow up emails with one click(coming soon)</li>
						</ul>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default Features;
