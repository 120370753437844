import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'chart.js/auto';
import { SankeyController, Flow } from 'chartjs-chart-sankey';
import '../../styles/UIElements/Sankey.css';

Chart.register(SankeyController, Flow);

const SankeyDiagram = () => {
	const userApplicationData = useSelector((state) => state.user.userApplicationData);

	const colors = {
		submitted: 'white',
		assessment: '#e879f9',
		interview: '#7dd3fc',
		follow: '#fef08a',
		followup: '#fef08a',
		waiting: 'gray',
		waitingonresponse: 'gray',
		withdraw: '#fb923c',
		rejected: '#f87171',
		offer: '#86e632',
		accepted: '#42c668',
	};

	const getColor = (key) => {
		if (colors.hasOwnProperty(key)) {
			return colors[key];
		} else {
			console.error(`Color for key '${key}' not found`);
			return 'black'; // Default color if key not found
		}
	};

	const chartRef = useRef(null);

	useEffect(() => {
		const ctx = chartRef.current.getContext('2d');

		// Define the order of stages
		const stageOrder = [
			'submitted',
			'follow',
			'waiting',
			'assessment',
			'interview',
			'rejected',
			'withdraw',
			'offer',
			'accepted',
		];

		// Prepare data array for Sankey chart
		let sankeyData = [];

		// Initialize stage connections
		const stageConnections = {};
		stageOrder.forEach((stage, index) => {
			stageConnections[stage] = stageOrder.slice(index + 1);
		});

		userApplicationData.forEach((application) => {
			const { history } = application;
			if (history.length > 1) {
				for (let i = 0; i < history.length - 1; i++) {
					let fromStage = history[i].stage;
					let toStage = history[i + 1].stage;

					// Adjusting stage names
					fromStage = fromStage.toLowerCase().replace(/\s+/g, '');
					toStage = toStage.toLowerCase().replace(/\s+/g, '');

					// Changing stage names
					if (fromStage === 'followup') fromStage = 'follow';
					if (toStage === 'followup') toStage = 'follow';
					if (toStage === 'waitingonresponse') toStage = 'waiting';
					if (fromStage === 'waitingonresponse') fromStage = 'waiting';

					// Adding connections between stages
					if (!sankeyData.some((entry) => entry.from === fromStage && entry.to === toStage)) {
						sankeyData.push({ from: fromStage, to: toStage, flow: 0 });
					}
					const existingIndex = sankeyData.findIndex((entry) => entry.from === fromStage && entry.to === toStage);
					if (existingIndex !== -1) {
						sankeyData[existingIndex].flow++;
					}
				}
			}
		});

		// Adding missing stage connections
		Object.entries(stageConnections).forEach(([fromStage, toStages]) => {
			toStages.forEach((toStage) => {
				if (!sankeyData.some((entry) => entry.from === fromStage && entry.to === toStage)) {
					sankeyData.push({ from: fromStage, to: toStage, flow: 0 });
				}
			});
		});

		sankeyData = sankeyData.filter(({ from, to }) => from !== 'null' && to !== 'null');

		// Sort the data based on the predefined order of stages
		sankeyData.sort((a, b) => stageOrder.indexOf(a.from) - stageOrder.indexOf(b.from));

		const chart = new Chart(ctx, {
			type: 'sankey',
			data: {
				datasets: [
					{
						label: 'Application Data',
						data: sankeyData,
						colorFrom: (c) => getColor(c.dataset.data[c.dataIndex].from),
						colorTo: (c) => getColor(c.dataset.data[c.dataIndex].to),
						colorMode: 'gradient',
						fontColor: 'white',
						labels: {
							submitted: 'Submissions',
							follow: 'Followed Up',
							rejected: 'Rejections',
							waiting: 'Waiting on Response',
							offer: 'Offers',
							assessment: 'Assessments',
							interview: 'Interviews',
							withdraw: 'Withdrawals',
							accepted: 'Accepted',
						},
						size: 'min',
					},
				],
			},
			options: {
				sankey: {
					nodePadding: 500, // Adjust this value to increase/decrease node spacing
				},
				scales: {
					y: {
						ticks: {
							color: 'white', // Set label text color to white
						},
					},
				},
				legend: {
					labels: {
						fontColor: 'white',
					},
				},
				plugins: {
					tooltip: {
						callbacks: {
							labelColor: function (context) {
								return {
									borderColor: 'rgba(255,255,255,1)',
									backgroundColor: colors[context.dataset.data[context.dataIndex].to],
								};
							},
						},
					},
				},
			},
		});

		return () => {
			chart.destroy();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userApplicationData]);

	return (
		<canvas
			className='sankey-chart'
			ref={chartRef}
		/>
	);
};

export default SankeyDiagram;
