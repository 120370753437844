import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import SankeyDiagram from './Sankey';
import '../../styles/UIElements/InsightsPanel.css';

const InsightsPanel = () => {
	const userApplicationData = useSelector((state) => state.user.userApplicationData);
	const userData = useSelector((state) => state.user.userData);
	const [daysInSearch, setDaysInSearch] = useState(0);
	const [numberOfAppsTotal, setNumberOfAppsTotal] = useState(0);
	const [numberOfEmailsTotal, setNumberOfEmailsTotal] = useState(0);
	const [appsPerDay, setAppsPerDay] = useState(0);
	const [emailsPerDay, setEmailsPerDay] = useState(0);
	const [responseRate, setResponseRate] = useState(0);
	const [followUpRate, setFollowUpRate] = useState(0);
	const [rejectionRate, setRejectionRate] = useState(0);
	const [withdrawRate, setWithdrawRate] = useState(0);
	const [assessmentRate, setAssessmentRate] = useState(0);
	const [interviewRate, setInterviewRate] = useState(0);
	const [averageFirstResponse, setAverageFirstResponse] = useState(0);
	const [averageTimeToRejection, setAverageTimeToRejection] = useState(0);
	const [averageTimeToAssessment, setAverageTimeToAssessment] = useState(0);
	const [averageTimeToInterview, setAverageTimeToInterview] = useState(0);
	const [averageEmailsPerApp, setAverageEmailsPerApp] = useState(0);

	const daysTotal = () => {
		// Check if userData and userData.settings are defined
		if (userData && userData.settings && userData.settings.startedDate) {
			const startDate = new Date(userData.settings.startedDate);
			const currentDate = new Date();
			const differenceInTime = currentDate.getTime() - startDate.getTime();

			const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

			setDaysInSearch(differenceInDays);
			return true;
		} else {
			return false;
		}
	};

	const formatDate = (isoString) => {
		const date = new Date(isoString);
		const options = { month: 'long', day: 'numeric', year: 'numeric' };
		return date.toLocaleDateString('en-US', options);
	};

	const appsTotal = () => {
		const numberOfApplications = userApplicationData.length;
		setNumberOfAppsTotal(numberOfApplications);
	};

	const emailsTotal = () => {
		let totalEmails = 0;
		userApplicationData.forEach((application) => {
			if (application.emails && Array.isArray(application.emails)) {
				totalEmails += application.emails.length;
			}
		});
		setNumberOfEmailsTotal(totalEmails);
	};

	const applicationsPerDay = () => {
		if (daysInSearch !== 0 && numberOfAppsTotal !== 0) {
			const applications = numberOfAppsTotal / daysInSearch;
			setAppsPerDay(applications);
		} else {
			setAppsPerDay(0); // or handle the case differently based on your requirements
		}
	};

	const responsesRate = () => {
		let applicationsWithTwoEmails = 0;
		userApplicationData.forEach((application) => {
			if (application.emails && Array.isArray(application.emails) && application.emails.length >= 2) {
				applicationsWithTwoEmails++;
			}
		});

		// Calculate the response rate
		const responsesRate = numberOfAppsTotal !== 0 ? (applicationsWithTwoEmails / numberOfAppsTotal) * 100 : 0;

		setResponseRate(responsesRate);
	};

	const averageDaysForFirstResponse = () => {
		let totalDays = 0;
		let validApplications = 0;

		userApplicationData.forEach((application) => {
			if (application.emails && Array.isArray(application.emails) && application.emails.length >= 2) {
				const firstEmailDate = new Date(application.emails[0].timeReceived);
				const secondEmailDate = new Date(application.emails[1].timeReceived);
				const differenceInTime = secondEmailDate.getTime() - firstEmailDate.getTime();
				const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

				totalDays += differenceInDays;
				validApplications++;
			}
		});

		// Calculate the average days between first two emails
		const averageDays = validApplications !== 0 ? totalDays / validApplications : 0;

		setAverageFirstResponse(averageDays);
	};

	const averageDaysForRejection = () => {
		let totalDays = 0;
		let validApplications = 0;

		userApplicationData.forEach((application) => {
			const submittedEntry = application.history.find((entry) => entry.stage === 'submitted');
			const rejectedEntry = application.history.find((entry) => entry.stage === 'rejected');

			if (submittedEntry && rejectedEntry) {
				const submittedDate = new Date(submittedEntry.date);
				const rejectionDate = new Date(rejectedEntry.date);
				const differenceInTime = rejectionDate.getTime() - submittedDate.getTime();
				const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

				totalDays += differenceInDays;
				validApplications++;
			}
		});

		// Calculate the average days for rejection
		const averageDays = validApplications !== 0 ? totalDays / validApplications : 0;

		setAverageTimeToRejection(averageDays);
	};

	const averageDaysForAssessment = () => {
		let totalDays = 0;
		let validApplications = 0;

		userApplicationData.forEach((application) => {
			const submittedEntry = application.history.find((entry) => entry.stage === 'submitted');
			const assessmentEntry = application.history.find((entry) => entry.stage === 'assessment');

			if (submittedEntry && assessmentEntry) {
				const submittedDate = new Date(submittedEntry.date);
				const assessmentDate = new Date(assessmentEntry.date);
				const differenceInTime = assessmentDate.getTime() - submittedDate.getTime();
				const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

				totalDays += differenceInDays;
				validApplications++;
			}
		});

		// Calculate the average days for rejection
		const averageDays = validApplications !== 0 ? totalDays / validApplications : 0;

		setAverageTimeToAssessment(averageDays);
	};

	const averageDaysForInterview = () => {
		let totalDays = 0;
		let validApplications = 0;

		userApplicationData.forEach((application) => {
			const submittedEntry = application.history.find((entry) => entry.stage === 'submitted');
			const interviewEntry = application.history.find((entry) => entry.stage === 'interview');

			if (submittedEntry && interviewEntry) {
				const submittedDate = new Date(submittedEntry.date);
				const interviewDate = new Date(interviewEntry.date);
				const differenceInTime = interviewDate.getTime() - submittedDate.getTime();
				const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

				totalDays += differenceInDays;
				validApplications++;
			}
		});

		// Calculate the average days for rejection
		const averageDays = validApplications !== 0 ? totalDays / validApplications : 0;

		setAverageTimeToInterview(averageDays);
	};

	const averageEmailsPerApplication = () => {
		let totalEmails = 0;
		let totalApplications = 0;

		userApplicationData.forEach((application) => {
			if (Array.isArray(application.emails)) {
				totalEmails += application.emails.length;
				totalApplications++;
			}
		});

		// Calculate the average emails per application
		const averageEmails = totalApplications !== 0 ? totalEmails / totalApplications : 0;

		setAverageEmailsPerApp(averageEmails);
	};

	const countApplicationsWithFollowUp = () => {
		let count = 0;

		userApplicationData.forEach((application) => {
			// Check if both "follow up" and "waiting on response" stages are present in the history
			const hasFollowUpStage = application.history.some((entry) => entry.stage === 'follow up');
			const hasWaitingOnResponseStage = application.history.some((entry) => entry.stage === 'waiting on response');

			if (hasFollowUpStage && hasWaitingOnResponseStage) {
				count++;
			}
		});

		const followUpRate = numberOfAppsTotal !== 0 ? (count / numberOfAppsTotal) * 100 : 0;
		setFollowUpRate(followUpRate);
		return count;
	};

	const countApplicationsWithRejection = () => {
		let count = 0;

		userApplicationData.forEach((application) => {
			// Check if both "follow up" and "waiting on response" stages are present in the history
			const hasRejectionStage = application.history.some((entry) => entry.stage === 'rejected');

			if (hasRejectionStage) {
				count++;
			}
		});

		const rejectionRate = numberOfAppsTotal !== 0 ? (count / numberOfAppsTotal) * 100 : 0;
		setRejectionRate(rejectionRate);
		return count;
	};

	const countApplicationsWithWithdrawal = () => {
		let count = 0;

		userApplicationData.forEach((application) => {
			// Check if both "follow up" and "waiting on response" stages are present in the history
			const hasWithdrawStage = application.history.some((entry) => entry.stage === 'withdraw');

			if (hasWithdrawStage) {
				count++;
			}
		});

		const withdrawRate = numberOfAppsTotal !== 0 ? (count / numberOfAppsTotal) * 100 : 0;
		setWithdrawRate(withdrawRate);
		return count;
	};

	const countApplicationsWithAssessments = () => {
		let count = 0;

		userApplicationData.forEach((application) => {
			// Check if both "follow up" and "waiting on response" stages are present in the history
			const hasAssessmentStage = application.history.some((entry) => entry.stage === 'assessment');

			if (hasAssessmentStage) {
				count++;
			}
		});

		const assessmentRate = numberOfAppsTotal !== 0 ? (count / numberOfAppsTotal) * 100 : 0;
		setAssessmentRate(assessmentRate);
		return count;
	};

	const countApplicationsWithInterviews = () => {
		let count = 0;

		userApplicationData.forEach((application) => {
			// Check if both "follow up" and "waiting on response" stages are present in the history
			const hasInterviewStage = application.history.some((entry) => entry.stage === 'interview');

			if (hasInterviewStage) {
				count++;
			}
		});

		const interviewRate = numberOfAppsTotal !== 0 ? (count / numberOfAppsTotal) * 100 : 0;
		setInterviewRate(interviewRate);
		return count;
	};

	const emailsTotalPerDay = () => {
		if (daysInSearch !== 0 && numberOfEmailsTotal !== 0) {
			const emails = numberOfEmailsTotal / daysInSearch;
			setEmailsPerDay(emails);
		} else {
			setEmailsPerDay(0); // or handle the case differently based on your requirements
		}
	};

	useEffect(() => {
		daysTotal();
		appsTotal();
		emailsTotal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userApplicationData]);

	useEffect(() => {
		applicationsPerDay();
		emailsTotalPerDay();
		responsesRate();
		countApplicationsWithFollowUp();
		countApplicationsWithRejection();
		countApplicationsWithWithdrawal();
		countApplicationsWithAssessments();
		countApplicationsWithInterviews();
		averageDaysForFirstResponse();
		averageDaysForRejection();
		averageDaysForAssessment();
		averageDaysForInterview();
		averageEmailsPerApplication();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [daysInSearch, numberOfAppsTotal, numberOfEmailsTotal]);

	return (
		<motion.div
			initial='hidden'
			animate='visible'
			exit='hidden'
			className={`px-8 pl-4 insights-panel`}
		>
			<h2 className=''>Insights</h2>
			<p className='p-2 pt-0 text-base'>
				You started your search on{' '}
				{userData && userData.settings && userData.settings.startedDate ? (
					<strong className='text-sky-600'>{formatDate(userData.settings.startedDate)}</strong>
				) : (
					<strong className='text-sky-600'>Unknown</strong>
				)}
				. That's <strong className='text-sky-600'>{daysInSearch}</strong> days.
			</p>
			<div className='insights-outer'>
				<div className='insights-container'>
					<h4 className=''>Search</h4>
					<div className='insights-stats'>
						<span>
							Applications total: <strong>{numberOfAppsTotal}</strong>
						</span>
						<span>
							Emails total: <strong>{numberOfEmailsTotal}</strong>
						</span>
						<span>
							Applications/day: <strong>{appsPerDay.toFixed(2)}</strong>
						</span>
						<span>
							Emails/day: <strong>{emailsPerDay.toFixed(2)}</strong>
						</span>
						<span></span>
						<span>
							Emails/application: <strong>{averageEmailsPerApp.toFixed(2)}</strong>
						</span>
					</div>
				</div>
				<div className='insights-container'>
					<h4 className=''>Applications</h4>
					<div className='insights-widgets'>
						<span className=''>
							Responses{' '}
							<strong>
								{Math.round(responseRate)}
								<small>%</small>
							</strong>
						</span>
						<span className='assessment'>
							Assessments{' '}
							<strong>
								{Math.round(assessmentRate)}
								<small>%</small>
							</strong>
						</span>
						<span className='interview'>
							Interviews{' '}
							<strong>
								{Math.round(interviewRate)}
								<small>%</small>
							</strong>
						</span>
						<span className='follow'>
							Follow Up{' '}
							<strong>
								{Math.round(followUpRate)}
								<small>%</small>
							</strong>
						</span>
						<span className='withdraw'>
							Withdrawals{' '}
							<strong>
								{Math.round(withdrawRate)}
								<small>%</small>
							</strong>
						</span>
						<span className='rejected'>
							Rejections{' '}
							<strong>
								{Math.round(rejectionRate)}
								<small>%</small>
							</strong>
						</span>
					</div>
				</div>
				<div className='insights-container'>
					<h4 className=''>Timing</h4>
					<div className='insights-widgets timing'>
						<span className=''>
							First Response{' '}
							<strong>
								{averageFirstResponse.toFixed(2)}
								<small> days</small>
							</strong>
						</span>
						<span className='assessment'>
							Assessment{' '}
							<strong>
								{averageTimeToAssessment.toFixed(2)}
								<small> days</small>
							</strong>
						</span>
						<span className='interview'>
							Interview{' '}
							<strong>
								{averageTimeToInterview.toFixed(2)}
								<small> days</small>
							</strong>
						</span>
						<span className='rejected'>
							Rejection{' '}
							<strong>
								{averageTimeToRejection.toFixed(2)}
								<small> days</small>
							</strong>
						</span>
					</div>
				</div>
			</div>
			{userApplicationData.length > 0 && <SankeyDiagram />}
		</motion.div>
	);
};

export default InsightsPanel;
