// PaymentPage.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { setUserSubscription } from '../redux/userReducer';

import Navigation from '../components/UIElements/Navigation';
import Footer from '../components/UIElements/Footer';
import CheckoutForm from '../components/UIElements/Payments/CheckoutForm';
import MobileNav from '../components/UIElements/Landing/MobileNav';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_PROD);

const PaymentPage = () => {
	const dispatch = useDispatch();
	const [customerId, setCustomerId] = useState(null);
	const authToken = localStorage.getItem('authToken');
	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	useEffect(() => {
		const createCustomer = async () => {
			try {
				const response = await fetch(backendUrl + `subscription/create-customer`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
					body: JSON.stringify({}),
				});
				if (!response.ok) {
					throw new Error('Failed to create customer');
				}
				const data = await response.json();
				console.log('Customer created:', data.customer);
				setCustomerId(data.customer.id); // Assuming the customer object has an 'id' property
			} catch (error) {
				console.error('Error creating customer:', error);
			}
		};

		createCustomer();
	}, [authToken, backendUrl]);

	const createSubscription = async (customerId, paymentMethodId) => {
		try {
			const response = await fetch(backendUrl + `subscription/create-subscription`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					customerId: customerId,
					planId: 'price_1OlZzhAZJ5Zd81gAEopVklud',
					paymentMethodId: paymentMethodId,
				}),
			});
			const data = await response.json();
			if (!response.ok) {
				throw new Error('Failed to create subscription');
			}
			if (response.ok) {
				dispatch(setUserSubscription(true));
				sessionStorage.setItem('subscription', true);
			}
			return data;
		} catch (error) {
			console.error(error);
			return {};
		}
	};

	return (
		<div>
			<Navigation />
			<MobileNav />
			<section className='relative pt-16 pb-8 bg-section dark:bg-zinc-950 bg-zinc-100 bg-cover bg-center dark:text-white text-zinc-900'>
				<div className='inset-0 p-4 pt-8 flex flex-col bg-white dark:bg-zinc-950 items-center justify-center max-w-[70%] xs:max-w-[98%] m-auto'>
					<h1 className=' mb-8'>Subscribe to Premium</h1>
					<div className='payment-card bg-white dark:bg-zinc-950'>
						<Elements stripe={stripePromise}>
							<CheckoutForm
								createSubscription={createSubscription}
								customerId={customerId}
							/>
						</Elements>
					</div>
				</div>
			</section>
			<Footer isFixed />
		</div>
	);
};

export default PaymentPage;
