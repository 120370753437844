import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import {
	setUserTerms,
	setUserAutoImport,
	setUserLastImport,
	setUserStartDate,
	setUserApplicationData,
	setUserSortedApplicationData,
	setUserData,
} from '../redux/userReducer';

import Navigation from '../components/UIElements/Navigation';
import SignUpWalkthrough from '../components/UIElements/Forms/SignUpWalkthrough';
import Application from '../components/UIElements/Applications/Application';
import ApplicationActions from '../components/UIElements/Applications/ApplicationActions';
import ApplicationWidgets from '../components/UIElements/Applications/ApplicationWidgets';
import ApplicationStarter from '../components/UIElements/Applications/ApplicationStarter';
import Email from '../components/UIElements/Email';
import Modal from '../components/UIElements/Modal';
import Footer from '../components/UIElements/Footer';
import UserPanel from '../components/UIElements/Users/UserPanel';
import TaskPanel from '../components/UIElements/Tasks/TaskPanel';
import InsightsPanel from '../components/UIElements/InsightsPanel';

import '../styles/Dashboard.css';
import {
	setShowUserPanel,
	setShowApplicationPanel,
	setShowTaskPanel,
	setShowInsightsPanel,
	setReviewQueue,
} from '../redux/appDataReducer';
import { setTaskNumber, setTaskFollowUps } from '../redux/taskDataReducer';
import UserMenu from '../components/UIElements/Users/UserMenu';

const Dashboard = () => {
	const dispatch = useDispatch();
	const userAcceptedTerms = useSelector((state) => state.user.userTerms);
	const userData = useSelector((state) => state.user.userData);
	const lastImport = useSelector((state) => state.user.userLastImport);
	const userApplicationData = useSelector((state) => state.user.userApplicationData);
	const userSortedApplicationData = useSelector((state) => state.user.userSortedApplicationData);
	const showApplicationPanel = useSelector((state) => state.appData.showApplicationPanel);
	const showUserPanel = useSelector((state) => state.appData.showUserPanel);
	const showTaskPanel = useSelector((state) => state.appData.showTaskPanel);
	const showInsightsPanel = useSelector((state) => state.appData.showInsightsPanel);
	const taskCount = useSelector((state) => state.taskData.showTaskNumber);
	const reviewQueue = useSelector((state) => state.appData.reviewQueue);
	const isSubscribed = useSelector((state) => state.user.userSubscription);

	const environment = process.env.REACT_APP_ENV;
	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const panel = searchParams.get('panel');

	/* Local state */
	// eslint-disable-next-line no-unused-vars
	const [initialLoad, setInitialLoad] = useState(false);
	const [applicationToDelete, setApplicationToDelete] = useState('');
	const [applicationToEdit, setApplicationToEdit] = useState({});
	const [forceUpdate, setForceUpdate] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [emailsIsOpen, setEmailsIsOpen] = useState(false);
	const [isCheckedList, setIsCheckedList] = useState([]);
	const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

	const [editApplicationDetails, setEditApplicationDetails] = useState({
		employer: '',
		jobTitle: '',
		stage: '',
	});

	const triggerForceUpdate = () => {
		setForceUpdate((prevState) => !prevState);
	};

	const handleShowDeleteModal = async (applicationId, data) => {
		setApplicationToDelete(applicationId);
		setShowDeleteModal(true);
	};
	const handleShowEditModal = async (application) => {
		setApplicationToEdit(application);
		setEditApplicationDetails(application);
		setShowEditModal(true);
	};
	const handleFlagApplication = async (applicationId) => {
		try {
			const response = await fetch(backendUrl + `application/${applicationId}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			const data = await response.json();
			if (response.ok) {
				try {
					// eslint-disable-next-line no-unused-vars
					const responseData = await fetch(backendUrl + `email/${data.emails[0]._id}`, {
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
						body: JSON.stringify({ flaggedIncorrect: true }),
					});
				} catch (error) {
					console.log('unable to update email');
				}
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during flagging application: ' + error);
		}
	};
	const handleDeleteApplication = async (applicationId) => {
		try {
			const response = await fetch(backendUrl + `application/${applicationId}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (response.ok) {
				const applicationWithoutDeleted = userApplicationData.filter(
					(application) => application._id !== applicationId
				);
				dispatch(setUserApplicationData(applicationWithoutDeleted));
				const sortedApplicationsWithoutDeleted = userSortedApplicationData.filter(
					(application) => application._id !== applicationId
				);
				dispatch(setUserSortedApplicationData(sortedApplicationsWithoutDeleted));
				setForceUpdate((prevState) => !prevState);
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during application deletion: ' + error);
		}
		setShowDeleteModal(false);
	};
	const handleEditApplication = async (applicationToEdit) => {
		let data;
		try {
			const originalApplication = await fetch(backendUrl + `application/${applicationToEdit._id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (originalApplication.ok) {
				data = await originalApplication.json();
			}
		} catch (error) {
			console.log(error);
		}
		try {
			const response = await fetch(backendUrl + `application/${applicationToEdit._id}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({ ...editApplicationDetails }),
			});
			if (response.ok) {
				const updatedEditedApplication = await response.json();
				// Find the index of the edited application in sortedApplications
				const index = sortedApplications.findIndex((app) => app._id === applicationToEdit._id);
				//console.log(updatedApplication);
				if (index !== -1) {
					const today = new Date();
					// Make a shallow copy of the application at that index
					const updatedApplicationCopy = { ...sortedApplications[index] };
					// Update only the fields you want to modify
					updatedApplicationCopy.employer = updatedEditedApplication.application.employer;
					updatedApplicationCopy.jobTitle = updatedEditedApplication.application.jobTitle;
					updatedApplicationCopy.stage = updatedEditedApplication.application.stage;
					updatedApplicationCopy.notes = updatedEditedApplication.application.notes;

					// Define an array of stages that should only exist once in the history array
					const uniqueStages = ['rejected', 'submitted', 'withdraw', 'offer', 'accepted'];

					if (data.stage !== updatedApplicationCopy.stage) {
						// Check if the stage being added is one of the unique stages and if it already exists in the history array
						const isStageExists =
							uniqueStages.includes(editApplicationDetails.stage) &&
							updatedEditedApplication.application.history.some(
								(entry) => entry.stage === editApplicationDetails.stage
							);

						// If the stage is unique and doesn't exist in the history array, append it
						if (!isStageExists) {
							updatedApplicationCopy.history = [
								...updatedEditedApplication.application.history,
								{ stage: editApplicationDetails.stage, date: today.toISOString() },
							];
						}
					}

					// Update the data for the application at that index
					sortedApplications[index] = updatedApplicationCopy;
					//console.log(sortedApplications[index]);
					// Update the state with the modified sortedApplications
					dispatch(setUserSortedApplicationData([...sortedApplications]));
					setForceUpdate((prevState) => !prevState);
				}
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during signup: ' + error);
		}
		setShowEditModal(false);
	};

	const handleIsChecked = (data) => {
		setIsCheckedList([...isCheckedList, data]);
	};
	const handleUnchecked = (data) => {
		const updatedList = isCheckedList.filter((item) => item !== data);
		setIsCheckedList(updatedList);
	};
	const handleResetList = async () => {
		setIsCheckedList([]);
	};

	const handleEditChange = async (e) => {
		const { name, value } = e.target;
		if (name === 'stage') {
			// Handle the select element separately
			setEditApplicationDetails((prevState) => ({
				...prevState,
				stage: value,
			}));
		} else {
			// For other input fields
			setEditApplicationDetails((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const openEmailPanel = () => {
		setEmailsIsOpen((prevState) => !prevState);
	};

	const sortedApplications = userApplicationData.slice().sort((a, b) => {
		const dateA = new Date(a.lastUpdated);
		const dateB = new Date(b.lastUpdated);
		return dateB - dateA; // Descending order
	});

	// Loop through each application and sort its emails
	const sortedApplicationsWithSortedEmails = userSortedApplicationData.map((application) => {
		if (application.emails && application.emails.length > 0) {
			const sortedEmails = application.emails.slice().sort((a, b) => {
				const dateA = new Date(a.timeReceived);
				const dateB = new Date(b.timeReceived);
				return dateB - dateA; // Descending order
			});
			return { ...application, emails: sortedEmails };
		} else {
			// No emails, return the application as is
			return { ...application };
		}
	});

	const fortyEightHoursAgo = new Date().getTime() - 48 * 60 * 60 * 1000;

	// Filter applications based on the last 48 hours
	const updatedApplications = sortedApplicationsWithSortedEmails.filter((application) => {
		if (application.emails && application.emails.length > 0 && application.emails[0] !== null) {
			const lastUpdateTimestamp = new Date(application.emails[0].timeReceived).getTime();
			return lastUpdateTimestamp > fortyEightHoursAgo;
		} else {
			// No emails, consider the application as not updated
			return false;
		}
	});

	const handleShowAddModal = () => {
		setShowAddModal(true);
	};

	const resetPanels = () => {
		dispatch(setShowUserPanel(false));
		dispatch(setShowApplicationPanel(false));
		dispatch(setShowTaskPanel(false));
		dispatch(setShowInsightsPanel(false));
	};

	const handleAddReview = async (id) => {
		try {
			const response = await fetch(backendUrl + `reviews/${id}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (response.ok) {
				const updatedReviews = reviewQueue.filter((review) => review._id !== id);
				dispatch(setReviewQueue(updatedReviews));
				if (updatedReviews.length === 0) {
					setForceUpdate((prevState) => !prevState);
				} else {
					const newIndex = Math.min(currentReviewIndex, updatedReviews.length - 1);
					setCurrentReviewIndex(newIndex);
				}
			} else {
				const updatedReviews = reviewQueue.filter((review) => review._id !== id);
				dispatch(setReviewQueue(updatedReviews));
				if (updatedReviews.length === 0) {
					setForceUpdate((prevState) => !prevState);
				} else {
					const newIndex = Math.min(currentReviewIndex, updatedReviews.length - 1);
					setCurrentReviewIndex(newIndex);
				}
			}
		} catch (error) {
			console.error('Error during remove from review queue:', error);
		}
	};

	const handleRemoveReview = async (id) => {
		try {
			const response = await fetch(backendUrl + `reviews/${id}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (response.ok) {
				const updatedReviews = reviewQueue.filter((review) => review._id !== id);
				dispatch(setReviewQueue(updatedReviews));
				if (updatedReviews.length === 0) {
					setForceUpdate((prevState) => !prevState);
				} else {
					const newIndex = Math.min(currentReviewIndex, updatedReviews.length - 1);
					setCurrentReviewIndex(newIndex);
				}
			} else {
				const updatedReviews = reviewQueue.filter((review) => review._id !== id);
				dispatch(setReviewQueue(updatedReviews));
				if (updatedReviews.length === 0) {
					setForceUpdate((prevState) => !prevState);
				} else {
					const newIndex = Math.min(currentReviewIndex, updatedReviews.length - 1);
					setCurrentReviewIndex(newIndex);
				}
			}
		} catch (error) {
			console.error('Error during remove from review queue:', error);
		}
	};

	useEffect(() => {
		let initialUserFetchCompleted = false;

		const fetchUserSettings = async () => {
			try {
				const response = await fetch(backendUrl + 'user', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				});
				if (response.ok) {
					const userData = await response.json();
					dispatch(setUserTerms(userData.user.settings.termsAccepted));
					dispatch(setUserAutoImport(userData.user.settings.autoImport));
					dispatch(setUserLastImport(userData.user.settings.lastImport));
					dispatch(setUserStartDate(userData.user.settings.startedDate));
					dispatch(setUserData(userData.user));
				} else {
					const errorData = await response.json();
					console.error(errorData.error); // Handle errors
				}
			} catch (error) {
				console.log('Error during signup: ' + error);
			}
		};

		if (!initialUserFetchCompleted) {
			fetchUserSettings();
			initialUserFetchCompleted = true;
		}
		//fetchUserImage();
	}, [backendUrl, dispatch, forceUpdate]);

	useEffect(() => {
		let initialApplicationsFetchCompleted = false;
		const fetchUserApplications = async () => {
			try {
				const response = await fetch(backendUrl + 'application/all', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				});
				if (response.ok) {
					const applicationData = await response.json();
					dispatch(setUserApplicationData(applicationData));
					const sortedApplications = applicationData.slice().sort((a, b) => {
						const dateA = new Date(a.lastUpdated);
						const dateB = new Date(b.lastUpdated);
						return dateB - dateA; // Descending order
					});
					dispatch(setUserSortedApplicationData(sortedApplications));
					setInitialLoad(true);
				} else {
					const errorData = await response.json();
					console.error(errorData.error); // Handle errors
				}
			} catch (error) {
				console.log('Error during signup: ' + error);
			} finally {
				setInitialLoad(true);
			}
		};

		if (!initialApplicationsFetchCompleted) {
			fetchUserApplications();
			initialApplicationsFetchCompleted = true;
		}
		//fetchUserImage();
	}, [backendUrl, dispatch, forceUpdate]);

	useEffect(() => {
		if (isSubscribed) {
			const taskData = async () => {
				try {
					const response = await fetch(backendUrl + 'task/all', {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
					});
					const data = await response.json();
					if (response.ok) {
						if (data.length > 0) {
							const filteredData = data.filter((task) => task.status === 'unread');
							dispatch(setTaskNumber(filteredData.length));
							dispatch(setTaskFollowUps(data));
						}
					} else {
						console.error('Logout failed:', response.statusText);
					}
				} catch (error) {
					console.error('Error during logout:', error);
				}
			};

			taskData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskCount, forceUpdate, isSubscribed]);

	useEffect(() => {
		const fetchReviews = async () => {
			try {
				const response = await fetch(backendUrl + 'reviews', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				});
				if (response.ok) {
					const userData = await response.json();
					dispatch(setReviewQueue(userData.userQueue));
				}
			} catch (error) {
				console.log('Error during signup: ' + error);
			}
		};
		fetchReviews();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [backendUrl, dispatch]);

	useEffect(() => {
		if (panel === 'insights') {
			resetPanels();
			dispatch(setShowInsightsPanel(true));
		} else if (panel === 'tasks') {
			resetPanels();
			dispatch(setShowTaskPanel(true));
		} else if (panel === 'settings') {
			resetPanels();
			dispatch(setShowUserPanel(true));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [panel, dispatch]);

	const applicationVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};
	const dashboardVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};
	const dashboardMarginVariants = {
		margin: { marginRight: '-31%' },
		noMargin: { marginRight: 0 },
	};
	const latestChangesVariants = {
		hidden: { right: '-110%' },
		visible: { right: 0 },
	};

	return (
		<React.Fragment>
			<AnimatePresence>
				{reviewQueue.length > 0 && (
					<Modal setShow={() => setShowDeleteModal(false)}>
						{reviewQueue.map(
							(review, index) =>
								index === currentReviewIndex && (
									<div
										key={review._id}
										className='p-8 review'
									>
										<h2 className='mb-0'>Are these emails related to job applications?</h2>
										<p>Bear with us while we learn, you'll see less of these as we improve.</p>
										<motion.div
											initial='hidden'
											animate='visible'
											exit='hidden'
											variants={dashboardVariants}
											transition={{}}
											key={review._id}
											className='review-container'
										>
											<h3 className='mb-0'>{review.subject}</h3>
											<span className='text-sm'>
												Sender: <a href={`mailto:${review.sender}`}>{review.sender}</a>
											</span>
											<p>{review.bodySnippet}</p>
										</motion.div>
										<div className='modal-button_container'>
											<button
												onClick={() => {
													handleAddReview(review._id);
												}}
												className='add'
											>
												<i
													className='fa-solid fa-check'
													aria-hidden='true'
												></i>{' '}
												Yes
											</button>
											<button
												onClick={() => {
													handleRemoveReview(review._id);
												}}
												className='remove'
											>
												<i
													className='fa-solid fa-xmark'
													aria-hidden='true'
												></i>{' '}
												No
											</button>
										</div>
									</div>
								)
						)}
					</Modal>
				)}
				{showDeleteModal && (
					<Modal
						allowClose='deleteModal'
						setShow={() => setShowDeleteModal(false)}
					>
						<div className='p-8'>
							<h3>Are you sure you want to delete this application?</h3>
							<p>
								If you are deleting this because it was incorrectly imported, please let us know by flagging and
								deleting it.{' '}
							</p>
							<div className='modal-button_container'>
								<button
									className='cancel'
									onClick={() => {
										setShowDeleteModal(false);
									}}
								>
									Cancel
								</button>
								<button
									className='delete secondary'
									onClick={() => {
										handleDeleteApplication(applicationToDelete);
										setShowDeleteModal(false);
									}}
								>
									Delete
								</button>
								<button
									className='delete primary'
									onClick={async () => {
										await handleFlagApplication(applicationToDelete);
										handleDeleteApplication(applicationToDelete);
										setShowDeleteModal(false);
									}}
								>
									Flag and Delete
								</button>
							</div>
						</div>
					</Modal>
				)}
				{showEditModal && (
					<Modal
						allowClose={true}
						setShow={() => setShowEditModal(false)}
					>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleEditApplication(applicationToEdit);
							}}
							className='form p-8'
						>
							<h3 className='self-start'>Application Details</h3>
							<p></p>
							<div className='form-inner_container'>
								<label>Employer:</label>
								<input
									type='text'
									name='employer'
									className='form-input'
									placeholder='Employer'
									onChange={(e) => handleEditChange(e)}
									value={editApplicationDetails.employer}
								></input>
							</div>
							<div className='form-inner_container !mt-0'>
								<label>Job Title:</label>
								<input
									type='text'
									name='jobTitle'
									className='form-input'
									placeholder='Job Title'
									onChange={(e) => handleEditChange(e)}
									value={editApplicationDetails.jobTitle}
								></input>
							</div>
							<div className='form-inner_container'>
								<label>Stage:</label>
								<select
									className='w-[68%]'
									onChange={(e) => handleEditChange(e)}
									name='stage'
									value={editApplicationDetails.stage}
								>
									<option value='submitted'>Submitted</option>
									<option value='follow up'>Follow Up</option>
									<option value='waiting on response'>Waiting on Response</option>
									<option value='interview'>Interview</option>
									<option value='assessment'>Assessment</option>
									<option value='offer'>Offer Received</option>
									<option value='withdraw'>Withdraw</option>
									<option value='rejected'>Rejected</option>
									<option value='accepted'>Accepted</option>
								</select>
							</div>
							<div className='form-inner_container-column'>
								<label>Notes</label>
								<textarea
									name='notes'
									className='form-textarea'
									placeholder='Notes'
									value={editApplicationDetails.notes}
									onChange={(e) => handleEditChange(e)}
								></textarea>
							</div>
							<div className='modal-button_container'>
								<button
									type='button'
									className='cancel'
									onClick={() => {
										setShowEditModal(false);
									}}
								>
									Cancel
								</button>
								<button
									type='submit'
									onClick={() => {
										setShowEditModal(false);
									}}
								>
									Save
								</button>
							</div>
						</form>
					</Modal>
				)}
			</AnimatePresence>
			<Navigation
				triggerForceUpdate={triggerForceUpdate}
				applications={sortedApplications}
			/>
			<main className='flex'>
				<AnimatePresence>
					<motion.div
						initial='hidden'
						animate='visible'
						exit='hidden'
						variants={dashboardVariants}
						transition={{ delay: 0.4 }}
					>
						<UserMenu triggerForceUpdate={triggerForceUpdate} />
					</motion.div>
				</AnimatePresence>
				{userData && showUserPanel && (
					<AnimatePresence>
						<motion.div
							initial='hidden'
							animate='visible'
							exit='hidden'
							variants={dashboardVariants}
							transition={{ delay: 0.4 }}
							className='user-panel_container'
						>
							<UserPanel triggerForceUpdate={triggerForceUpdate} />
						</motion.div>
					</AnimatePresence>
				)}
				{userApplicationData && showApplicationPanel && (
					<AnimatePresence>
						<motion.div
							initial='hidden'
							animate='visible'
							exit='hidden'
							variants={dashboardVariants}
							transition={{ delay: 0.4 }} // Adjust the delay as needed
							className={`main-content px-8 pl-0`}
						>
							<motion.div
								initial='noMargin'
								animate={emailsIsOpen ? 'visible' : 'margin'}
								exit='noMargin'
								variants={dashboardMarginVariants}
							>
								<ApplicationWidgets
									sortedApplications={sortedApplications}
									checkedList={isCheckedList}
								/>
								{sortedApplications.length > 0 && (
									<div id='application-section'>
										<ApplicationActions
											triggerForceUpdate={triggerForceUpdate}
											lastUpdated={lastImport}
											applicationData={sortedApplications}
											isCheckedList={isCheckedList}
											handleResetList={handleResetList}
											showAddModal={showAddModal}
										/>
										<div className='application-header'>
											<span className='col-span-1 flex items-center'></span>
											<span className='col-span-2'>Employer</span>
											<span className='col-span-3'>Job Title</span>
											<span className='col-span-2 flex justify-center w-[90%]'>Stage</span>
											<span className='col-span-1 flex justify-center w-[90%]'>Emails</span>
											<span className='col-span-2 flex justify-center w-[90%]'>Last Updated</span>
											<span className='col-span-1'></span>
										</div>
										{sortedApplications.length > 0 && (
											<div className='application_container'>
												<AnimatePresence>
													{userSortedApplicationData.map((application, index) => (
														<React.Fragment key={index}>
															<motion.div
																initial='hidden'
																animate='visible'
																exit='hidden'
																variants={applicationVariants}
																transition={{ delay: index * 0.01 }}
															>
																<Application
																	key={application._id}
																	data={application}
																	handleDeleteApplication={() => handleShowDeleteModal(application._id)}
																	handleEditApplication={() => handleShowEditModal(application)}
																	triggerForceUpdate={triggerForceUpdate}
																	handleChecked={() => handleIsChecked(application._id)}
																	handleUnchecked={() => handleUnchecked(application._id)}
																/>
															</motion.div>
															{!isSubscribed && userSortedApplicationData.length > 0 && (index + 1) % 5 === 0 && (
																<div className='google-ads'>
																	<script
																		async
																		src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1030788272342852'
																		crossOrigin='anonymous'
																	></script>
																	<ins
																		className='adsbygoogle'
																		style={{ display: 'block' }}
																		data-ad-format='fluid'
																		data-ad-layout-key='-gw-3+1f-3d+2z'
																		data-ad-client='ca-pub-1030788272342852'
																		data-ad-slot='3290249374'
																	></ins>
																	<script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>
																</div>
															)}
														</React.Fragment>
													))}
												</AnimatePresence>
											</div>
										)}
									</div>
								)}
								{!sortedApplications.length && (
									<ApplicationStarter
										triggerForceUpdate={triggerForceUpdate}
										showAddModal={handleShowAddModal}
									/>
								)}
							</motion.div>
						</motion.div>
					</AnimatePresence>
				)}
				{userApplicationData && showTaskPanel && (
					<AnimatePresence>
						<motion.div
							initial='hidden'
							animate='visible'
							exit='hidden'
							variants={dashboardVariants}
							transition={{ delay: 0.2 }} // Adjust the delay as needed
							className={`main-content pl-0 pr-4`}
						>
							<TaskPanel
								emailsIsOpen={emailsIsOpen}
								triggerForceUpdate={triggerForceUpdate}
							/>
						</motion.div>
					</AnimatePresence>
				)}
				{userApplicationData && showInsightsPanel && (
					<AnimatePresence>
						<motion.div
							initial='hidden'
							animate='visible'
							exit='hidden'
							variants={dashboardVariants}
							transition={{ delay: 0.2 }} // Adjust the delay as needed
							className={`main-content pl-0 pr-4`}
						>
							<InsightsPanel
								emailsIsOpen={emailsIsOpen}
								triggerForceUpdate={triggerForceUpdate}
							/>
						</motion.div>
					</AnimatePresence>
				)}
				{showApplicationPanel && (
					<button
						onClick={() => {
							openEmailPanel();
						}}
						className={` ${emailsIsOpen ? 'hide' : ''} email-drawer-button`}
					>
						<i
							className={`${emailsIsOpen ? '' : 'rotate-180'} fa-solid fa-angle-right `}
							aria-hidden='true'
						></i>
					</button>
				)}
				{showApplicationPanel && (
					<AnimatePresence>
						<motion.aside
							initial='hidden'
							animate={emailsIsOpen ? 'visible' : 'hidden'}
							exit='hidden'
							variants={latestChangesVariants}
							transition={{}}
							id='recent-updates'
							className={` ${emailsIsOpen ? 'flex' : ''}`}
						>
							<button
								onClick={() => {
									openEmailPanel();
								}}
								className='email-drawer-button email-inner-button'
							>
								<i
									className={`${emailsIsOpen ? '' : 'rotate-180'} fa-solid fa-angle-right `}
									aria-hidden='true'
								></i>
							</button>
							<div>
								<h4 className='text-base'>Latest Changes</h4>
								{updatedApplications.length === 0 && (
									<div className='emails-container'>
										<p>No new emails.</p>
									</div>
								)}
								<div className='emails-container'>
									<AnimatePresence>
										{updatedApplications.map((application, index) => (
											<motion.div
												key={index}
												initial='hidden'
												animate='visible'
												exit='hidden'
												variants={applicationVariants}
												transition={{ delay: index * 0.15 }} // Adjust the delay as needed
											>
												<Email
													key={index}
													data={application}
												/>
											</motion.div>
										))}
									</AnimatePresence>
								</div>
							</div>
						</motion.aside>
					</AnimatePresence>
				)}
			</main>
			{!userAcceptedTerms && (
				<AnimatePresence>
					<motion.div
						initial='hidden'
						animate='visible'
						exit='hidden'
						variants={dashboardVariants}
						transition={{ delay: 0.5 }}
					>
						<SignUpWalkthrough />
					</motion.div>
				</AnimatePresence>
			)}
			<Footer />
		</React.Fragment>
	);
};

export default Dashboard;
