import React, { useState, useEffect } from 'react';
import { setLoggedIn } from '../../../redux/loggedInReducer';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import '../../../styles/UIElements/UserDetails.css';
import { AnimatePresence, motion } from 'framer-motion';

import { setUserData } from '../../../redux/userReducer';
import { setShowApplicationPanel, setShowUserPanel } from '../../../redux/appDataReducer';

import Modal from '../Modal';
import UpdatePaymentForm from '../Payments/UpdatePaymentForm';

const UserDetails = (props) => {
	const dispatch = useDispatch();

	const userData = useSelector((state) => state.user.userData);
	const isSubscribed = useSelector((state) => state.user.userSubscription);

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_PROD);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const keywordsFromProps = props.data.settings?.keywords || [];
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const excludedWordsFromProps = props.data.settings?.excludedWords || [];
	const autoImportFromProps = props.data.settings?.autoImport || false;
	const optOutFromProps = props.data.settings?.optOut || false;

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}
	const authToken = localStorage.getItem('authToken');
	const [customerId, setCustomerId] = useState(null);

	const [formData, setFormData] = useState({
		autoImport: false,
		optOut: false,
		excludedWords: [],
		keywords: [],
		keywordInput: '',
		excludedWordsInput: '',
		submitted: userData.settings?.notificationPreferences.includes('submitted') || false,
		followUp: userData.settings?.notificationPreferences.includes('follow up') || false,
		assessment: userData.settings?.notificationPreferences.includes('assessment') || false,
		interview: userData.settings?.notificationPreferences.includes('interview') || false,
		offer: userData.settings?.notificationPreferences.includes('offer') || false,
		rejected: userData.settings?.notificationPreferences.includes('rejected') || false,
		withdraw: userData.settings?.notificationPreferences.includes('withdraw') || false,
	});
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
	const [selectedReason, setSelectedReason] = useState('');
	const [otherReason, setOtherReason] = useState('');
	const [showPaymentUpdateModal, setShowPaymentUpdateModal] = useState(false);

	const handleOtherReasonChange = (e) => {
		setOtherReason(e.target.value);
		setSelectedReason('other');
	};

	const [pushData, setPushData] = useState({
		excludedWords: [],
		keywords: [],
		keywordInput: '',
		excludedWordsInput: '',
		notificationPreferences: [],
	});

	const [pullData, setPullData] = useState({
		excludedWords: [],
		keywords: [],
		keywordInput: '',
		excludedWordsInput: '',
		notificationPreferences: [],
	});

	const [formErrors, setFormErrors] = useState({});

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData({
			...formData,
			[name]: value,
		});

		// Reset form errors and success
		setFormErrors({
			...formErrors,
			[name]: '',
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const updatedSettings = {
				...userData.settings,
				...formData,
			};

			const updatedUserData = {
				...userData,
				settings: updatedSettings,
			};

			const response = await fetch(backendUrl + 'user/update', {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({
					...userData,
					settings: updatedSettings,
				}),
			});
			if (response.ok) {
				dispatch(setUserData(updatedUserData));
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during update: ' + error);
		}
		try {
			const response = await fetch(backendUrl + 'user/pull', {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({
					settings: {
						keywordsToRemove: pullData.keywordsToRemove,
						excludedWordsToRemove: pullData.excludedWordsToRemove,
						notificationPreferences: pullData.notificationPreferences,
					},
				}),
			});
			if (response.ok) {
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during pull: ' + error);
		}
		try {
			const response = await fetch(backendUrl + 'user/push', {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({
					settings: { ...pushData },
				}),
			});
			if (response.ok) {
			} else {
				const errorData = await response.json();
				console.error(errorData.error); // Handle errors
			}
		} catch (error) {
			console.log('Error during pull: ' + error);
		}
		resetPanels();
		dispatch(setShowApplicationPanel(true));
		props.triggerForceUpdate();
	};

	const resetPanels = () => {
		dispatch(setShowUserPanel(false));
		dispatch(setShowApplicationPanel(false));
	};

	const cancelSubscription = async () => {
		try {
			const response = await fetch(backendUrl + 'subscription/cancel-subscription', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (!response.ok) {
				throw new Error('Failed to cancel subscription');
			}
			if (response.ok) {
				const combinedText = userData.email + ': ' + selectedReason + ' ' + otherReason;
				try {
					const body = {
						subject: 'Subscription Cancellation',
						text: combinedText,
					};
					const response = await fetch(backendUrl + 'send-email/subscription-cancellation', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(body),
					});
					if (response.ok) {
					} else {
						const errorData = await response.json();
						console.error(errorData.error); // Handle errors
					}
				} catch (error) {
					console.log('Error during signup: ' + error);
				}
			}
			const data = await response.json();
			return data;
		} catch (error) {
			console.error(error);
			return {};
		}
	};

	const handleLogout = async () => {
		dispatch(setLoggedIn(false));
		localStorage.removeItem('authToken');
		localStorage.removeItem('expires');
		localStorage.removeItem('lastFetchTime');
		sessionStorage.removeItem('subscription');
		sessionStorage.removeItem('authStatus');
		document.cookie =
			'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.jobwrangler.io; Secure; SameSite=Strict;';
		window.location.href = '/login';
	};

	const deleteAccount = async () => {
		try {
			const response = await fetch(backendUrl + 'user', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (!response.ok) {
				throw new Error('Failed to delete account');
			}
			if (response.ok) {
				handleLogout();
			}
			const data = await response.json();
			return data;
		} catch (error) {
			console.error(error);
			return {};
		}
	};

	const handlePaymentUpdateSubmit = async (customerId, paymentMethodId) => {
		try {
			const response = await fetch(backendUrl + `subscription/update-payment`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					customerId: customerId,
					planId: 'price_1OlaVgAZJ5Zd81gAprxYaa3R',
					paymentMethodId: paymentMethodId,
				}),
			});
			const data = await response.json();
			if (!response.ok) {
				throw new Error('Failed to create subscription');
			}
			if (response.ok) {
				sessionStorage.setItem('subscription', true);
			}
			return data;
		} catch (error) {
			console.error(error);
			return {};
		}
	};

	useEffect(() => {
		setFormData((prevFormData) => ({
			...prevFormData,
			keywords: keywordsFromProps,
			excludedWords: excludedWordsFromProps,
			autoImport: autoImportFromProps,
			optOut: optOutFromProps,
		}));
		setPushData((prevPushData) => ({
			...prevPushData,
			keywords: [],
			excludedWords: [],
		}));
		setPullData((prevPullData) => ({
			...prevPullData,
			keywordsToRemove: [],
			excludedWordsToRemove: [],
		}));
	}, [keywordsFromProps, excludedWordsFromProps, autoImportFromProps, optOutFromProps]);

	useEffect(() => {
		const createCustomer = async () => {
			try {
				const response = await fetch(backendUrl + `subscription/create-customer`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
					body: JSON.stringify({}),
				});
				if (!response.ok) {
					throw new Error('Failed to create customer');
				}
				const data = await response.json();
				setCustomerId(data.customer.id); // Assuming the customer object has an 'id' property
			} catch (error) {
				console.error('Error creating customer:', error);
			}
		};
		if (isSubscribed) {
			createCustomer();
		}
	}, [authToken, backendUrl, isSubscribed]);

	const keywordVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	const [isDemo, setIsDemo] = useState(false);
	const currentPathname = window.location.pathname;

	const checkDemoPage = () => {
		if (currentPathname === '/demo') {
			setIsDemo(true);
		}
	};

	useEffect(() => {
		checkDemoPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPathname]);

	return (
		<>
			{showCancelModal && (
				<Modal
					allowClose='deleteModal'
					setShow={() => setShowCancelModal(false)}
				>
					<div className='p-8'>
						<h3>Confirm Cancellation</h3>
						<p>We would love to know more about why you're leaving.</p>
						<form className='mt-4'>
							<h4>Reason for Cancellation:</h4>
							<div className='reason-options'>
								<label>
									<input
										type='radio'
										name='cancelReason'
										value='foundJob'
										onChange={(e) => setSelectedReason(e.target.value)}
									/>
									Found a job
								</label>
								<label>
									<input
										type='radio'
										name='cancelReason'
										value='tooExpensive'
										onChange={(e) => setSelectedReason(e.target.value)}
									/>
									Too expensive
								</label>
								<label>
									<input
										type='radio'
										name='cancelReason'
										value='noLongerSearching'
										onChange={(e) => setSelectedReason(e.target.value)}
									/>
									Stopped searching for jobs
								</label>
								<label>
									<input
										type='radio'
										name='cancelReason'
										value='didntFindValue'
										onChange={(e) => setSelectedReason(e.target.value)}
									/>
									Subscription wasn't what I was expecting
								</label>
								<label>
									<input
										type='radio'
										name='cancelReason'
										value='other'
										onChange={() => setSelectedReason('other')}
									/>
									Other
								</label>
								{selectedReason === 'other' && (
									<textarea
										value={otherReason}
										onChange={handleOtherReasonChange}
										placeholder='Please specify your reason...'
										className='form-input'
									/>
								)}
							</div>
						</form>
						<div className='modal-button_container'>
							<button
								className='delete secondary'
								onClick={() => {
									cancelSubscription();
									setShowCancelModal(false);
								}}
							>
								Submit
							</button>
						</div>
					</div>
				</Modal>
			)}
			{showDeleteAccountModal && (
				<Modal
					allowClose='deleteModal'
					setShow={() => setShowDeleteAccountModal(false)}
				>
					<div className='p-8'>
						<h3>Confirm Account Deletion</h3>
						<p className='text-center '>We're sad to see you go.</p>
						<div className='modal-button_container'>
							<button
								className='secondary'
								onClick={() => {
									setShowDeleteAccountModal(false);
								}}
							>
								Cancel
							</button>
							<button
								className='delete secondary'
								onClick={() => {
									deleteAccount();
									setShowDeleteAccountModal(false);
								}}
							>
								Delete Account
							</button>
						</div>
					</div>
				</Modal>
			)}
			{showPaymentUpdateModal && (
				<Modal
					allowClose='paymentUpdateModal'
					setShow={() => setShowPaymentUpdateModal(false)}
				>
					<div className='p-8'>
						<h3>Update Payment Information</h3>
						<Elements stripe={stripePromise}>
							<UpdatePaymentForm
								updatePayment={handlePaymentUpdateSubmit}
								customerId={customerId}
							/>
						</Elements>
					</div>
				</Modal>
			)}
			<div className='user-information'>
				<h2>Settings</h2>
				<div className='user-form'>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit(e);
						}}
						className='user-options'
					>
						<div>
							{formErrors.keywordInput && <div className='field-error'>{formErrors.keywordInput}</div>}
							{formData.keywords.length > 0 && (
								<div className='mt-2'>
									<strong>Job Titles:</strong>
									<div className='flex flex-wrap mt-2'>
										<AnimatePresence>
											{formData.keywords.map((keyword, index) => (
												<motion.div
													key={index}
													initial='hidden'
													animate='visible'
													exit='exit'
													variants={keywordVariants}
													transition={{ duration: 0.2, ease: 'easeInOut' }}
													className='keywords-container'
												>
													<span className='mr-1 text-zinc-900 font-bold text-base'>{keyword}</span>
													<button
														className='keywords'
														onClick={(e) => {
															e.preventDefault();
															setFormErrors({
																...formErrors,
																keywordInput: '',
															});
															const updatedKeywords = formData.keywords.filter((k, i) => i !== index);
															const keywordToRemove = formData.keywords[index];
															setFormData({ ...formData, keywords: updatedKeywords });
															setPullData({
																...pullData,
																keywordsToRemove: [...pullData.keywordsToRemove, keywordToRemove],
															});
														}}
													>
														<i className='fas fa-x text-xs font-bold text-sky-800 ml-3 hover:opacity-60 transition-all'></i>
													</button>
												</motion.div>
											))}
										</AnimatePresence>
									</div>
								</div>
							)}
							{formData.keywords.length === 0 && (
								<div className='mt-2'>
									<strong>Keywords:</strong>
									<div className='flex flex-wrap mt-2'>
										<span>You have not added any job titles.</span>
									</div>
								</div>
							)}
							<div className='flex w-fit relative'>
								{formData.keywords.length < 15 && (
									<React.Fragment>
										<input
											type='text'
											name='keywordInput'
											placeholder='Add job title'
											className={`form-input flex-1 ${formErrors.keywordInput ? 'input-error' : ''}`}
											value={formData.keywordInput}
											onChange={(e) => {
												handleChange(e);
												setFormData({ ...formData, keywordInput: e.target.value });
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
													e.preventDefault();
													if (formData.keywords.length >= 15) {
														setFormErrors({
															...formErrors,
															keywordInput: 'Please limit yourself to 15 keywords.',
														});
														return;
													}
													if (formData.keywordInput.trim()) {
														if (formData.keywords.find((keyword) => keyword === formData.keywordInput.trim())) {
															setFormErrors({
																...formErrors,
																keywordInput: 'You have already added this keyword.',
															});
														} else {
															setPushData({
																...pushData,
																keywords: [...pushData.keywords, formData.keywordInput.trim().toLowerCase()],
															});
															setFormData({
																...formData,
																keywords: [...formData.keywords, formData.keywordInput.trim().toLowerCase()],
																keywordInput: '', // Clear the input field
															});
														}
													}
												}
											}}
										/>
										<button
											className='absolute right-0 top-1 h-full px-2 py-1'
											onClick={(e) => {
												e.preventDefault();
												handleChange(e);
												if (formData.keywordInput) {
													setPushData({
														...pushData,
														keywords: [...pushData.keywords, formData.keywordInput.trim().toLowerCase()],
													});
													setFormData({
														...formData,
														keywords: [...formData.keywords, formData.keywordInput.trim().toLowerCase()],
														keywordInput: '', // Clear the input field
													});
												}
											}}
										>
											<i className='fas fa-plus text-s font-bold text-sky-800 ml-3 hover:opacity-60 transition-all'></i>
										</button>
									</React.Fragment>
								)}
							</div>
							{formErrors.excludedWordsInput && <div className='field-error'>{formErrors.excludedWordsInput}</div>}
							{formData.excludedWords.length > 0 && (
								<div className='mt-2'>
									<strong>Excluded Phrases:</strong>
									<div className='flex flex-wrap mt-2'>
										<AnimatePresence>
											{formData.excludedWords.map((excludedWord, index) => (
												<motion.div
													key={index}
													initial='hidden'
													animate='visible'
													exit='exit'
													variants={keywordVariants}
													transition={{ duration: 0.2, ease: 'easeInOut' }}
													className='keywords-container'
												>
													<span className='keywords'>{excludedWord}</span>
													<button
														className='text-zinc-900 font-bold'
														onClick={(e) => {
															e.preventDefault();
															setFormErrors({
																...formErrors,
																excludedWordsInput: '',
															});
															const updatedExcludedWords = formData.excludedWords.filter((k, i) => i !== index);
															const excludedWordToRemove = formData.excludedWords[index];
															setPullData({
																...pullData,
																excludedWordsToRemove: [...pullData.excludedWordsToRemove, excludedWordToRemove],
															});
															setFormData({ ...formData, excludedWords: updatedExcludedWords });
														}}
													>
														<i className='fas fa-x text-xs font-bold text-sky-800 ml-3 hover:opacity-60 transition-all'></i>
													</button>
												</motion.div>
											))}
										</AnimatePresence>
									</div>
								</div>
							)}
							{formData.excludedWords.length === 0 && (
								<div className='mt-2'>
									<strong>Excluded Phrases:</strong>
									<div className='flex flex-wrap mt-2'>
										<span>You have not added any excluded phrases.</span>
									</div>
								</div>
							)}
							<div className='flex w-fit relative'>
								<input
									type='text'
									name='excludedWordsInput'
									placeholder='Add excluded phrase'
									className={`form-input flex-1 ${formErrors.excludedWordsInput ? 'input-error' : ''}`}
									value={formData.excludedWordsInput}
									onChange={(e) => {
										handleChange(e);
										setFormData({ ...formData, excludedWordsInput: e.target.value });
									}}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.key === 'Tab') {
											e.preventDefault();
											if (formData.excludedWords.length >= 15) {
												setFormErrors({
													...formErrors,
													excludedWordsInput: 'Please limit yourself to 15 excludedWords.',
												});
												return;
											}
											if (formData.excludedWordsInput.trim()) {
												if (
													formData.excludedWords.find(
														(excludedWord) => excludedWord === formData.excludedWordsInput.trim()
													)
												) {
													setFormErrors({
														...formErrors,
														excludedWordsInput: 'You have already added this excluded phrase.',
													});
												} else {
													setPushData({
														...pushData,
														excludedWords: [
															...pushData.excludedWords,
															formData.excludedWordsInput.trim().toLowerCase(),
														],
													});
													setFormData({
														...formData,
														excludedWords: [
															...formData.excludedWords,
															formData.excludedWordsInput.trim().toLowerCase(),
														],
														excludedWordsInput: '', // Clear the input field
													});
												}
											}
										}
									}}
								/>
								<button
									className='absolute right-0 top-1 h-full px-2 py-1'
									onClick={(e) => {
										e.preventDefault();
										handleChange(e);
										if (formData.excludedWordsInput) {
											setPushData({
												...pushData,
												excludedWords: [...pushData.excludedWords, formData.excludedWordsInput.trim().toLowerCase()],
											});
											setFormData({
												...formData,
												excludedWords: [...formData.excludedWords, formData.excludedWordsInput.trim().toLowerCase()],
												excludedWordsInput: '', // Clear the input field
											});
										}
									}}
								>
									<i className='fas fa-plus text-s font-bold text-sky-800 ml-3 hover:opacity-60 transition-all'></i>
								</button>
							</div>
							<div className='user-import-settings'>
								<strong>Import Settings:</strong>
								<span className='checkbox-container'>
									<input
										type='checkbox'
										name='autoImport'
										className='form-input'
										checked={formData.autoImport}
										onChange={(e) => {
											setFormData({ ...formData, autoImport: e.target.checked });
										}}
										disabled={isDemo}
									/>
									<span>I agree to having my applications imported from my Gmail account</span>
								</span>
								<span className='checkbox-container'>
									<input
										type='checkbox'
										name='optOut'
										className='form-input'
										checked={!formData.optOut}
										onChange={(e) => {
											setFormData({ ...formData, optOut: !e.target.checked });
										}}
										disabled={isDemo}
									/>
									<span>I agree to having my emails included in the machine learning model</span>
								</span>
							</div>
							<button
								className='settings-button no-mobile'
								type='submit'
							>
								Save
							</button>
						</div>
						<div>
							<div className='user-import-settings'>
								<strong>Notification Preferences</strong>
								<div className='grid grid-cols-1 self-start'>
									<span className='toggle-container'>
										<input
											type='checkbox'
											id='submittedToggle'
											name='submitted'
											className='toggle-input'
											value={formData.submitted}
											checked={formData.submitted}
											onChange={(e) => {
												const isChecked = e.target.checked;
												setFormData((prevFormData) => ({
													...prevFormData,
													submitted: isChecked,
												}));
												if (isChecked) {
													setPushData((prevPushData) => ({
														...prevPushData,
														notificationPreferences: [...prevPushData.notificationPreferences, e.target.name],
													}));
												} else {
													setPullData((prevPullData) => ({
														...prevPullData,
														notificationPreferences: [...prevPullData.notificationPreferences, e.target.name],
													}));
												}
											}}
										/>
										<label
											htmlFor='submittedToggle'
											className='toggle-label'
										>
											New Applications
										</label>
									</span>
									<span className='toggle-container'>
										<input
											disabled={!isSubscribed}
											type='checkbox'
											id='followUpToggle'
											name='follow up'
											className='toggle-input'
											value={formData.followUp}
											checked={formData.followUp}
											onChange={(e) => {
												const isChecked = e.target.checked;
												setFormData((prevFormData) => ({
													...prevFormData,
													followUp: isChecked,
												}));
												if (isChecked) {
													setPushData((prevPushData) => ({
														...prevPushData,
														notificationPreferences: [...prevPushData.notificationPreferences, e.target.name],
													}));
												} else {
													setPullData((prevPullData) => ({
														...prevPullData,
														notificationPreferences: [...prevPullData.notificationPreferences, e.target.name],
													}));
												}
											}}
										/>
										<label
											htmlFor='followUpToggle'
											className='toggle-label'
										>
											Follow Up
										</label>
									</span>
									<span className='toggle-container'>
										<input
											type='checkbox'
											id='assessmentToggle'
											name='assessment'
											className='toggle-input'
											value={formData.assessment}
											checked={formData.assessment}
											onChange={(e) => {
												const isChecked = e.target.checked;
												setFormData((prevFormData) => ({
													...prevFormData,
													assessment: isChecked,
												}));
												if (isChecked) {
													setPushData((prevPushData) => ({
														...prevPushData,
														notificationPreferences: [...prevPushData.notificationPreferences, e.target.name],
													}));
												} else {
													setPullData((prevPullData) => ({
														...prevPullData,
														notificationPreferences: [...prevPullData.notificationPreferences, e.target.name],
													}));
												}
											}}
										/>
										<label
											htmlFor='assessmentToggle'
											className='toggle-label'
										>
											Assessments
										</label>
									</span>
									<span className='toggle-container'>
										<input
											type='checkbox'
											id='interviewToggle'
											name='interview'
											className='toggle-input'
											value={formData.interview}
											checked={formData.interview}
											onChange={(e) => {
												const isChecked = e.target.checked;
												setFormData((prevFormData) => ({
													...prevFormData,
													interview: isChecked,
												}));
												if (isChecked) {
													setPushData((prevPushData) => ({
														...prevPushData,
														notificationPreferences: [...prevPushData.notificationPreferences, e.target.name],
													}));
												} else {
													setPullData((prevPullData) => ({
														...prevPullData,
														notificationPreferences: [...prevPullData.notificationPreferences, e.target.name],
													}));
												}
											}}
										/>
										<label
											htmlFor='interviewToggle'
											className='toggle-label'
										>
											Interviews
										</label>
									</span>
									<span className='toggle-container'>
										<input
											type='checkbox'
											id='offerToggle'
											name='offer'
											className='toggle-input'
											value={formData.offer}
											checked={formData.offer}
											onChange={(e) => {
												const isChecked = e.target.checked;
												setFormData((prevFormData) => ({
													...prevFormData,
													offer: isChecked,
												}));
												if (isChecked) {
													setPushData((prevPushData) => ({
														...prevPushData,
														notificationPreferences: [...prevPushData.notificationPreferences, e.target.name],
													}));
												} else {
													setPullData((prevPullData) => ({
														...prevPullData,
														notificationPreferences: [...prevPullData.notificationPreferences, e.target.name],
													}));
												}
											}}
										/>
										<label
											htmlFor='offerToggle'
											className='toggle-label'
										>
											Offers
										</label>
									</span>
									<span className='toggle-container'>
										<input
											type='checkbox'
											id='rejectedToggle'
											name='rejected'
											className='toggle-input'
											value={formData.rejected}
											checked={formData.rejected}
											onChange={(e) => {
												const isChecked = e.target.checked;
												setFormData((prevFormData) => ({
													...prevFormData,
													rejected: isChecked,
												}));
												if (isChecked) {
													setPushData((prevPushData) => ({
														...prevPushData,
														notificationPreferences: [...prevPushData.notificationPreferences, e.target.name],
													}));
												} else {
													setPullData((prevPullData) => ({
														...prevPullData,
														notificationPreferences: [...prevPullData.notificationPreferences, e.target.name],
													}));
												}
											}}
										/>
										<label
											htmlFor='rejectedToggle'
											className='toggle-label'
										>
											Rejections
										</label>
									</span>
									<span className='toggle-container'>
										<input
											type='checkbox'
											id='withdrawToggle'
											name='withdraw'
											className='toggle-input'
											value={formData.withdraw}
											checked={formData.withdraw}
											onChange={(e) => {
												const isChecked = e.target.checked;
												setFormData((prevFormData) => ({
													...prevFormData,
													withdraw: isChecked,
												}));
												if (isChecked) {
													setPushData((prevPushData) => ({
														...prevPushData,
														notificationPreferences: [...prevPushData.notificationPreferences, e.target.name],
													}));
												} else {
													setPullData((prevPullData) => ({
														...prevPullData,
														notificationPreferences: [...prevPullData.notificationPreferences, e.target.name],
													}));
												}
											}}
										/>
										<label
											htmlFor='withdrawToggle'
											className='toggle-label'
										>
											Withdrawals
										</label>
									</span>
								</div>
							</div>
							<button
								className='settings-button no-desktop'
								type='submit'
							>
								Save
							</button>
						</div>
					</form>
					{isSubscribed && (
						<>
							<div className='!justify-start'>
								<button
									onClick={() => {
										setShowCancelModal(true);
									}}
									className='cancel-subscription'
								>
									Cancel Subscription
								</button>
								<button
									onClick={() => {
										setShowPaymentUpdateModal(true);
									}}
									className='cancel-subscription ml-2'
								>
									Update Payment Information
								</button>
							</div>
						</>
					)}
					<div className='!justify-start'>
						<button
							onClick={() => {
								setShowDeleteAccountModal(true);
							}}
							className='delete-account'
						>
							Delete Account
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default UserDetails;
