import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../../styles/UIElements/UserDetails.css';
import { AnimatePresence, motion } from 'framer-motion';

import { setNavOpen } from '../../../redux/userReducer';
import {
	setShowApplicationPanel,
	setShowUserPanel,
	setShowTaskPanel,
	setShowInsightsPanel,
} from '../../../redux/appDataReducer';
import { setLoggedIn } from '../../../redux/loggedInReducer';
import InboxSearch from '../Forms/InboxSearch';

const UserMenu = ({ triggerForceUpdate }) => {
	const dispatch = useDispatch();

	const userData = useSelector((state) => state.user.userData);
	const isSubscribed = useSelector((state) => state.user.userSubscription);
	const autoImport = useSelector((state) => state.user.userAutoImport);
	const navOpen = useSelector((state) => state.user.navOpen);
	const showApplicationPanel = useSelector((state) => state.appData.showApplicationPanel);
	const showUserPanel = useSelector((state) => state.appData.showUserPanel);
	const showTaskPanel = useSelector((state) => state.appData.showTaskPanel);
	const showInsightsPanel = useSelector((state) => state.appData.showInsightsPanel);
	const taskCount = useSelector((state) => state.taskData.showTaskNumber);
	const lastImport = useSelector((state) => state.user.userLastImport);
	const isLoggedIn = useSelector((state) => state.loggedIn.loggedIn);

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	const environment = process.env.REACT_APP_ENV;

	let backendUrl;
	if (environment === 'prod') {
		backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
	} else {
		backendUrl = process.env.REACT_APP_BACKEND_URL_DEV;
	}

	const resetPanels = () => {
		dispatch(setShowUserPanel(false));
		dispatch(setShowApplicationPanel(false));
		dispatch(setShowTaskPanel(false));
		dispatch(setShowInsightsPanel(false));
	};

	const handleResize = () => {
		setScreenWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const changeNavState = () => {
		dispatch(setNavOpen(!navOpen));
	};

	const handleLogout = async () => {
		try {
			const response = await fetch(backendUrl + 'auth/logout', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
			});
			if (response.ok) {
				dispatch(setLoggedIn(false));
				localStorage.removeItem('authToken');
				localStorage.removeItem('expires');
				localStorage.removeItem('lastFetchTime');
				sessionStorage.removeItem('subscription');
				sessionStorage.removeItem('authStatus');
				document.cookie =
					'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.jobwrangler.io; Secure; SameSite=Strict;';
				window.location.href = '/login';
			} else {
				console.error('Logout failed:', response.statusText);
			}
		} catch (error) {
			console.error('Error during logout:', error);
		}
	};

	const convertToUserTime = (lastImport) => {
		// Parse the lastImport string into a JavaScript Date object
		const lastImportTime = new Date(lastImport);

		// Define options to customize the time format
		const options = {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true, // Use 12-hour format
		};

		// Convert the lastImport time to the user's local time with specified options
		const adjustedLastImportTime = lastImportTime.toLocaleString(undefined, options);

		return adjustedLastImportTime;
	};

	const adjustedLastImportTime = convertToUserTime(lastImport);

	const dashboardVariants = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	return (
		<>
			{navOpen && <div className='overlay-nav'></div>}
			<div className={`user-menu ${navOpen && screenWidth < 1030 ? 'slide-nav' : 'slide-out-nav'}`}>
				<div>
					<div className='user-details'>
						<div className='profile'>
							{userData.icon && (
								<img
									alt={userData.name}
									src={userData.icon}
								/>
							)}
							{!userData.icon && <i className='fa-regular fa-user'></i>}
							<div>
								<h6>
									{userData.name}
									{isSubscribed && <i className='fa-solid fa-crown text-yellow-400 text-xs ml-2'></i>}
								</h6>
								<span>{userData.email}</span>
							</div>
						</div>
					</div>
					{!isSubscribed && (
						<div>
							<button
								onClick={() => {
									window.location.href = '/premium';
								}}
								className='premium no-desktop mb-4'
							>
								Get Premium <i className='fa-solid fa-crown'></i>
							</button>
						</div>
					)}
					{isLoggedIn && autoImport && <InboxSearch triggerForceUpdate={triggerForceUpdate} />}
					<ul>
						<li className={`${showApplicationPanel ? 'active' : ''}`}>
							<button
								onClick={() => {
									resetPanels();
									dispatch(setShowApplicationPanel(true));
									changeNavState();
								}}
							>
								<i className='fa-solid fa-table-list'></i> Applications
							</button>
						</li>
						<li
							className={`${!isSubscribed ? 'disabled' : ''} ${showTaskPanel ? 'active' : ''}`}
							disabled={`${!isSubscribed ? 'disabled' : ''}`}
							title='Tasks'
						>
							<button
								className={`${!isSubscribed ? 'disabled' : ''}`}
								disabled={`${!isSubscribed ? 'disabled' : ''}`}
								onClick={() => {
									resetPanels();
									dispatch(setShowTaskPanel(true));
									changeNavState();
								}}
							>
								<i className='fa-solid fa-list-check'></i> Tasks{' '}
								{isSubscribed && (
									<AnimatePresence>
										{taskCount > 0 && (
											<motion.span
												initial='hidden'
												animate='visible'
												exit='hidden'
												variants={dashboardVariants}
												transition={{}}
												className='task-count'
											>
												{taskCount}
											</motion.span>
										)}
									</AnimatePresence>
								)}
								{!isSubscribed && <i className='fa-solid fa-crown text-yellow-400 text-base ml-2'></i>}
							</button>
						</li>
						<li
							className={`${!isSubscribed ? 'disabled' : ''} ${showInsightsPanel ? 'active' : ''}`}
							disabled={`${!isSubscribed ? 'disabled' : ''}`}
							title=''
						>
							<button
								onClick={() => {
									resetPanels();
									dispatch(setShowInsightsPanel(true));
									changeNavState();
								}}
								className={`${!isSubscribed ? 'disabled' : ''}`}
								disabled={`${!isSubscribed ? 'disabled' : ''}`}
							>
								<i className='fa-solid fa-lightbulb'></i> Insights{' '}
								{!isSubscribed && <i className='fa-solid fa-crown text-yellow-400 text-base ml-2'></i>}
							</button>
						</li>
					</ul>
				</div>
				<div>
					<ul>
						<li className='no-desktop'>
							<a href='/dashboard'>Dashboard</a>
						</li>
						<li className='no-desktop'>
							<a
								className=''
								href='/feedback'
							>
								Feedback
							</a>
						</li>
					</ul>
				</div>
				<div>
					<ul>
						<li className={`${showUserPanel ? 'active' : ''}`}>
							<button
								onClick={() => {
									resetPanels();

									dispatch(setShowUserPanel(true));
									changeNavState();
								}}
							>
								<i className='fa-solid fa-gear'></i> Settings
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									handleLogout();
									changeNavState();
								}}
							>
								<i className='fa-solid fa-arrow-right-from-bracket'></i> Logout
							</button>
						</li>
					</ul>
					{lastImport && <span className='user-last-sync'>Last Sync: {adjustedLastImportTime.toString()}</span>}
				</div>
			</div>
		</>
	);
};

export default UserMenu;
