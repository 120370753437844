import React from 'react';

import FeedbackForm from '../components/UIElements/Forms/FeedbackForm';
import Navigation from '../components/UIElements/Navigation';
import Footer from '../components/UIElements/Footer';
import MobileNav from '../components/UIElements/Landing/MobileNav';

//import '../styles/Login.css';

const Feedback = () => {
	return (
		<React.Fragment>
			<Navigation />
			<MobileNav />
			<section className='relative pt-16 pb-8 bg-section dark:bg-zinc-950 bg-cover bg-center dark:text-white text-zinc-900'>
				<div className='inset-0 p-4 pt-8 flex flex-col items-center justify-center max-w-[70%] m-auto sm:max-w-[90%] xs:max-w-[100%]'>
					<h1 className=' mb-8'>Have Feedback?</h1>
					<p className='self-start w-[80%] m-auto'>We would love to hear it. Fill out the form below.</p>
					<FeedbackForm />
				</div>
			</section>
			<Footer isFixed />
		</React.Fragment>
	);
};

export default Feedback;
